<template>
	<div class="" v-if="expanded">
		<h3>{{ trx.products.customs.settings }}</h3>
		<div>
			<h4>{{ trx.products.customs.hs }}</h4>
			<input v-model="hs" placeholder="e.g. 847160" type="number"/>
		</div>
		<div>
			<h4>{{ trx.products.customs.value }}</h4>
			<input v-model="value" placeholder="e.g. 10" type="number"/>
		</div>
		<div>
			<h4>{{ trx.products.customs.weight_kg }}</h4>
			<input v-model="weight_kg" placeholder="e.g. 0.178" type="number"/>
		</div>
		<button v-on:click="saveProduct(product)">Opslaan</button>
	</div>
</template>
<script>

import trx from "@/translations"
import { mapActions } from "vuex";

export default {
	name: "CustomSettings",
	props: {
		product: Object,
		expanded: Boolean,
	},
	data() {
		return {
			trx,
			hs: "",
			value: "",
			weight_kg: "",
		}
	},
	mounted() {
		this.hs = this.product.customs.hs ? this.product.customs.hs : ""
		this.value = this.product.customs.value ? this.product.customs.value : ""
		this.weight_kg = this.product.weight_kg ? this.product.weight_kg : ""
	},
	methods: {
		...mapActions(["update_customs_settings"]),
		saveProduct(product) {

			// Check if we are working with an older product that does not have a customs object
			// if so create one
			if (!product.customs)
				product.customs = {}
			product.customs.hs = this.hs
			product.customs.value = this.value
			product.weight_kg = this.weight_kg
			
			this.update_customs_settings(product)
		}
	}
}
</script>
<style lang="scss" scoped>
</style>