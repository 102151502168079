<template>
  <div>
    <h1>{{ trx.localization.name }}</h1>
    <form>
      <label for="firstName">First Name:</label>
      <input type="text" id="firstName" v-model="user.first_name" />

      <label for="lastName">Last Name:</label>
      <input type="text" id="lastName" v-model="user.family_name" />

      <label for="language">Language:</label>
      <select id="language" v-model="user.lang">
        <option value="nl">{{trx.localization.dutch}}</option>
        <option value="en">{{trx.localization.english}}</option>
        <option value="fr">{{trx.localization.french}}</option>
        <option value="es">{{trx.localization.spanish}}</option>
      </select>

      <label for="dateTimeFormat">Date Time Format:</label>
      <input type="text" id="dateTimeFormat" v-model="user.date_time_format" />

      <button type="submit">Save</button>
    </form>
  </div>
</template>

<script>
import trx from '@/translations';
import { mapGetters } from 'vuex';
/* eslint-disable no-debugger */

export default {
    name: 'Localization_settings',
    computed: {
        ...mapGetters(['user'])
    },
    data() {
        return {
        trx
        };
    }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
