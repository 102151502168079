<template>
  <div>
    <div class="assign_location flex" draggable @dragend="dragEnd($event, node._id)" @dragstart="startDrag($event, node)" >  
      <div class="flex-5 flex-10-md drag">
            <p class="drag_icon">≡</p>
      </div>
      <div class="location_box flex flex-90 flex-90-md">
        <div class="location">
          <div class="loc"> <span>{{location_rack}}</span>-<span>{{location_number}}</span></div>
          <input v-model="stock" type="number" v-on:change="update_location_stock({product_id: node.product_id, location_product_id: node._id,  amount: stock, search: null, product_ean: ean })" placeholder="Amount" >
        </div> 
        <div class="buttonsContainer flex flex-20-md">
          <button class="transferButton" @click="transfer_location({loc_prod_id: node._id })">🔀</button>
          <button  class="color_dark_red" v-on:click="deassign_location({location_product_id: node._id, product_id: node.product_id, location_id: node.location_id._id, product_ean: ean, search: null })" >X</button>  
        </div>
        <!-- <div class="print">
          <button v-on:click="print_label({_id: id, rack: location_rack, number: location_number})">⎙</button>
        </div> -->
      </div>
    </div>

    <div class="drop-zone"
      :ref="node._id" 
      @dragover.prevent
      @dragenter="onDragEnter($event, node._id)" 
      @dragleave="onDragLeave($event, node._id)"
      @drop="onDrop($event, node._id)">
      <br>
    </div>
   
  </div>
  
</template>

<script>

import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';

/* eslint-disable no-debugger */


export default {
  name: 'AssignLocation',
  computed: {
    ...mapGetters(['products'])
  },
  methods: {
    ...mapActions(['setProductLocation', 'switch_loc_position', 'deassign_location', 'transfer_location', 'update_location_stock','link_barcode',  'linkProductLocation', 'print_label', 'addTransfer']),
    startDrag (evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.target.classList.add('dragging')
      evt.dataTransfer.setData('itemID', item._id)
    },
    dragEnd (evt) {
      evt.target.classList.remove('dragging')
    },
    onDrop (evt, id) {
        const itemID = evt.dataTransfer.getData('itemID');
        this.$refs[id].classList.remove('drag-enterd')
        this.switch_loc_position({id_to_switch: itemID, id_to_be: id, product_id: this.node.product_id })
    },
    onDragEnter (evt, id) {
      evt.prevent;
      this.$refs[id].classList.add('drag-enterd');
    },
    onDragLeave (evt, id) {
      evt.prevent;
      this.$refs[id].classList.remove('drag-enterd');
    }
  },
  components: {
   
  },
  props: {
    id: String,
    location_rack: String,
    location_number: String,
    stock: Number,
    node: Object,
    ean: String,
    inboundItemId: String,
  },
  data () {
    return {
      trx,
      amount: 0,
    }
  }
}

</script>

<style lang="scss" scoped>

  .drag {
    cursor:move
  }

  .drag_icon {
    line-height: 0rem;
    font-weight: 400;
    font-size: 5rem;
    position: absolute;
    top: 1.7rem;
    left: 0rem;
    @media all and (max-width: $bp-medium) {
      top:3.7rem
    }
  }

  input {
    margin-right: 0px;
    height: 4rem;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 13rem;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    padding-right: 0rem;
    font-size: 2rem;
    font-weight: 700;
    align-items: center;
  }

  .buttonsContainer {
    .transferButton {
      background-color: var(--color-yellow);
      margin-right: 1rem;
      &:hover {
          background-color: var( --color-purple);
        }
    }
    .color_dark_red {
      background-color: var( --color-red);
      
        &:hover {
          background-color: var(--color-red-dark);
        }
    }
    
  }

  .assign_location {
     position: relative;
     margin-right: auto;
     .location {
       font-size: 1.7rem !important;
       font-weight: 800;
       max-width: 20rem;
       color: var(--color-primary);
       .loc {
        max-width: 11rem;
        position: absolute;
        border-right: 2px solid var(--color-primary);
        min-width: 11rem;
        padding-right: 0.6rem;
        top: 0.6rem;
        left: 1rem;
        @media all and (max-width: $bp-medium ) {
          top: 1.6rem
        }
       }
     }
     select {
       width: 8rem;
       height: 6rem;
       padding: 0.5rem;
     }
  }

  .barcode {
     display: 100;
  }

  .drag-enterd {
    padding: 0.5rem;
    @include box-bg-dash;
   
  }

  .dragging {
    @include box-bg-dash;
  }

  .location_box {
    position: relative;
    justify-content: space-between;
    .print {
      position: absolute;
      padding: 1rem;
      right: 10px;
      top: -0.5rem;
      font-size: 2rem;
      button {
        font-size: 4rem;
        padding: 5px;
      }
    }
  }
   
</style>
