<template>
    <div>
        <span class="expand-icon" v-on:click="expanded = !expanded">ᐅ {{ title }}</span> 
        <div class="expanded" v-if="expanded">
            
            <div class="" >
                <table class="table-m">
                    <thead>
                        <tr>
                            <th v-for="x in Object.keys(node[0] )" v-bind:key="x" :class="x">{{x}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i in node" v-bind:key="i.date">
                            <td v-for="x in i" v-bind:key=" x ? x[0] ? /^-?\d+\.\d{3,}$/.test(x[0] + Math.random(0, 1000)) : x._id : 'test'">
                                <div v-if="isArrayofObjects(x)">
                                    <StorageItem :node="flattenData(x)"></StorageItem>
                                    
                                </div>
                                <div v-else-if="isObject(x)">
                                    <StorageItem :node="[x]"></StorageItem>
                                </div>
                                <div v-else-if="moment(x).isValid() && moment(x).isAfter(moment('1971-01-01'))">
                                    {{ moment(x).format('DD-MM-YYYY')}}
                                </div>
                                <div v-else-if="/^-?\d+\.\d{3,}$/.test(x)">
                                    {{ x | formatEuro }}
                                </div>
                                <div v-else>
                                    {{x}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script>

  import trx from '@/translations';
  import moment from "moment";
  import storageItem from './item.vue';

  /* eslint-disable no-debugger */
  
  export default {
    name: 'StorageItem',
    created() {
      
    },
    methods: {
        isArrayofObjects(item) {
        // Check if it's an array
            if (!Array.isArray(item)) {
                return false;
            }
            // Check every element to ensure each is an object
            return item.every(element => typeof element === 'object' && element !== null && !Array.isArray(element));
        },

        isObject (item) {
            return typeof item === 'object' && item !== null && !Array.isArray(item);
        },
        flattenData(data) {
            return data.map(item => ({
                product_name: item.product.name,
                product_ean: item.product.ean,
                location_price: item.location.price,
                location_type: item.location.type,
                location_id: item.location._id,
                located_datetime: item.located_datetime,
                location_name: item.location.name,
                
            }));
        }
    },
    components: {
        storageItem
    },
    props: {
        node: {
            type: [Object, Array],
            required: true
        },
        title: String,
    },
    data () {
      return {
        trx,
        moment,
        expanded: false,
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  
  .expand-icon {
    cursor: pointer;
  }

  .date {
    min-width: 120px;
  }

</style>