<template>
  <div class="signup">
    <img class="signupbg" src="@/assets/signup.png" />
    <AuthContainer type="reset"/>
    
  </div>
</template>

<script>
// @ is an alias to /src
import AuthContainer from '@/components/Layout/AuthContainer.vue';

export default {
  name: 'Signup',
  components: {
    AuthContainer
  }
}
</script>

<style lang="scss" scoped>
   
</style>