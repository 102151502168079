<template>
  <div class="wrapper" :style="lastItem && 'margin-bottom: 20rem;'">
    <h4 class="item-name">
      {{ item.title }}
    </h4>
    <div class="c">
      <div class="c-inner">
        <div class="c-image">
          <img
            class="image"
            v-if="this.item.img"
            v-bind:src="this.item.img"
            />
        </div>
        <div class="product">
          <p>
            {{ item.ean }}
          </p>
        </div>
        <div class="amount-selecter">
          <div class="flex add_remove_button">
            <div class="button button-left" v-on:click="sub_quantity()">-</div>
            <input class="input" v-model="item.quantity" type="number" />
            <div class="button button-right" v-on:click="add_quantity()">
              +
            </div>
          </div>
        </div>
      </div>
      <div class="delete button button-right" v-on:click="delete_item()">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.75488 11.1599L17.9999 18.8249L31.1548 11.2049"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.9999 32.4148V18.8098"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32.4149 13.755V22.2451C32.4149 22.3201 32.4149 22.38 32.3999 22.455C31.3499 21.54 29.9999 21 28.4999 21C27.0899 21 25.7849 21.495 24.7499 22.32C23.3699 23.415 22.4999 25.11 22.4999 27C22.4999 28.125 22.8149 29.19 23.3699 30.09C23.5049 30.33 23.6699 30.555 23.8499 30.765L21.1049 32.2801C19.3949 33.2401 16.6049 33.2401 14.8949 32.2801L6.88493 27.84C5.06993 26.835 3.58496 24.3151 3.58496 22.2451V13.755C3.58496 11.685 5.06993 9.16503 6.88493 8.16003L14.8949 3.72C16.6049 2.76 19.3949 2.76 21.1049 3.72L29.1149 8.16003C30.9299 9.16503 32.4149 11.685 32.4149 13.755Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M34.5 27C34.5 28.125 34.185 29.19 33.63 30.09C33.315 30.63 32.91 31.11 32.445 31.5C31.395 32.445 30.015 33 28.5 33C26.31 33 24.405 31.83 23.37 30.09C22.815 29.19 22.5 28.125 22.5 27C22.5 25.11 23.37 23.415 24.75 22.32C25.785 21.495 27.09 21 28.5 21C31.815 21 34.5 23.685 34.5 27Z"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.105 28.56L26.925 25.395"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.075 25.4399L26.895 28.6049"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "OrderProductItem",
  props: {
    item: Object,
    lastItem: Boolean,
  },
  methods: {
    ...mapActions(["removeOrderProduct"]),
    add_quantity() {
      // Vuex state is automatically updated
      this.item.quantity += 1;
    },
    sub_quantity() {
      // Vuex state is automatically updated
      if (this.item.quantity > 1) {
        this.item.quantity -= 1;
      }
    },
    delete_item() {
      // Delete item
      this.removeOrderProduct(this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  box-shadow: var(--shadow-light);
  border-radius: 0.77rem;
  background-color: white;
}

.item-name {
  background-color: var(--color-grey-light-3);
  padding: 1rem;
  margin-top: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.c {
  display: flex;
  gap: 2rem;
  background-color: transparent;

  &-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
  }

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input {
  text-align: center;
  font-family: inherit;
  padding: 0;
  width: 3rem;
  padding: .5rem .25rem;
  border-radius: var(--border-radius-box);
  box-shadow: var(--shadow-light);
  margin: 0;
  margin-right: 0;
  font-size: 1.5rem;
  font-weight: 200;
  color: var(--color-primary-dark);
  border: 0px;
  transition: all 0.1s ease;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.product {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.amount-selecter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.button {
  @media screen and (max-width: $bp-medium) {
    padding: 0.5rem 1rem;
  }
}

.add_remove_button {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.image {
  height: auto;
  max-height: 75px;
  width: 50px;
  object-fit: contain;
}

.delete {
  background-color: transparent;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  svg {
    stroke: var(--color-red);
  }
}

.delete:hover {
  background-color: transparent;
}
</style>
