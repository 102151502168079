<template>
  <div>
    <div v-if="status != null">
      <h2>{{ this.status }}</h2>
      <button @click="status = null">Nog een product toevoegen</button>
    </div>
    <div v-else>
      <input v-if="selected_product === null" v-model="search_query" placeholder="Zoek op EAN / naam"/>

      <div class="body_products" v-if="selected_product === null">
        <div class="flex">
          <div class="flex-50"><p><b>{{ trx.products.Product }}</b></p></div>
          <div class="flex-50"><p><b>{{ trx.products.ean }}</b></p></div>
        </div>
        <div class="body_product flex" v-for="product in filtered_products" :key="product._id" @click="selected_product = product">
          <div class="flex-50"><p>{{ product.name }}</p></div>
          <div class="flex-50"><p>{{ product.ean }}</p></div>
        </div>
      </div>

      <div class="body_selected_product" v-if="selected_product !== null">

        <div class="flex justify-between">
          <div>
            <h3>{{ selected_product.name }}</h3>
            <p>{{ selected_product.ean }}</p>
          </div>
          <button @click="selected_product = null">TERUG</button>
        </div>

        <div class="container_acp">
          <div class="body_acp">
            <div>
              <label style="font-size: 1.5rem">{{ trx.inbound_details.delivery.packedPer }}*</label>
              <input v-model="selected_product_acp.packed_per" type="number" />
            </div>
            <div>
              <label style="font-size: 1.5rem">{{ trx.inbound_details.delivery.units }}*</label>
              <input v-model="selected_product_acp.units" type="number" />
            </div>
          </div>
          <div class="body_acp">
            <div>
              <label style="font-size: 1.5rem">{{ trx.checkinv.checkin_product.batch_number }}</label>
              <input v-model="selected_product_acp.batch_nr" type="text" />
            </div>
            <div>
              <label style="font-size: 1.5rem">{{ trx.checkinv.checkin_product.expiration_date }}</label>
              <input v-model="selected_product_acp.expiry_date" type="date" />
            </div>
          </div>
        </div>

        <button class="add_button" @click="add(selected_product._id, selected_product_acp)">
          Product toevoegen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import trx from "@/translations";
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
import error from "@/error"

/* eslint-disable no-debugger */

export default {
  name: "AddProduct",
  data() {
    return {
      trx,
      name: "",
      selected_product: null,
      selected_product_acp: {
        units: "",
        packed_per: "",
        batch_nr: "",
        expiry_date: "",
      },
      search_query: "",
      filtered_products: [],
      status: null,
    };
  },
  props: {
    inbound_id: String,
  },
  watch: {
    search_query: function (query) {
      this.search_product(query);
    },
  },
  created() {
    this.fetch_products();
  },
  methods: {
    ...mapActions(["getProducts", 'fetchPendingInbounds', "fetchInboundItems"]),
    search_product(query) {
      this.filtered_products = this.products.filter((x) => {
        if (x.name === null || x.ean === null) {
          return false;
        }
        return (
          x.name.toLowerCase().includes(query.toLowerCase()) ||
          x.ean.includes(query)
        );
      });
    },
    async fetch_products() {
      // await this.getProducts();
      await this.getProducts({limit: 999999});
      this.filtered_products = this.products;
    },
    async add() {
      if (
        this.selected_product_acp.units !== "" &&
        this.selected_product_acp.packed_per !== ""
      ) {
        
        const res = await api.post(`/inbound_item/add/${this.inbound_id}`, {
          product_id: this.selected_product,
          units: this.selected_product_acp.units,
          packed_per: this.selected_product_acp.packed_per,
          batch_nr: this.selected_product_acp.batch_nr,
          expiry_date: this.selected_product_acp.expiry_date,
        })
        if (res.status !== 200) {
          error(res.data)
        }
        await this.fetchInboundItems({id: this.inbound_id})
        await this.fetchPendingInbounds();

        this.selected_product = null;
        this.status = "Product toegevoegd!";
      }
    },
  },
  computed: {
    ...mapGetters(["products"]),
  },
};
</script>

<style lang="scss" scoped>

  .body_product {
        cursor: pointer;
        &:hover {
          background: var(--color-grey-light-3);
        }
        @include box-bg-white;
        padding: 1rem 2rem;
        margin-bottom: 2rem;
        >div {
          padding: 0rem 2rem;
        };
        >div:first-child {
          padding: 0rem;
          border-right: 1px solid var(--color-grey-dark-1);
        }
  }
</style>
