<template>
  <div class="terms-container">
        <h1>{{trx.subscriptions["Terms of Service"]}}</h1>
        <object type="application/pdf" :data="require('@/static/terms.pdf')" width="100vw"> </object>
        <div class="controles">
            <label v-on:click="isActive == true ? isActive = false: isActive == true" class="check-container">{{trx.subscriptions["I have read and accept the terms of condition"]}}
                <input type="checkbox">
                <span class="checkmark"></span>
            </label>
            <button v-bind:class="{ disabled: isActive, }" v-on:click="acceptTerms">{{trx.continue}}</button> 
        </div>    
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import {  mapActions } from 'vuex';


export default {
  name: 'add-subscription',
  components: {
  },
  methods: {
    ...mapActions([ 'acceptTerms'])
  },
  data () {
    return {
      trx,
      isActive: true
    }
  }
}
</script>

<style lang="scss" scoped>
    .terms-container {
        display: flex;
        flex-direction: column;
        .controles {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                &.disabled {
                    &:hover {
                        cursor: none;
                        pointer-events: none;
                    }
                }
                margin-left: auto;
            }
        }
        object {
            @include box-bg-solid;
            overflow: auto;
            height: calc(100vh - 40vh) ;
            width: calc(100vw - 14vw);
        }
    }

   
</style>
