<template>
  <div class="ck_inv ck_inv__container">
    
    <div class="header">
      <div class="">
        <h3>{{trx.checkinv.add_checkin.change_checkin}}</h3>
        <p>{{ trx.checkinv.add_checkin.change_checkin_info }}</p>  
      </div>
      <div>
        <button @click="goToScanQR"> scan QR code</button>
      </div>
    </div>
    
    <h3>{{trx.checkinv.expected_delivery}}</h3>
    <div class="ck_inv__container__row">
      
      <div v-if="pending_inbounds !== (null || undefined)" class="">
          <InboundProduct v-for="pi in pending_inbounds" :node="pi" v-bind:key="pi._id"/>
      </div>
    </div>

    <h3>{{ trx.checkinv.received }}</h3>
    <div v-if="checkedin_inbounds && checkedin_inbounds.length > 0 " class="ck_inv__container__row ck_inv__container__row--checked_in">
      

      <div v-if="pending_inbounds !== (null || undefined)" class="">
        <InboundProduct v-for="pi in checkedin_inbounds" :node="pi" v-bind:key="pi._id"/>
      </div>
    </div>

  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import trx from '@/translations';
import InboundProduct from './inbound_product.vue';
// import RowItem from './row_item';
import moment from "moment";
// import router from '../../../router';

/* eslint-disable no-debugger */

export default {
  name: 'CheckinInventory',
  methods: {
    ...mapActions([
      'addInventory', 
      'send_checkin_confirmation', 
      'fetchPendingInbounds',
      'fetchCheckedinInbounds',
      'fetchInboundItem',
    ]),
    goToScanQR() {
      this.$router.push('/inbound/item')
    }
  },
  computed: {
    ...mapGetters([
    'pending_inbounds',
    'checkedin_inbounds',
    'inbound_item',
  ]),
  },
  components: {
    InboundProduct,
    // RowItem,
  },
  created() {
      this.fetchPendingInbounds();
      this.fetchCheckedinInbounds();
      },
  data () {
    return {
      trx,
      moment,
      expanded: true
    }
  }
}
</script>

<style lang="scss" scoped>
    .ck_inv {
      &__container {
        
        &__row {
          margin-bottom: 2rem;
          @include box-bg-dash;
          padding: 1rem;
          &--checked_in {
           height: 50vh;
           overflow: scroll;
          }
        }
      } 
      
      
    }
   .sub.big {
        min-width: 70vw !important;
        max-width: 70vw !important;
   }
   .header {
    display: flex;
    justify-content: space-between;
     margin-bottom: 5rem;
   }
</style>
