<template>
  <div class="ck_inv__container">
    <InboundItem v-for="inb_item in node.inv_prod_id" :inbound_id="id" v-bind:key="inb_item._id" :inbound_item="inb_item"/>
  </div>
</template>

<script>
import {mapMutations, mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import InboundItem from "./inboundItem.vue"

/* eslint-disable no-debugger */

export default {
  name: 'InventoryProducts',
  methods: {
    ...mapActions(['selectFiles', 'expand_amounts', 'upload','uploadImages', 'checkinInventory', 'toggleFulfilment',
     'send_checkin_confirmation', 'update_check_in', 'cr_checkin_code' ]),
    ...mapMutations(['SET_SELECTED_FILES', 'upload', 'SET_UPLOAD_STATUS']),
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.SET_SELECTED_FILES(files);
    },
  },
  computed: {
    ...mapGetters([
       'selectedFiles', 'uploadStatus', 'pending_inbounds', 'inbound_item' ])
  },
  components: {
    InboundItem
  },
  created() {
   
  },
  props: {
    node: Object,
    id: String
  },
  data () {
    return {
      trx,
    }
  }
}
</script>

<style lang="scss" scoped>
   .ck_inv__container  {
     margin-left:5rem;
   }
</style>
