<template>
  <div class="settings">
    <h2>{{trx.admin.name}}</h2>
    <div class="settings__nav"> 
      <SubNavItem v-if="user.is_admin" link="/admin/search_order" :name="trx.admin.search_orderid.name" state=""/>
      <SubNavItem link="/admin/shipment_box" :name="trx.admin.ship_boxes.name" state=""/>
      <SubNavItem link="/admin/surcharge" :name="trx.admin.surcharge.name" state=""/>
      <SubNavItem link="/admin/shipment_method" :name="trx.admin.shipment_method.name" state=""/>
      <SubNavItem link="/admin/locations" :name="trx.admin.locations.name" state=""/>
      <SubNavItem link="/admin/users" :name="trx.admin.users.name" state=""/>
      <SubNavItem link="/admin/labels" :name="trx.admin.labels.name" state=""/>
      <SubNavItem link="/admin/transfers" :name="trx.admin.transfers.name" state=""/>
    </div>  
    <section class="sub light big">
          <router-view></router-view>
    </section>
  </div>
</template>

<script>
import SubNavItem from '@/components/Layout/SubNavItem.vue';
import trx from '@/translations'; 
import { mapGetters } from 'vuex';


export default {
  name: 'Admin',
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    SubNavItem
  },
  data() {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-top: 5rem;
  }
  max-width: 30rem;
  height: 100%;
}


</style>
