<template>
  <div class="users-wrp">
        <h3>{{trx.users["Users in your organization"]}}</h3>
       
        <div class="table_controles">
            <div class="table_controles__btns">
                <router-link v-if="!$route.path.includes('add')" :to="{path:'/settings/users/add'}" class="button">
                        + {{trx["Add"]}}
                </router-link>
                <router-link v-if="!$route.path.includes('import')" :to="{path:'/settings/users/import'}" class="button"  >
                        + {{trx["Import"]}}
                </router-link>
            </div>
            
            <div class="add_container" v-if="$route.path.includes('add') > 0" >
                <router-view ></router-view>
            </div>
            <div class="import_container" v-if="$route.path.includes('import') > 0" >
                <router-view></router-view>
            </div>
        </div> 

        <div v-if="users !== (null || undefined)" class="users table table__5">
             <div class="table__row header"> 
                <div class="user__name col-2">{{trx.users["First name"]}}</div>
                <div class="user__name col-2">{{trx.users["Last name"]}}</div>
                <div class="user__email col-3">{{trx.users["Email"]}}</div>
                <div class="user__licence col-2 center">{{trx.users["Licence"]}}</div>
                <div class="user__verified col-1 center">{{trx.users["Verified"]}}</div>
                <div class="user__controls col-1 center"><img :src="require('@/assets/icons/settings.svg')"> </div>
            </div>
            

            <div v-for="user in users" v-bind:key="user._id" class="table__row user"> 
                <div class="user__name col-2" contenteditable>{{user.first_name}}</div>
                <div class="user__name col-2" contenteditable>{{user.family_name}}</div>
                <div class="user__email col-3" contenteditable>{{user.email}}</div>
                <div class="user__licence col-2 center">
                    <select v-if="plans !== (null || undefined)" v-on:change="updateLicence(user._id, this)">
                        <option>{{trx["Please select"]}}</option>
                        <option
                            v-for="plan in plans" 
                            v-bind:key="plan.id" 
                            :value="plan.id"
                            :selected="getSelectedLicence(user, plan)">
                            {{plan.name}}
                        </option>
                    </select>
                </div>
                <div class="user__verified col-1 center">
                    <div v-if="user.confirmed === true ">
                        <img class="check" :src="require('@/assets/icons/check.svg')">
                    </div>
                </div>
                <div class="user__reset col-1 center">
                    <div v-on:click="reset_password(user.email)">
                        <img title="Reset password" alt="Password reset"  class="check" :src="require('@/assets/icons/password_reset.svg')">
                    </div>
                </div>
                <div class="user__controls col-1 center">
                    ...
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
/* eslint-disable no-debugger */

export default {
  name: 'users',
  computed: {
      ...mapGetters(['users', 'plans', 'user_totals', "current_org"])
    },
  methods: {
    ...mapActions(['fetchUsers', 'fetch_curr_org_details', 'fetchPlans', 'updateUser', 'deleteUser', 'fetchUserTotals', 'updateLicence', 'reset_password'])
  },
  components: {
     
  },
  created() {
      this.fetchPlans();
      this.fetchUsers();
      this.fetch_curr_org_details()
  },
  data() {
    return {
      trx,
      getSelectedLicence: (user, plan) => {
          const userLicence = user.licences.filter((x)=> {
              return x.organization_id === this.$store.getters.current_org._id
          })[0];
          if (userLicence) {
            if (userLicence.licence_id === plan.id) {
                return true
            } else {
                return false
            }
          } else {
              return false
          }  
       }
    }
  }
}
</script>

<style lang="scss" scoped>
    h3 {
        margin: 5rem 0rem;
    }

    .totals {
      display: flex;
      justify-content: space-between;
    }

    .table_controles {
         margin: 5rem 0rem;

        .add_container {
            margin-bottom:2rem;
            @include box-bg-dash;
            padding: 2rem;
        }
        &__btns {
            margin:2rem 0rem;
        }
        .button {
           @include button;
            width: 10rem;
            text-align: center;
            font-weight: 600;
            font-size: 1.2rem;
            margin-right: 1rem;
            // color: var(--color-primary-dark);
        }
    }
    
    .users {
        .user__controls {
               img {
                   width: 1rem;
                   height: auto;
               }
        }
        .user {
            &__reset {
                cursor: pointer;
            }
        }
        
    }
</style>
