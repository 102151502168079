import api from '@/api';

import moment from 'moment';

import ean_added_audio from '@/assets/audio/ean_checked.mp3'
import wrong_audio from '@/assets/audio/wrong.mp3';

// import GlobalErr from '@/componenets/error';
/* eslint-disable no-debugger */

const state = {
    pending_inventory: null,
    checkedin_inventory: null,
    temp_checkin_rows: [],
    stock_analysis: [],
    selectedFiles: [],
    uploadStatus: '',

};

const getters = {
    pending_inventory: state => state.pending_inventory,
    checkedin_inventory: state => state.checkedin_inventory,
    temp_checkin_rows: state => state.temp_checkin_rows,
    stock_analysis: state => state.stock_analysis,
    selectedFiles: state => state.selectedFiles,
    uploadStatus: state => state.uploadStatus,
  
};

const actions = {
    async fetch_checkedin_inventory({ commit }) {
        const response = await api.get("/inventory/org/checked_in");
        response !== undefined ? commit('set_checkedin_inventory', response.data) : '';
    },
    async checkinInventory({ dispatch }, model) {
        const products = model.products.map(x => {
            x.product = x.product._id
            return x
        });
        
        model.products = products;

        await api.post('/inventory/checkin', model);
        await dispatch('send_checkin_confirmation', {_id: model._id});
        dispatch('fetchPendingInventory');
    },
    async send_checkin_confirmation({ dispatch }, model) {
        await api.post('/inventory/email', { _id: model });
        dispatch('fetchPendingInventory');
    },
    
    async print_label({ dispatch }, model) {
        const response = await api.post('/inventory/label', model, { responseType: 'blob' });

        var fileURL = window.URL.createObjectURL(response.data);
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;

        const locationWindow = window.open(fileURL);

        locationWindow.print();

        await dispatch('dummyLog')
    },
    async print_location_label({ dispatch }, model) {
        const response = await api.post('/location/label', model, { responseType: 'blob' });

        var fileURL = window.URL.createObjectURL(response.data);
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;

        const locationWindow = window.open(fileURL);

        locationWindow.print();

        await dispatch('dummyLog')
    },
    async add_temp_checkin_record({ commit }, model) {
        model._id ? "" : model._id = null
        const curr_product = arguments[0].rootState.products.products.filter(p => {
            return p.ean === model.ean && p.parent === null
        })[0];

        if (curr_product) {
            var audio = new Audio(ean_added_audio);
            audio.play();
        } else {
            const wrong_audio_inst = new Audio(wrong_audio);
            wrong_audio_inst.play();
        }

        commit('set_temp_checkin_rows', {
            ean: curr_product.ean,
            org: curr_product.org,
            name: curr_product.name,
            amount_checkin: 1,
            comment: model.comment,
            coli: model.coli,
            pallets: model.pallets,
            type: model.type,
            product: curr_product._id,
            product_item: curr_product
        });
    },

    async cr_checkin_code({ dispatch }, model) {
        const response = await api.post(`/product/label/${model.ean}/${model.amount}`, model, { responseType: 'blob' })

        var fileURL = window.URL.createObjectURL(response.data);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;

        const locationWindow = window.open(fileURL);

        locationWindow.print();

        await dispatch('dummyLog')

    },
    async fetch_stock_analysis({commit}, model) {

        let response = await api.get(`/stock/analysis/${model.org_id}/${model.ean}`);
        
        if(response.data.i)
            response.data.i.monthlyData = response.data.i.monthlyData.sort((a,b) => new moment(a.month).format('YYYYMMDD') - new moment(b.month).format('YYYYMMDD'))
        if(response.data.o)    
            response.data.o.monthlyData = response.data.o.monthlyData.sort((a,b) => new moment(a.month).format('YYYYMMDD') - new moment(b.month).format('YYYYMMDD'))
        if(response.data.r)
            response.data.r.monthlyData = response.data.r.monthlyData.sort((a,b) => new moment(a.month).format('YYYYMMDD') - new moment(b.month).format('YYYYMMDD'))
        
        response !== undefined ? commit('set_stock_analysis', response.data) : '';  
    },
    selectFiles({ commit }, files) {
        commit('SET_SELECTED_FILES', files);
    },
    async expand_amounts({ dispatch }, model) {
        try {
            await api.post('/inventory/checkin/expand_amounts/'+model);
            console.log(dispatch);
          } catch (error) {
            console.error('Error expanding_items:', error);
          }
    },
    async uploadImages({ commit, state }, model) {
        commit('SET_UPLOAD_STATUS', 'uploading');
        
        const formData = new FormData();

        state.selectedFiles.forEach((file) => {
            formData.append(`images[]`, file);
        });

        try {
          await api.put('/inventory/checkin/images/'+model, formData);
          commit('SET_UPLOAD_STATUS', 'success');
        } catch (error) {
          commit('SET_UPLOAD_STATUS', 'error');
          console.error('Error uploading images:', error);
        }
    },
   
};

const mutations = {
    set_checkedin_inventory: (state, checkedin_inventory) => (state.checkedin_inventory = checkedin_inventory),
    setPendingInventory: (state, pending_inventory) => (state.pending_inventory = pending_inventory),
    SET_SELECTED_FILES(state, files) {
        state.selectedFiles = files;
    },
    SET_UPLOAD_STATUS(state, status) {
        state.uploadStatus = status;
    },
    set_temp_checkin_rows: (state, temp_checkin_rows) => {

        // check if list contains item
        const exist = state.temp_checkin_rows.filter(x => {
            return x.product === temp_checkin_rows.product
        })[0];

        if (exist)
            temp_checkin_rows.amount_checkin = exist.amount_checkin + 1;

        state.temp_checkin_rows = [
            ...state.temp_checkin_rows.filter(x => {
                return x.product !== temp_checkin_rows.product
            }),
            temp_checkin_rows
        ]
    },
    set_stock_analysis: (state, stock_analysis) => (state.stock_analysis = {...stock_analysis} ),
  
};

export default {
    state,
    getters,
    actions,
    mutations
}
