<template>
  <div class="newOrder-section">
    <h1 class="flex-100">Scanner</h1>
    <div class="flex">
      <div class="products-conn flex-100">
        <br />
        <br />

        <!-- <StreamBarcodeReader  @decode="scan_product_func" @loaded="onLoaded"></StreamBarcodeReader> -->

        <div class="hidden hidden-md">
          <v-select ref="product_ean " class="flex-80" @search=" (search, loading) => {search_product_ean({ search: search, loading: loading })}" open="true" v-model="selected_product" v-if="shop_products" :reduce="(product) => product._id"
            :options="shop_products" label="ean">
            <template slot="option" slot-scope="option">
              <span v-if="option.locations.length > 0">{{ option.locations[0].location_id.parent.name }} -
                {{ option.locations[0].location_id.name }} -
                {{ option.name }}</span>
              <br />
              <small class="text-muted">
                {{ option.ean }}
              </small>
            </template>
          </v-select>

          <!-- <button class="flex-20" v-on:click="scan_product_ean = !scan_product_ean">📷</button> -->
        </div>

        <input :class="show_manual_ean_inp ? 'ean_input active' : 'ean_input' " v-model="selected_product" name="ean" v-on:change="search_product_ean({ search: selected_product, loading: loading })" />
        
        <div v-if="shop_products.length > 0" class="products">
          <ProductItem ref="product" v-for="x in shop_products" v-bind:key="x._id" :product_id="x._id" :i="0" />
        </div>
        
        <div class="scanner_img flex" v-else>
          <img width="320px" style="margin-left: auto; margin-right:auto; width: 320px;" src="../../assets/icons/scan.svg">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-debugger */

// import { mapGetters, mapActions } from 'vuex';
import Vue from "vue";
import vSelect from "vue-select";
import trx from "@/translations";
// import { StreamBarcodeReader } from "vue-barcode-reader";

import { mapGetters, mapActions } from 'vuex';
import ProductItem from '../Products/product_item.vue';

import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

export default {
  name: "Scanner",
  components: {
    //   StreamBarcodeReader,
    ProductItem,
  },
  methods: {
    ...mapActions([
      "fetchOrgs",
      "getProducts",
      "get_shop_products",
      "search_product_ean",
      "fetch_shipment_methods_list"
    ]),
    scan_product_func: function (x) {
      this.$refs.product_ean.search = x;
      this.scan_product_ean = false;
    },
    onChange(input) {
      this.input = input;
      if (typeof input === "string") {
        this.$refs.product_ean.search = input;
        this.$refs.product_ean.open = true;
      }
    },
    onKeyPresskeyboard(button) {
      if (button === "{numpadenter}") {
        this.$refs.product_ean.onEscape();
        this.input = "";
      }
      if (button === "{delete}") {
        this.$refs.product_ean.search.slice(0, -1);
        this.$refs.ean_text_input.value = this.$refs.product_ean.search;
      }
    },
    onInputChange(input) {
      this.input = input.target.value;
      this.$refs.product_ean.search = input;
    },

    listen_to_ean_input() {
      let code = "";

      window.addEventListener('keypress', e => {
        
        //usually scanners throw an 'Enter' key at the end of read
        if (e.keyCode === 13) {
          
          if (code.length > 10) {
            // const self = this;
            this.selected_product = null;
            this.shop_products = null;
            this.search_product_ean({ search: code, loading: this.loading });
            this.prev_code = [...code];
            this.waitForProduct(this.prev_code);
            code = ""
          }
        } else {
          code += e.key; //while this is not an 'enter' it stores the every key            
        }
      });
    },
    
    waitForProduct(code) {
      
      if ((this.$refs.product && this.$refs.product.length > 0) && this.$refs.product[0].product.ean === code ) {
        setTimeout(this.waitForProduct, 200); 
      } else if (this.$refs.product && this.$refs.product.length > 0 ) {
        this.$refs.product[0].$children[4].$refs.loc_link_button.click();
      } else {
        
        setTimeout(this.waitForProduct, 200); // Check again after 100ms
      }
    },
  },
  created() {
    this.getProducts();
    this.fetchOrgs();
    this.listen_to_ean_input(this);
    this.fetch_shipment_methods_list()
  },
  computed: {
    ...mapGetters([
      "products",
      "possible_addresses",
      "orgs",
      "order_products",
      "shop_products",
      "shipment_details",
    ]),
    selected_product_live: function () {
      return this.selected_product;
    },
    current_products: function () {
      const self = this;
      const items = this.products.filter((x) => {
        return self.order_products.indexOf(x._id) >= 0;
      });

      return items;
    },
  },
  data() {
    return {
      trx,
      ship_option: null,
      prev_code: null,
      autocomplete: null,
      type: null,
      remark: null,
      scan_product_ean: true,
      scan_location: false,
      selected_shop: null,
      selected_product: null,
      input: "",
      productReady: false,
      show_manual_ean_inp: true,
      loading: false,
      events: {
        compositionstart: () => (this.isComposing = true),
        compositionend: () => (this.isComposing = false),
        keydown: this.onSearchKeyDown,
        blur: this.onSearchBlur,
        focus: this.onSearchFocus,
        input: (e) => (this.search = e.target.value),
      },
      attributes: {
        disabled: this.disabled,
        placeholder: this.searchPlaceholder,
        tabindex: this.tabindex,
        readonly: !this.searchable,
        id: this.inputId,
        "aria-autocomplete": "list",
        "aria-labelledby": `vs${this.uid}__combobox`,
        "aria-controls": `vs${this.uid}__listbox`,
        "aria-activedescendant":
          this.typeAheadPointer > -1
            ? `vs${this.uid}__option-${this.typeAheadPointer}`
            : "",
        ref: "search",
        type: "search",
        autocomplete: this.autocomplete,
        value: this.search,
      },
    };
  },
};
</script>

<style lang="scss" scoped>

  .ean_input {
    display: none;
    background-color: var(--color-grey-dark-3);
    -webkit-appearance: none;
    color: var(--color-white);
    &.active {
      display: block;
    }
  }

</style>
