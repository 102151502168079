import api from '@/api';
import router from '@/router';
// import GlobalErr from '@/componenets/error';
/* eslint-disable no-debugger */

const state = {
    current_user: null,
    users: null,
    user_totals: {},
    countries: [],
};

const getters = {
    user: state => state.current_user,
    users: state => state.users,
    user_totals: state => state.user_totals,
    countries: state => state.countries
}; 

const actions = {
    async dummyLog() {
        return 'OK'
    },
    async fetchUser({commit}) {
        const response = await api.get("/current_user");
        response !== undefined ? commit('setUser', response.data) : '';  
    },
    async fetchUsers({commit}) {
        const response = await api.get("/users");
        response !== undefined ? commit('setUsers', response.data) : '';  
    },
    async fetchUserTotals({commit, state, dispatch}) {
        await dispatch('fetchUser');
        const licences = state.current_user.curr_org.licences;
        const totals = {
            total: {
                used: Math.round(licences.map((z) => { return z.used }).reduce((a, b) => a + b, 0)),
                count: Math.round(licences.map((z) => { return z.count }).reduce((a, b) => a + b, 0))
            },
            csr: {
                used: licences.filter((x) => {return x.name === "CSR Manager"})[0].used, 
                count: licences.filter((x) => {return x.name === "CSR Manager"})[0].count
            },
            stakeholders: {
                used: licences.filter((x) => {return x.name === "Stakeholders"})[0].used,
                count: licences.filter((x) => {return x.name === "Stakeholders"})[0].count
            }
        } 
        commit('setUserTotals', totals);  
    },
    async addUser({dispatch}, model) {
        await api.post("/users", [model]);
        dispatch('fetchUsers')
        router.go(-1);
    },
    async updateLicence({commit}, id) {
        const response = await api.put("/users/licence", {
            user_id: id,
            licence_id: event.currentTarget.value
        });
        
        response !== undefined ? commit('setUsers', response.data) : '';  
    },
    async login({commit}, model) {
        const response = await api.post("/login", model );
        commit('setUser', response.data);
        router.push('/home');
    },
    async signUp({commit}, model) {
        const response = await api.post("/signup", model);
        commit('setUser', response.data);
        router.push('/orgs/add');
    },
    async logout({commit}) {
        const response = await api.get("/logout");
        commit('removeUser', response.data);
        router.push('/login');
    },
    async reset_password({dispatch}, email) {
        await api.post("/users/password_reset/email", {email: email});
        dispatch('dummyLog')
    },
    async update_password({dispatch}, model) {
        if (model !== undefined ) {
            await api.post("/users/password_reset/"+ model.token, {password: model.password});
            router.push('/');
            dispatch('dummyLog')
        } else {
            return;
        }
    },
    async fetch_countries({commit}) {
        const response = await api.get("/countries/europe");
        response !== undefined ? commit('setCountries', response.data) : '';  
    }
};

const mutations = {
    setUser: (state, user) => (state.current_user = user),
    setUsers: (state, users) => (state.users = users),
    removeUser: (state) => (state.current_user = null),
    setUserTotals: (state, totals) => (state.user_totals = totals),
    setCountries: (state, countries) => (state.countries = countries)
};

export default {
  state,
  getters,
  actions,
  mutations
}
