<template>
  <div class="users-wrp">
        <h3>Labels</h3>
       
        <date-range-picker
            ref="picker"
            class="datePicker"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :time-picker-increment="5"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :timePicker="true"
            :autoApply="true"
            :time-picker-seconds="true"
            opens="right"
            
            v-model="dateRange"
            >

            <template v-slot:input="picker" style="min-width: 350px;">
                {{ moment(picker.startDate).format('DD-MM-YYYY') | date }} - {{ moment(picker.endDate).format('DD-MM-YYYY') | date }}
            </template>
        </date-range-picker>

        <button v-on:click="get_labels(dateRange)"> Show labels</button>

        <LabelSlider v-if="labels" :items="labels" />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
// import pdf from 'vue-pdf'
import LabelSlider from './label_slider.vue';

/* eslint-disable no-debugger */

export default {
  name: 'scan_labels',
  computed: {
      ...mapGetters(['labels'])
    },
  methods: {
    ...mapActions(['get_labels',  ]),
    error: function(err) {

			console.log(err);
		}
  },
  components: {
     DateRangePicker,
    //  pdf: pdf,
     LabelSlider
  },
  created() {
      if (!this.labels) {
        this.get_labels(this.dateRange);
      }
  },
  data() {
    const baseDate = moment().startOf('hour')
    const startDate = new Date( baseDate.startOf('hour'));
    const endDate = new Date( baseDate.endOf('hour').add(2, 'hours') );

    return {
      trx,
      dateRange: {startDate, endDate},
      moment: moment,
      show: true,
        src:'',
        loadedRatio: 0,
        page: 1,
        numPages: 0,
        rotate: 0,
    }
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : ''
      }
    }
}
</script>

<style lang="scss" scoped>
    
</style>
