

<template>
    <div v-if="authCheck('/current_org', 'GET', user.curr_role)" :class="'stp_wizard__conn ' + 'stp_wizard__conn__'+active  ">
      <div v-on:click="toggle_wizard()" :class="'question_mark '+active"> {{active ? '►' : '?' }}</div>
      
      <div :class="'stp_wizard '+active">
        <h3>Welkom</h3>
        <p>Ik ben de wizard van deze app en ik ben hier om je te helpen.</p>
        <br>
        <br>
        <Step v-if="authCheck('/current_org', 'GET', user.curr_role)" step_number="1" button_text="Volgende >>" link="/orgs/add" next_link="/settings/apis" title="Winkel aanmaken" description="In deze stap kun je je winkel aanmaken en de gewenste instellingen configureren."  />
        <Step v-if="authCheck('/current_org', 'GET', user.curr_role)" step_number="2" button_text="Volgende >>" link="/settings/apis" next_link="/products" title="API's & koppelingen" description="In deze stap kun je verschillende API's en koppelingen instellen om je winkel te integreren met andere systemen en tools. Dit kan bijvoorbeeld helpen bij het synchroniseren van voorraadniveaus tussen je winkel en externe marktplaatsen." />
        <Step step_number="3" button_text="Volgende >>" link="/products" next_link="/add_inventory" title="Producten importeren" description="Hier kun je producten importeren naar je winkel vanuit andere systemen of bronnen. Dit bespaart tijd en maakt het gemakkelijker om je productcatalogus up-to-date te houden. Als je producten wilt importeren zorg je dat deze eerst op het platform of je webshop staan. Als je dit hebt gedaan klik je op importeren en vervolgens klik je op het platform waarvan je de producten wilt importeren. Gelukt? Je producten worden zichtbaar in dit scherm."  />
        <Step step_number="4" button_text="Volgende >>" link="/add_inventory" next_link="/orders" link_button="https://m.smartfulfilment.nl/asset/21:werken-met-het-smart-fulfilment-portaal" link_button_text="Aanleverinstructies" title="Producten voormelden" description="In deze stap kun je producten voorbereiden voor verzending door ze te voor te melden, ze te verpakken en klaar te maken voor transport. Dit helpt ervoor te zorgen dat je producten goed worden ingeboekt in ons magazijn. Benieuwd naar onze aanleverinstructies? Deze kun je doormiddel van de button hieronder downloaden" />
        <Step v-if="authCheck('/orders/*/*', 'GET', user.curr_role)" step_number="5" button_text="Volgende >>" link="/orders" next_link="/shipments" title="Orders" description="Hier kun je bestellingen van klanten bekijken en beheren." />
        <Step step_number="6" button_text="Volgende >>" link="/shipments" title="Zendingen"  next_link="/home"  description="In deze stap kun je zendingen beheren en volgen. Hier vind je een overzicht van alle verzonden producten en kun je de voortgang van elke zending bekijken." />
        <Step step_number="7" button_text="Close help" link="/home" title="Dashboard" description="Dit is de startpagina van je winkel en geeft een overzicht van belangrijke statistieken en gebeurtenissen." />
      </div>
    </div>
  </template>
  
<script>
    import Step from "./step.vue";
    import authCheck from "@/lib/authCheck";
    import { mapGetters } from 'vuex';

    export default {
        name: 'Setup_wizzard',
        props: {
            msg: String,
            current: String,
        },
        computed: {
            ...mapGetters([
                'user'
            ])
        },
        methods: {

          toggle_wizard() {
                this.active = !this.active;
                if (this.active) {
                    window.document.querySelectorAll(".main")[0].style.minWidth = 'calc(93vw - 50rem)'
                    window.document.querySelectorAll(".main")[0].style.marginRight = '50rem'
                } else {
                    window.document.querySelectorAll(".main")[0].style.minWidth = '93vw'
                    window.document.querySelectorAll(".main")[0].style.marginRight = '0rem'
                }
            },
            authCheck
        },
        components: {
            Step
        },
        data () {
            return {
                active: false
            }
        }
    }
</script>
  
  <style lang="scss" scoped>
      .stp_wizard__conn {
        z-index: 999;
        position: relative;
        
      }
      .question_mark {
        transition:  0.1s linear;
        position: absolute;
        padding: 1rem 1.3rem;
        right: 0rem;
        top: 13.9rem;
        font-size: 2rem;
        @media all and (max-width: $bp-medium) {
          display: none;
        }
        
        &.true {
          right: 50rem !important;
        }
        top:10rem;
        font-size: 2rem;
        line-height: 3rem;
        color: var(--color-white);
        border-radius: 10px 0px 0px 10px;
        background: var(--color-primary);
        cursor: pointer;
      }
      .stp_wizard {
        overflow: auto;
        transition:  0.1s linear;
          h3, h1, p {
            color: var(--color-white);
          }
          h3 {
            margin-bottom: 2rem;
          }
          height: 100vh;
          width: 0px;
          display: none;
          &.true {
            padding: 5rem;
            display: block;
            width: 50rem !important;
          }
          background: var(--color-primary-light);
          position: absolute;
          right: 0;
          top:0;
          
          border-radius: 50px 0px 0px 50px;
      }
  </style>