<template>
  <div>
    <form @submit.prevent="setInformation">
      <div>
        <label for="input_contact_person">{{ trx.checkinv.checkin_confirmation.contact_person_label }}</label>
        <input
          v-model="contact_person"
          id="input_contact_person"
          :placeholder="trx.checkinv.checkin_confirmation.contact_person_placeholder"
          type="text"
          :class="{'input-error': errors.contact_person}"
        />
        <span v-if="errors.contact_person" class="error-message">{{ errors.contact_person }}</span>
      </div>

      <div>
        <label for="input_contact_person_email">{{ trx.checkinv.checkin_confirmation.contact_person_email_label }}</label>
        <input
          v-model="contact_person_email"
          id="input_contact_person_email"
          :placeholder="trx.checkinv.checkin_confirmation.contact_person_email_placeholder"
          type="email"
          :class="{'input-error': errors.contact_person_email}"
        />
        <span v-if="errors.contact_person_email" class="error-message">{{ errors.contact_person_email }}</span>
      </div>

      <div>
        <label for="input_phone">{{ trx.checkinv.checkin_confirmation.phone_label }}</label>
        <input
          v-model="phone"
          id="input_phone"
          :placeholder="trx.checkinv.checkin_confirmation.phone_placeholder"
          type="text"
          :class="{'input-error': errors.phone}"
        />
        <span v-if="errors.phone" class="error-message">{{ errors.phone }}</span>
      </div>

      <div>
        <label for="input_shipping_address">{{ trx.checkinv.checkin_confirmation.shipping_address_label }}</label>
        <input
          v-model="shipping_address"
          id="input_shipping_address"
          :placeholder="trx.checkinv.checkin_confirmation.shipping_address_placeholder"
          type="text"
          :class="{'input-error': errors.shipping_address}"
        />
        <span v-if="errors.shipping_address" class="error-message">{{ errors.shipping_address }}</span>
      </div>

      <div>
        <label for="input_track_trace">{{ trx.checkinv.checkin_confirmation.track_trace_label }}*</label>
        <input
          v-model="track_trace"
          id="input_track_trace"
          required
          :placeholder="trx.checkinv.checkin_confirmation.track_trace_placeholder"
          type="text"
          :class="{'input-error': errors.track_trace}"
        />
        <span v-if="errors.track_trace" class="error-message">{{ errors.track_trace }}</span>
      </div>

      <div>
        <label for="input_expected_delivery">{{ trx.checkinv.checkin_confirmation.expected_delivery_label }}</label>
        <input
          v-model="expected_delivery"
          id="input_expected_delivery"
          :placeholder="trx.checkinv.checkin_confirmation.expected_delivery_placeholder"
          type="date"
          :class="{'input-error': errors.expected_delivery}"
        />
        <span v-if="errors.expected_delivery" class="error-message">{{ errors.expected_delivery }}</span>
      </div>

      <button type="submit" class="full_width">{{ trx.checkinv.checkin_confirmation.button_submit }}</button>
    </form>
  </div>
</template>

<script>
import trx from "@/translations";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "AddCheckinInformation",
  props: {
    data: Array,
    updateStep: Function,
    updateInformation: Function,
  },
  data() {
    return {
      trx,
      contact_person: '',
      contact_person_email: '',
      phone: '',
      shipping_address: '',
      track_trace: '',
      expected_delivery: '',
      errors: {}
    };
  },
  computed: {
    ...mapGetters(["user", "current_org"]),
  },
  methods: {
    ...mapActions(["fetch_curr_org_details"]),
    
    validateForm() {
      this.errors = {};

      if (!this.contact_person) this.errors.contact_person = 'Contact person is required.';
      if (!this.contact_person_email) {
        this.errors.contact_person_email = 'Contact person email is required.';
      } else if (!this.validateEmail(this.contact_person_email)) {
        this.errors.contact_person_email = 'Valid email is required.';
      }
      if (!this.phone) this.errors.phone = 'Phone number is required.';
      if (!this.shipping_address) this.errors.shipping_address = 'Shipping address is required.';
      if (!this.track_trace) this.errors.track_trace = 'Track & trace is required.';
      if (!this.expected_delivery) this.errors.expected_delivery = 'Expected delivery date is required.';

      return Object.keys(this.errors).length === 0;
    },

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },

    setInformation() {
      if (this.validateForm()) {
        this.updateInformation({
          contact_person: this.contact_person,
          contact_person_email: this.contact_person_email,
          phone: this.phone,
          shipping_address: this.shipping_address,
          track_trace: this.track_trace,
          expected_delivery: this.expected_delivery,
        });
        this.updateStep(3);
      }
    }
  },
  async created() {
    await this.fetch_curr_org_details();
    if (this.current_org) {
      this.contact_person = this.current_org.contact_person || '';
      this.contact_person_email = this.current_org.contact_person_email || '';
      this.phone = this.current_org.phone || '';
      this.shipping_address = this.current_org.address?.line1 || '';
      this.expected_delivery = moment(new Date()).toISOString().split('T')[0];
    }
  },
};
</script>
<style lang="scss" scoped>
.full_width {
  width: 100%;
}

.input-error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.875em;
}
</style>