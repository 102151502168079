<template>
    <button v-on:click="shipSMF({ order:order, code:code, amount:amount, ship_mtd_code: ship_mtd_code, iframe: iframe })">
        {{name}}
    </button>
</template>
<script>
import {  mapActions } from 'vuex';

export default {
  name: 'ShipOption',
  methods: {
      ...mapActions(['shipSMF']),
  },
  components: {
  },
  props: {
    order: Object,
    name: String,
    code: String,
    amount: Number,
    ship_mtd_code: String,
    iframe: HTMLIFrameElement,
  },
  data () {
      return {
          
      }
  }
}
</script>