<template>
    <div class="autm__actions">
        <div class="change" v-if="type === 'change' ">
            <div class="flex">
                <div class="flex-50">
                    Verander
                    <select v-model="node.field" >
                        <option value="selected_ship_option">Verzendwijze</option>
                        <option value="selected_packaging_option">Verpakking</option>
                    </select>
                </div>
                <div class="flex-50">
                    Nieuwe waarde
                    <v-select v-model="node.value" v-if="node.field === 'selected_ship_option'" :reduce="item => item.code" :options="shipment_methods_list" label="code"> 
                        <template slot="option" slot-scope="option" >
                            <p selectable="true"> {{ option.name }}</p> <br>
                            <h6>  {{ option.price }} / {{option.cuttoff}} </h6>
                        </template>
                    </v-select>
                    <v-select v-model="node.value" v-if="node.field === 'selected_packaging_option'" :reduce="item => item._id" :options="shipment_boxes_list" label="label"> 
                        <template slot="option" slot-scope="option">
                            <p selectable="true"> {{ option.label }}</p> <br>
                            <h6> {{ option.max_size.L +"x" +option.max_size.W + "x" + option.max_size.H }} cm / €{{option.price}} </h6>
                        </template>
                    </v-select>
                </div>
            </div>
        </div>
        <div class="add" v-if="type === 'add' ">
            <div class="flex">
                <div class="flex-50">
                    EAN:
                    <v-select v-model="node.value" 
                        @search="(search, loading) => { 
                            search_product_ean_list({search: search, loading: loading})
                        }"
                        :reduce="shop_products_list => shop_products_list._id" 
                        :options="shop_products_list" label="ean"> 
                        <template slot="option" slot-scope="option" >
                            <div selectable="true"> {{ option.name }}</div> <br>
                            <small class="text-muted">
                                {{ option.ean }} // {{option.api_name}}
                            </small>
                        </template>
                    </v-select>
                </div>
                <div class="flex-50">
                    Aantal factor:
                    <input v-model="node.qty" type="number" />
                </div>
            </div>
        </div>
    </div> 
</template>
        
<script>

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import vSelect from 'vue-select';

import trx from '@/translations';
/* eslint-disable no-debugger */

import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

export default {
  
    computed: {
        ...mapGetters(['products', 'shop_products_list','shipment_methods_list', 'shipment_boxes_list']),
    },
  methods: {
    ...mapActions([ 
        'search_product_ean_list'
    ])
    },
    components: {
        
    },
    mounted() {
        
    },
    props: {
        product: Object, 
        type: String,
        node: Object
    },
    data() {
        return {
            trx,
            change_type: ""
        }
    },
    name: "autm_rule",
}
</script>

<style lang="scss" scoped>
   .product__aut {
       @include box-bg;
       padding: 2rem;

       .autm {
        margin-top: 2rem;
        padding: 2rem;
        @include box-bg-white;
         
        &__actions {
            @include box-bg-dash;
            padding: 2rem;
            margin-left: 5rem;
            max-width: 50rem;
        }
        [class^='flex-'] {
            margin-right: 1rem;
        }

       }
   }
</style>
