var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order__details__shipment__option flex",on:{"click":function($event){_vm.ship( {
    orderItems: _vm.orderItems.map(i => {
        return {
            orderItemId: i.orderItemId 
        }
    }), 
    shippingLabelOfferId: _vm.o.shippingLabelOfferId,
    org_id: _vm.order.org,
    order_id: _vm.order._id,
    code: _vm.code ? _vm.code : _vm.o.transporterCode
    })}}},[_c('span',{staticClass:"flex-100"},[_vm._v(_vm._s(_vm.o.labelType))]),_c('p',{staticClass:"flex-70"},[_vm._v(_vm._s(_vm.o.transporterCode))]),_c('p',{staticClass:"flex-30"},[_vm._v(" € "+_vm._s(_vm.o.labelPrice.totalPrice))]),_c('div',{staticClass:"flex-100"},[_vm._v(" "+_vm._s(_vm.o.packageRestrictions.maxWeight)+" - "+_vm._s(_vm.o.packageRestrictions.maxDimensions)+" ")]),_c('br'),_c('p',[_vm._v(_vm._s(_vm.o.handoverDetails !== undefined ? _vm.moment(_vm.o.handoverDetails.latestHandoverDateTime).format("DD-MM-YYYY") : ""))])])
}
var staticRenderFns = []

export { render, staticRenderFns }