
<template>
   <div :class="'locations__location__number '+status +' '+ (node.products.length > 0 ? 'has_products' : 'empty'
    ) ">
        <div v-if="node.product_id || node.product_id == null">
            <span class="name">{{node.name}}</span>
            <button class="print-button color-gray" v-on:click="print_location_label({id: node._id})"> P </button>
            <button class="tools" v-on:click="expanded = !expanded" >S</button>
        </div>
        <span :class="'location_type ' + (node.type ? node.type : parent_type)">  </span>
        <span class="indicator" :style="getBackgroundStyle(node.products && node.products[0] ? node.products[0].product_id.total_sold_30d : null)" ></span>
        <span class="indicator-total" :style="getBackgroundStyleTotal(node.products && node.products[0] ? node.products[0].product_id.total_sold_30d : null)" ></span>
        
        <div class="details" v-if="expanded">
            <div v-if="node.products.length > 0">
              <div v-for="p in node.products" v-bind:key="p._id" :class="p.active ?  'details__product' : 'details__product details__product--inactive'  ">
                Ean: {{p.product_id.ean}}<br>
                Product: {{p.product_id.name}}<br>
                Stock: {{p.stock}} <br>
                Organization: {{p.product_id.org[0].name}}<br>
                30d_sold: {{p.product_id.total_sold_30d}}<br>
                daily_demand: {{p.product_id.daily_demand}}<br>
                Location_id: {{ node._id }}
                Product_location_id: {{p._id}}
                Product_id: {{p.product_id._id}}
                <button style="margin-right:auto" class="color_dark_red" v-on:click="deassign_location({location_product_id: p._id, product_id: p.product_id._id, location_id: node._id, search: false, parent_id: parent_id  })" >𐄂</button>
              </div>
            </div>

            <div class="actions">
              <button v-on:click="clear_location({id: node._id })"> Delete </button>
             
            </div>
        </div>


    </div>  
</template>

<script>
import trx from '@/translations';
import {  mapActions, mapGetters } from 'vuex';


export default {
  name: 'AssignLocation',
  methods: {
    ...mapActions(['clear_location', 'get_most_sold_product', 'deassign_location', 'print_location_label']),
    getBackgroundStyle(dailyDemand) {
     
      if (dailyDemand == null || dailyDemand === 0) {
        dailyDemand = 0; // Default value for null or zero demand
      }

      if (!this.most_sold_product) {
        this.get_most_sold_product();
      }
       
      
      const intensity = Math.min(dailyDemand / this.maxDailyDemand, 1);
      
      const color = `rgba(0, 0, 255, ${intensity})`; // Red color with varying opacity
      return { background: color };
    },
    getBackgroundStyleTotal(dailyDemand) {
     
     if (dailyDemand == null || dailyDemand === 0) {
       dailyDemand = 0; // Default value for null or zero demand
     }

     if (!this.most_sold_product) {
       this.get_most_sold_product();
     }
      
     
     const intensity = Math.min(dailyDemand / this.most_sold_product, 1);
     
     const color = `rgba(177, 0, 255, ${intensity})`; // Red color with varying opacity
     return { background: color };
   }
  },
  computed: { 
    ...mapGetters(['most_sold_product' ]),
    status: function() {
      if (this.node.products.length > 0 && this.node.products.length < 1  ) {
        return this.node.products[0].stock > 0 ? 'used' : 'free'
      }
      else if (this.node.products.length > 1) {
        return 'error'
      } else {
        return 'free'  
      } 
        
    }
  },
  created() {
    
  },
  props: {
    node: Object,
    rack: String,
    parent_id: String,
    parent_type: String,
    maxDailyDemand: Number
  },
  data () {
    return {
        trx,
        expanded: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .tools, .print-button {
    display: none;
  }
  .locations__location__number:hover {
    transition: all 0.4s ;
    .tools {
       display: block;
       position: absolute;
       bottom:0;
       right: 0;
       padding: 0.5rem;
    }
    .print-button {
      position: absolute;
      display: block;
      top:0;
      right: 0;
      padding: 0.5rem;
    }
  }

  .indicator {
    height: 10px;
    width: 10px;
    position: absolute;
    left: 1px;
    bottom: 2px;
      border-radius: 50px;
    z-index: 2;
    &-total {
      height: 10px;
      width: 10px;
      position: absolute;
      left: 1px;
      z-index: 4;
      top: 2px;
      border-radius: 50px;
    }
  }

  .location_type {
    height: 5px;
    width: 50px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 5;
  }

  .pallet {
    background-color: black;
  }

  [class~='1/2pallet'] {
    
    background-color: orangered;
  }

  [class~='1/3pallet'] {
    
    background-color: yellow;
  }

  [class~='warehousing'] {
    
    background-color: blue;
  }

  .name {
    z-index: 6;
  }
  
  .details {
    position: absolute;
    @include box-bg;
    background: var(--color-white);
    z-index: 999;
    top:68px;
    right: auto;
    left: auto;
    min-width: 300px;
    cursor: default;
    z-index: 999;
    &:before {
      content: "▲";
      font-size: 18px;
      top: -3.9rem;
      left: 50%;
      line-height: 0px;
      position: relative;
      color: var(--color-primary-mid);
    }
    &__product {
      @include box_bg_white;
      margin: 2rem 0rem;
      &--inactive {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .inactive {
      cursor: none;
      opacity: 0.3;
    }
    
  }
</style>
