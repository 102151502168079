var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"expand-icon",on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_vm._v("ᐅ "+_vm._s(_vm.title))]),(_vm.expanded)?_c('div',{staticClass:"expanded"},[_c('div',{},[_c('table',{staticClass:"table-m"},[_c('tbody',_vm._l((_vm.node),function(value,key,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(key))]),(key === 'report')?_c('td',[_c('table',[_vm._m(0,true),_c('tbody',_vm._l((value.map(d => {
                            return {
                                // _id: d._id,
                                product: d.product_id[0].ean,
                                product_name: d.product_id[0].name,
                                type: d.type,
                                date: _vm.moment(d.located_datetime).format('DD-MM-YYYY'),
                                location: d.location_id.parent.name + '-' + d.location_id.name ,
                            }
                        }).sort((a, b) => a.type.localeCompare(b.type))),function(td){return _c('tr',{key:td},_vm._l((td),function(tdValue){return _c('td',{key:tdValue},[_c('span',[_vm._v(" "+_vm._s(tdValue)+" ")])])}),0)}),0)])]):( (typeof value === 'object') && ( key !== 'report'))?_c('td',[_c('table',[_c('tbody',_vm._l((value),function(innerValue,innerKey){return _c('tr',{key:innerKey},[_c('td',[_vm._v(_vm._s(innerKey))]),_c('td',[_vm._v(_vm._s(innerValue))])])}),0)])]):_c('td',[_vm._v(_vm._s(value))])])}),0)])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Product")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Location")])])])
}]

export { render, staticRenderFns }