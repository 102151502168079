<template>
  <div class="plans">
      <div v-for="plan in plans" v-bind:key="plan.id" :id="plan.id" class="plans__plan">
        <div class="plans__plan__name">
            <p>{{plan.name}}</p>
            {{plan.description}}
        </div>
        <div class="plans__plan__name__input --price">
          <label for="price">{{trx.subscriptions['Price /order']}}</label>
          <input type="number" name="price" :value="plan.price" disabled>
        </div>
      </div>
      <router-link v-if="action !== 'update'" :to="{name: 'terms'}" >{{trx.continue}}</router-link>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';

export default {
  name: 'subscription-form',
  components: {
   
  },
  props: {
    submit: Function,
    submitNmae: String,
    subscription: {},
    action: String
  },
  methods: {
    ...mapActions([ 'createSubscr', 'updateSubscr',])
  },
  computed: {
    ...mapGetters(['current_subscr', 'totals', 'plans'])
  },
  created() {
  },
  data () {
    return {
      trx,
    }
  }
}
</script>

<style lang="scss" scoped>

.plans {
  display: flex;
  flex-direction: column;
  &__calc {
      width: 20rem;
      margin: 2rem;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      position: relative;
      p, h4 {
        flex: 0 0 50%;
        text-align: right;
      }
     
  }
  &__plan {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    &__name__input {
      max-width: 10rem;
      margin:0rem 1rem;
      position: relative;
      input {
        padding: 1rem 2rem;
        text-align: right;
      }
      &.--price::before, &.--total::before {
        content: '€';
        font-size: 1.7rem;
        position: absolute;
        top: 2.8rem;
        left: 1rem;
      }
    }
    &__name {
      margin-right: auto;
    }
  }
  button {
    margin-top: 5rem;
    margin-left: auto;
  }
}


  
</style>
