import api from '@/api';
import router from '../../router';

/* eslint-disable no-debugger */

const state = {
    products: [],
    all_products: [],
    pending_inventory_pdf: '',
    shop_products: [],
    shop_products_list: [],
    product_image: [],
    total_prod_count: null,
    history_locations: [],
    searched_products: [],
    searched_linking_products: [],
    product_transfers: [],
};

const getters = {
    products: state => state.products,
    all_products: state => state.all_products,
    pending_inventory_pdf: state => state.pending_inventory_pdf,
    shop_products: state => state.shop_products,
    total_prod_count: state => state.total_prod_count,
    shop_products_list: state => state.shop_products_list,
    history_locations: state => state.history_locations,
    searched_products: state => state.searched_products,
    searched_linking_products: state => state.searched_linking_products,
    product_transfers: state => state.product_transfers,
};

const actions = {
    async syncProducts({ dispatch }, model) {
        await api.get("/bol/products/get/" + model.api_name);
        dispatch('getProducts');
    },
    async delete_product({ dispatch }, model) {
        await api.delete(`/product/${model._id}`);
        await dispatch('getProducts');
    },
    async syncProductsWoo({ dispatch }) {
        await api.get('/products/sync/woo');
        dispatch('getProducts')
    },
    async syncProductsShopify({ dispatch }) {
        await api.get('/products/sync/shopify');
        dispatch('getProducts')
    },
    async syncProductsLightspeed({ dispatch }) {
        await api.get('/products/sync/lightspeed');
        dispatch('getProducts')
    },
    async syncProductsShopware({ dispatch }) {
        await api.get('/products/sync/shopware');
        dispatch('getProducts')
    },
    async sync_products_mirakl({ dispatch }) {
        await api.get('/products/sync/mirakl');
        dispatch('getProducts')
    },
    async toggleFulfilment({ dispatch }, model) {
        await api.post("/product/fulfilment/toggle", { id: model });
        await dispatch('getProducts');
    },
    async fetch_total_products({ commit }) {
        const response = await api.get("/products/count");
        response !== undefined ? commit('set_total_prod_count', response.data) : '';
    },
    async getProducts({ commit }, model) {
        let new_model = model ? model : {};

        if (!model) {
            new_model.limit = 500; new_model.skip = 0;
        }

        const response = await api.get("/products?" + 'skip=' + new_model.skip + "&limit=" + new_model.limit);
        
        response !== undefined ? commit('setProducts', response.data) : '';
    },
    async getAllProducts({ commit }) {
        const response = await api.get("/products/all");
        response !== undefined ? commit('setAllProducts', response.data) : '';
    },
    async get_shop_products({ commit }, model) {
        const response = await api.get("/products/org/" + model);
        response !== undefined ? commit('set_shop_products', response.data) : '';
    },
    async search_products({ commit }, model) {
        console.log(model)
        let response;
        if (typeof model === 'string') {
            if (model === "") {
                commit('set_searched_products', [])
                return;
            }
            const limit = 10;
            response = await api.get("/products/search" + "?search=" + model + "&limit=" + limit);
        } else {
            if (model.query === "") {
                commit('set_searched_products', [])
                return;
            }
            model.limit = model.limit ? model.limit : 10;
            response = await api.get("/products/search" + "?search=" + model.query + "&limit=" + model.limit);
        }
        response !== undefined ? model.product_linking ? commit('set_searched_linking_products', response.data) : commit('set_searched_products', response.data) : '';
    },
    async addInventory({ commit, state, dispatch }, model) {
        model.products = state.products.filter((x) => {
            return x.pending_to_inventory
        }).map((x) => {
            if (x.pending_to_inventory) {
                return {
                    product: x._id,
                    coli: parseFloat(x.coli),
                    pallets: parseFloat(x.pallets),
                    type: x.type,
                    amount: parseFloat(x.pending_to_inventory)
                }
            }

        });

        const response = await api.post("/inventory/add_inventory", model);
        response !== undefined ? commit('setInventoryReq', response.data) : '';
        await dispatch('getPendingInventoryPdf');
        router.push({ path: 'products/inventory_ticket' });
    },
    async direct_check_in({ commit, dispatch }, model) {
        model.products = this.state.inventory.temp_checkin_rows.map((x) => {
            return {
                product: x.product,
                coli: parseFloat(x.coli),
                pallets: parseFloat(x.pallets),
                type: x.type,
                amount: parseFloat(x.amount_checkin)
            }
        });

        const response = await api.post("/inventory/add_inventory", model);

        response !== undefined ? commit('setInventoryReq', response.data) : '';
        await dispatch('getPendingInventoryPdf');
        router.push({ path: 'products/inventory_ticket' });
    },
    async getPendingInventoryPdf({ state }) {
        const response = await api.post('/inventory/pending_inventory_pdf', state.pending_inventory_pdf, { responseType: 'blob', });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'file.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
    },
    async search_product_ean({ commit }, model) {

        await commit('reset_shop_products');

        let final_search = model.search;
        let final_url;

        if (model.length > 3)
            final_search = model
        else {
            final_search = model.search
        }


        if (router.currentRoute.path !== '/scanner') {
            final_url = '/products_org/ean/'
        } else {
            final_url = '/products/ean/'
        }



        if (final_search.length > 3 || final_search.length === 0) {

            if (model.loading && model.loading !== undefined)
                model.loading(true);

            const response = await api.get(final_url + final_search);


            if (model.loading && model.loading !== undefined)
                model.loading(false);

            response !== undefined ? commit('set_shop_products', response.data) : '';
            response !== undefined ? commit('setProducts', response.data) : '';

            console.log(response.data)
        }

    },
    async search_product_ean_list({ commit }, model) {

        await commit('reset_shop_products_list');

        let final_search;
        let final_url;

        if (model.length > 3)
            final_search = model
        else {
            final_search = model.search
        }


        if (router.currentRoute.path !== '/scanner') {
            final_url = '/products_org/ean/'
        } else {
            final_url = '/products/ean/'
        }

        if (final_search > 3) {

            if (model.loading)
                model.loading(true);

            const response = await api.get(final_url + final_search);


            if (model.loading)
                model.loading(false);

            response !== undefined ? commit('set_shop_products_list', response.data) : '';
            response !== undefined ? commit('set_products_list', response.data) : '';

        }

    },
    async fetch_product_transfers({ commit }, model) {
        // The model must include a product_id
        const product = model.product_id;
        const response = await api.get(`/product/transfers/open/${product}`);

        response !== undefined ? commit('set_product_transfers', response.data) : '';
    },
    async get_history_locations({ commit }, model) {
        const response = await api.get(`/product/history_locations/${model.product_id}`);
        response !== undefined ? commit('set_history_locations', response.data) : '';
    },
    async link_product({ dispatch }, model,) {
        await api.get('/product/link/' + model.product_1 + '/' + model.product_2);
        await dispatch('getProducts');
    },
    async unlink_product({ dispatch }, model,) {
        await api.get('/product/unlink/' + model.parent_id + '/' + model.child_id);
        await dispatch('getProducts');
    },
    async setShippingMethod({ dispatch }, model) {
        await api.post('/product/assign_shipping', model);
        await dispatch('getProducts');
        await dispatch('getOrders', this.state.orders_date_range);
    },
    async setShipBox({ dispatch }, model) {
        await api.post('/product/assign_box', model);
        await dispatch('getProducts');
        await dispatch('getOrders', this.state.orders_date_range);
    },
    async setEmail({ dispatch }, model) {
        await api.post('/product/track_trace_email', {
            product_id: model.product_id,
            track_trace_email: model.track_trace_email
        });
        await dispatch('getProducts');
    },
    async setShareTT({ dispatch }, model) {
        await api.post('/product/share_track_trace', {
            product_id: model.product_id,
            share_track_trace: model.share_track_trace
        });
        await dispatch('getProducts');
    },
    async setNote({ dispatch }, model) {
        await api.post('/product/note', model);
        await dispatch('getProducts');
    },
    async setPickNote({ dispatch }, model) {
        await api.post('/product/pick_note', model);
        await dispatch('getProducts');
    },
    async setPackNote({ dispatch }, model) {
        await api.post('/product/pack_note', model);
        await dispatch('getProducts');
    },
    async setLeadTime({ dispatch }, model) {
        await api.post('/product/set_lead_time', model);
        await dispatch('getProducts');
    },
    async setShipTime({ dispatch }, model) {
        await api.post('/product/set_ship_time', model);
        await dispatch('getProducts');
    },
    async setReturnInstruction({ dispatch }, model) {
        await api.post('/product/return_instruction', model);
        await dispatch('getProducts');
    },
    async update_min_stock({ dispatch }, model) {
        await api.post('/product/stock/min/' + model.product_id, { value: model.value });
        await dispatch('getProducts');
    },
    async update_max_stock({ dispatch }, model) {
        await api.post('/product/stock/max/' + model.product_id, { value: model.value });
        await dispatch('getProducts');
    },
    async update_customs_settings({ dispatch }, model) {
        await api.post('/product/customs/' + model._id, {
            hs: model.customs.hs,
            value: model.customs.value,
            weight_kg: model.weight_kg,
        });
        await dispatch('getProducts');
    },
    async undoTransfer({ dispatch }, model) {
        await api.delete(`/product/transfer/${model.loc_prod_id}`);
        
        if (router.currentRoute.fullPath.includes('inbound_details')) {
            const path = window.location.pathname;

            // Split the path by '/' and get the last part
            const parts = path.split('/');
            const id = parts[parts.length - 1];
            console.log(id)
            await dispatch('fetchInboundItem', {id: id});
        } else {
            await dispatch('getProducts');
        }
    },
    async set_bol_confirm({ dispatch }, model) {
        await api.put(`/product/bol_confirm`, model);

        await dispatch('');
    },
    async get_product({ commit }, model) {
        try {
            const response = await api.get("/product/" + model);
            if (response && Array.isArray(response.data) && response.data.length > 0) {
                return response.data[0];
            } else {
                console.warn("No data available or data is not in the expected format.");
                return null;
            }
        } catch (error) {
            console.error("Error fetching product:", error);
            console.log(commit)
            return null;
        }
    }
};



const mutations = {
    setProducts: (state, products) => (state.products = products),
    setAllProducts: (state, all_products) => (state.all_products = all_products),
    syncProducts: (state, products) => (state.products = products),
    setInventoryReq: (state, pending_inventory_pdf) => (state.pending_inventory_pdf = pending_inventory_pdf),
    set_shop_products: (state, shop_products) => { state.shop_products = shop_products },
    reset_shop_products: (state) => { state.shop_products = [] },
    set_total_prod_count: (state, total_prod_count) => { state.total_prod_count = total_prod_count },
    set_shop_products_list: (state, shop_products_list) => { state.shop_products_list = shop_products_list },
    set_history_locations: (state, history_locations) => { state.history_locations = history_locations },
    set_searched_products: (state, searched_products) => { state.searched_products = searched_products },
    set_searched_linking_products: (state, searched_linking_products) => { state.searched_linking_products = searched_linking_products },
    set_product_transfers: (state, product_transfers) => { state.product_transfers = product_transfers },
};

export default {
    state,
    getters,
    actions,
    mutations
}
