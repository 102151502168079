<!-- src/components/Confetti.vue -->
<template>
  <canvas ref="confettiCanvas" :width="width" :height="height"></canvas>
</template>

<script>
  /* eslint-disable no-debugger */

export default {
  name: 'Confetti',
  props: {
    width: {
      type: Number,
      default: window.innerWidth
    },
    height: {
      type: Number,
      default: window.innerHeight
    }
  },
  methods: {
    launchConfetti() {
      debugger;
      const canvas = this.$refs.confettiCanvas;
      const ctx = canvas.getContext('2d');
      const confettiParticles = [];

      function createParticle() {
        const colors = ['#ff0', '#ff6', '#ff0', '#ff6', '#fff'];
        return {
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height - canvas.height,
          r: Math.random() * 6 + 4,
          d: Math.random() * 10 + 5,
          color: colors[Math.floor(Math.random() * colors.length)],
          tilt: Math.random() * 10 - 10,
          tiltAngleIncremental: Math.random() * 0.07 + 0.05,
          tiltAngle: Math.random() * Math.PI
        };
      }

      function drawParticle(particle) {
        ctx.beginPath();
        ctx.lineWidth = particle.r / 2;
        ctx.strokeStyle = particle.color;
        ctx.moveTo(particle.x + particle.tilt + particle.r / 3, particle.y);
        ctx.lineTo(particle.x + particle.tilt, particle.y + particle.tilt + particle.r / 5);
        ctx.stroke();
      }

      function updateParticle(particle) {
        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
        particle.x += Math.sin(particle.d);
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
        if (particle.y > canvas.height) {
          particle.x = Math.random() * canvas.width;
          particle.y = -20;
        }
      }

      function animateConfetti() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        confettiParticles.forEach((particle) => {
          updateParticle(particle);
          drawParticle(particle);
        });
        requestAnimationFrame(animateConfetti);
      }

      for (let i = 0; i < 100; i++) {
        confettiParticles.push(createParticle());
      }

      animateConfetti();
    }
  }
};
</script>

<style scoped>
canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
}
</style>