<template>
  <div class="inv_buttons">
    <div class="inv_button" @click="setType('package')">
      {{ this.trx.checkinv.type.package }}
      <p>{{ this.trx.checkinv.type.package_text }}</p>
    </div>
    <div class="inv_button" @click="setType('pallet')">
      {{ this.trx.checkinv.type.pallet }}
      <p>{{ this.trx.checkinv.type.pallet_text }}</p>
    </div>
    <div class="inv_button" @click="setType('container')"> 
      {{ this.trx.checkinv.type.container_other }}
      <p>{{ this.trx.checkinv.type.container_other_text }}</p>
    </div>
  </div>
</template>
<script>
import trx from "@/translations";

export default {
  name: "AddCheckinType",
  props: {
    updateType: Function,
    updateStep: Function,
  },
  methods: {
    setType(type) {
      this.updateType(type);
      this.updateStep(1);
    },
  },
  data() {
    return {
      trx,
    };
  }
};
</script>
<style lang="scss" scoped>
.inv_buttons {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.inv_button {
  cursor: pointer;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  border: 2px solid var(--color-primary);
  padding: 2rem;
  border-radius: 1rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--color-black);
  transition: all;
  transition-duration: 300ms;
}

.inv_button:hover {
  background-color: var(--color-primary);
  color: white;
}
</style>
