<template>
  <div class="add-subscription">
    <h3>{{trx.subscriptions["Invoices"]}}</h3>  
    <date-range-picker
      ref="picker"
      class="datePicker"
      :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
      :showWeekNumbers="true"
      :showDropdowns="true"
      :timePicker="false"
      :autoApply="true"
      opens="right"
      @update="fetch_invoice"
      v-model="dateRange"
    >
      <template v-slot:input="picker" style="min-width: 350px;">
          {{ moment(picker.startDate).format('DD-MM-YYYY') | date }} - {{ moment(picker.endDate).format('DD-MM-YYYY') | date }}
      </template>

      
    </date-range-picker>
<!-- {{curr_invoice}} -->
      
      <div v-if="curr_invoice[0]" class="invoice">
        <h3>Inzicht in de kosten</h3>
        <table class="table-m">
            <thead>
              <tr>
                <th><b>Dienst</b></th>
                <th style="text-align: left;"><b>Kosten</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{trx.settings.invoice.total_fulfilment}}</td>
                <td>{{curr_invoice[0].total_fulfilment | formatEuro}}</td>
              </tr>
              <tr>
                <td>{{trx.settings.invoice.total_used_shipment_method}}</td>
                <td>{{curr_invoice[0].total_used_shipment_method | formatEuro}}</td>
              </tr>
              <tr>
                <td>{{trx.settings.invoice.total_used_packaging}}</td>
                <td>{{curr_invoice[0].total_used_packaging | formatEuro}}</td>
              </tr>
              <tr>
                <td>{{trx.settings.invoice.total_used_surcharge}}</td>
                <td>{{curr_invoice[0].total_used_surcharge | formatEuro }}</td>
              </tr>
              <tr>
                <td><StorageItem title="Storage" :node="curr_invoice[0].storage.storage"></StorageItem> </td>
                
                <td>{{curr_invoice[0].storage.total_cost | formatEuro }}</td>
              </tr>
              <tr>
                <td><MutationItem title="mutations" :node="curr_invoice[0].mutations"></MutationItem> </td>
                <td></td>
              </tr>
              <tr>
                <td><b>{{trx.settings.invoice.total}}</b></td>
                <td>{{curr_invoice[0].total + curr_invoice[0].storage.total_cost | formatEuro}}</td>
              </tr>
            </tbody>
          </table>
        
        <br>
        <br>
        
        <!-- <div class="flex-40">
           <button v-on:click="export_invoice_pdf" >Get invoice</button>
        </div> -->
        <br>
        <br>
        <button v-on:click="export_invoice_data">{{trx.settings.invoice.download_details}}</button>
      </div>
      
      <div v-else class="">No invoice available</div>
  </div>
</template>

<script>
import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import StorageItem from './Storage/item.vue';
import MutationItem from './Storage/mutation_item.vue';

export default {
  name: 'add-subscription',
  components: {
      DateRangePicker,
      StorageItem,
      MutationItem
  },
  methods: {
    ...mapActions([ 'fetch_invoice',  'get_storage_price_total', 'get_storage_price', 'export_invoice_data', 'get_storage_data' ])
  },
  computed: {
    ...mapGetters(['curr_invoice', 'storage_data', 'storage_price_total', 'storage_price' ])
  },
  created() {
    this.fetch_invoice(this.dateRange);
    this.get_storage_data();
    this.get_storage_price_total();
    this.get_storage_price();
  },
  data () {
    const baseDate = moment().startOf('month').subtract(1, 'months')
    const startDate = new Date( baseDate.startOf('month'));
    const endDate = new Date( baseDate.endOf('month') );

    return {
      trx,
      dateRange: {startDate, endDate},
      moment: moment,
    }
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : ''
      }
    }
}
</script>

<style lang="scss" scoped>

    .invoice {
      @include box-bg-white;
      margin-top: 2rem;
      >div {
        padding: 0.5rem;
        @include box-bg-white;
        margin-top: 2rem;
        margin-right:2rem;
        
      }
    }

    .table-m td:nth-child(2) {
      text-align: right;
    }

    .storage  {
      align-content: flex-start;
      align-items: flex-start;
      > div {
        &:not(:last-child) {
          margin-right: 4rem;
        }
      }
    }
    // .add-subscription {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: flex-start;
    //     >h3 {
    //         margin-top:5rem;
    //     }
    //     @mixin def-padding {
    //         padding: 2rem;
    //     }
    //     &__plans-container {
    //         display: flex;
    //         flex-wrap: wrap;
    //         margin: 5rem 0rem;
    //         width: 100%;
    //         &__standard {
    //             flex: 0 0 100%;
    //             @include box-bg-white;
    //             padding: 2rem;
    //             margin:1rem 0rem;
    //             display: flex;
    //             flex-direction: column;
    //             justify-content: space-between;
    //         }
    //     }
    // }
</style>
