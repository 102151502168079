import api from '@/api';
/* eslint-disable no-debugger */

const state = {
    inbound_item: {},
    pending_inbounds: null,
    checkedin_inbounds: null,
    // All current inbounds ever made by the organization
    inbounds: null,
    inbound: null,
    // The inbound_items of the current selected inbound
    inbound_items: [],
};

const getters = {
    inbound_item: state => state.inbound_item,
    pending_inbounds: state => state.pending_inbounds,
    checkedin_inbounds: state => state.checkedin_inbounds,
    inbounds: state => state.inbounds,
    inbound: state => state.inbound,
    inbound_items: state => state.inbound_items,
};

const actions = {
    async fetchInboundItem ({ commit }, model) {
        const response = await api.get("/inbound_item/" + model.id);
        
        response !== undefined ? commit('set_inbound_item', response.data) : '';  
    },
    async fetchPendingInbounds({ commit }) {
        const response = await api.get("/inbounds/status/pending");
        response !== undefined ? commit('setPendingInbounds', response.data) : '';
    },
    async fetchCheckedinInbounds({ commit }) {
        const response = await api.get("/inbounds/status/checked_in");
        response !== undefined ? commit('setCheckedinInbounds', response.data) : '';
    },
    async fetchInbounds({ commit }) {
        const response = await api.get("/inbounds");
        
        if (response === undefined) {
            return '';
        }

        const data = response.data;
        // Sort by status, pending first
        data.sort((a, b) => {
            if (a.status === 'pending') return -1;
            if (b.status === 'pending') return 1;
            return 0;
        });
        commit('setInbounds', data);
    },
    async fetchInbound({ commit }, model) {
        const response = await api.get("/inbound/" + model.id);
        response !== undefined ? commit('setInbound', response.data) : '';
    },
    async fetchInboundItems({ commit }, model) {
        try {
            const response = await api.get(`/inbound_item/inbound/${model.id}`);
            response !== undefined ? commit('setInboundItems', response.data ? response.data : []) : '';
        } catch(err) {
            commit('setInboundItems', [])
        }
    },
    async delete_inb_item({ dispatch }, model) {
        await api.delete(`/inbound_item/delete/${model.inbound_id}/${model.item_id}`);
        dispatch('fetchPendingInbounds');
    },
    async edit_inb_item({dispatch}, model) {
        await api.put(`/inbound_item/edit/customer/${model._id}`, {
          units: model.units,
          packed_per: model.packed_per,
          batch_nr: model.batch_nr,
          expiration_date: model.expiration_date,
          notes: model.notes,
          package_closed: model.package_closed,
        });

        dispatch('fetchPendingInbounds');
    },
    async editInboundItemDetails ({ dispatch }, model) {
        const response = await api.put("/inbound_item/edit/checkin" + model._id, model);
        response !== undefined ? dispatch('fetchInboundItem', {id: model._id}) : '';  
        dispatch('fetchPendingInbounds');
    },

    async addSurcharge ({ dispatch }, model) {
        console.log(model)
        const response = await api.put("/inbound_item/surcharge/" + model.id, model)
        console.log(response)
        response !== undefined ? dispatch('fetchInboundItem', {id: model.id}) : '';

    },
    async deleteSurcharge ({ dispatch }, model) {
        console.log(model)
        const response = await api.delete(`/inbound_item/surcharge/${model.id}/${model.index}`, model)
        response !== undefined ? dispatch('fetchInboundItem', {id: model.id}) : '';  

    },
    async findLocation ({ dispatch }, model) {
        // const transferLocation = model.transferLocation ? model.transferLocation : false
        const response = await api.post(`/inbound_item/locations/find/${model.inbItemId}`, {
            type: model.type,
            amount: model.amount,
            included: typeof model.included === 'string' ? [] : model.included,
            existing_locations: model.enable_old_locations,
        })
        response !== undefined ? dispatch('fetchInboundItem', {id: model.inbItemId}) : '';  

    },
    async deleteReservedLocation ({ dispatch }, model) {
        const response = await api.put("/inbound_item/deleteLocation/" + model.inbItemId  + "/" + model.locationId )
        response !== undefined ? dispatch('fetchInboundItem', {id: model.inbItemId}) : '';  
    },
    async deleteReservedLocations ({ dispatch }, model) {
        console.log(model)
        const response = await api.delete("/inbound_item/reserved_locations/" + model.id)
        response !== undefined ? dispatch('fetchInboundItem', {id: model.id}) : '';
    },
    async addInbItemExpirationDate ({ dispatch }, model) {
        const response = await api.put('/inbound_item/expiration_date/' + model.id + '/' + model.expiration_date)
        response !== undefined ? dispatch('dummylog') : '';  
    },
    async submitInbItem ({dispatch}, model) {
        const response = await api.put('/inbound_item/submitInboundItem/' + model.id)
        response !== undefined ? dispatch('dummylog') : ''; 
    },
    async addTransfer({ dispatch }, model) {
        const response = await api.put(`inbound_item/addTransfer/${model.inbItemId}/${model.departedLocationId}`)
        response !== undefined ? dispatch('fetchInboundItem', {id: model.inbItemId}) : ''; 
    },
};

const mutations = {
    set_inbound_item: (state, inbound_item) => (state.inbound_item = inbound_item),
    setPendingInbounds: (state, pending_inbounds) => (state.pending_inbounds = pending_inbounds),
    setCheckedinInbounds: (state, checkedin_inbounds) => (state.checkedin_inbounds = checkedin_inbounds),
    setInbounds: (state, inbounds) => (state.inbounds = inbounds),
    setInbound: (state, inbound) => (state.inbound = inbound),
    setInboundItems: (state, inbound_items) => (state.inbound_items = inbound_items),
};

export default {
    state,
    getters,
    actions,
    mutations
}
