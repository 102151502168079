<template>
  <div class="InventoryTicket">
        <h3>{{trx.orders['Send your products to us']}}</h3>
        <button v-on:click="getPendingInventoryPdf">
            Download ticket
        </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
/* eslint-disable no-debugger */

export default {
  name: 'InventoryTicket',
  methods: {
    ...mapActions(['getPendingInventoryPdf'])
  },
  computed: {
    ...mapGetters(['pending_inventory'])
  },
  data () {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>
   h3 {
     margin-bottom: 5rem;
   } 
</style>
