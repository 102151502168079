// loadingPlugin.js
export default function loadingPlugin(store) {
    store.subscribeAction({
      before: () => {
        store.commit('setLoading', true);
      },
      after: () => {
        store.commit('setLoading', false);
      },
      error: () => {
        store.commit('setLoading', false);
      },
    });
  }
  