<template>
  <div class="container">
    <input
      v-model="inputValue"
      v-on:input="handleKeyDown"
      :placeholder="this.trx.products.search"
    />
  </div>
</template>
<script>
import trx from "@/translations";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProductSearch",
  methods: {
    ...mapActions(["getProducts", "search_product_ean"]),
    handleKeyDown() {
      if (this.inputValue.length < 3 || this.inputValue.length > 20) {
        if (this.inputValue.length == 0) {
          this.getProducts();
        }
        return;
      }
      this.search_product_ean({
        search: this.inputValue,
      });
    },
  },
  data() {
    return {
      trx,
      inputValue: "",
    };
  },
  computed: {
    ...mapGetters(["products"]),
  },
  created() {
    this.getProducts();
  },
};
</script>
<style>
.container {
  margin: 0 0 4rem 0;
}
</style>
