<template>
    <div v-if="hist_l_expanded" class="product__history_locations">
       
        <h4>{{  trx.history_locations.name }}</h4>
        <div v-if="history_locations.length > 0">

            <div class="product__history_locations__linked_locations" v-for="(x, idx) in history_locations" v-bind:key="`location` + x._id + '-' + idx + '-' + `-` + x.parent.name + `-` + x.name">
                <h3>{{ x.parent.name }} - {{ x.name }}</h3>
            </div>
        </div>
        <div v-else>
            <div class="product__history_locations__linked_locations">
                <h3>{{ trx.history_locations.none }}</h3>
            </div>
        </div>

      
    </div>
</template>
        
<script>

import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
/* eslint-disable no-debugger */


export default {
    name: "History_locations",
    computed: {
        ...mapGetters(['history_locations',]),
    },
    methods: {
        ...mapActions([ 'get_history_locations' ]),
    },
    components: {
        
    },
    props: {
        product: Object,
        i: Number,
        hist_l_expanded: Boolean,
        component_name: String
    },
    data() {
        return {
            trx,
        }
    },
    
}

</script>

<style lang="scss" scoped>
   .product__history_locations {
       @include box-bg;
       padding: 2rem;
       &__linked_locations {
           margin-top: 2rem;
           @include box-bg-dash;
       }
   }
</style>
