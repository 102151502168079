<template>
  <div>
    <div>
      <h3 class="state-text">Vul de gegevens in van de klant</h3>
    </div>
    <div>
      <OrderForm
        :updateStep="updateStep"
      />
    </div>
    <StepController :text="trx.orders.to_confirm" :step="1" :updateStep="updateStep" />
  </div>
</template>
<script>
import trx from "@/translations";

import OrderForm from "./order_form.vue";
import StepController from "./step_controller.vue";

export default {
  name: "OrderInformation",
  props: {
    updateStep: Function,
  },
  data() {
    return {
      trx,
    }
  },
  components: {
    OrderForm,
    StepController,
  },
};
</script>
<style lang="scss" scoped></style>
