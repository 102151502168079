<template>
  <div class="products-wrp">
    <div class="prd_fixed">
    <h3>{{ trx.orders["Products"] }}</h3>
    <div v-if="products !== undefined" class="totals">
      <infoBox class="hidden-md" :name="trx['Total']" :small_number="searched_products.length === 0 ? products.length : searched_products.length"
      :big_number="searched_products.length === 0 ? total_prod_count : searched_products.length" />
    </div>
    <!-- <div>
      <router-link :to="{ path: '/products/Import' }"></router-link>
    </div> -->
    <div class="btn-nav">
      <div class="flex">
        <router-link v-if="!$route.path.includes('add')" :to="{ path: '/pre_notify' }" class="button">
          + {{ trx.products["Add inventory"] }}
        </router-link>
        <router-link :to="{ path: '/checkin_inventory' }"
          class="button color_dark">
          {{ trx.products["Inventory checkin"] }}
        </router-link>
        <div class="sync_buttons">
        <button v-on:click="drp_open = !drp_open" class="button color_woo">
          {{ trx.products.sync.import_products }}
          <span v-if="!drp_open">▼</span> <span v-if="drp_open">▲</span>
        </button>
        <button class="button" v-on:click="weekEmail()">
          7-day Email
        </button>

        <div v-if="drp_open" :class="drp_open ? 'controle__btns is-open' : 'controle__btns'">
          <span class="help" :title="trx.products.sync_help">Sync met:</span>
          <button v-on:click="syncProducts({ api_name: 'bol' })" class="button color_bol">
            {{ trx.products.sync.bol }}
          </button>
          <button v-on:click="syncProducts({ api_name: 'bol_be' })" class="button color_bol">
            {{ trx.products.sync.bol }} BE
          </button>
          <button v-on:click="syncProductsWoo" class="button color_woo">
            {{ trx.products.sync.woo }}
          </button>
          <button v-on:click="syncProductsShopify" class="button color_shopify">
            {{ trx.products.sync.shopify }}
          </button>
          <button v-on:click="sync_products_mirakl" class="button color_mirakl">
            {{ trx.products.sync.mirakl }}
          </button>
          <button v-on:click="syncProductsLightspeed" class="button color_lightspeed">
            {{ trx.products.sync.lightspeed }}
          </button>
          <button v-on:click="syncProductsShopware" class="button color_shopware">
            {{ trx.products.sync.shopware }}
          </button>
          <router-link to="/products/import" class="button color_excel_import">
            {{ trx.products.import.xlsx }}
          </router-link>

          <router-link to="/products/new_product" class="button color_add_product">
            {{ trx.products.import.add_product }}
          </router-link>

          
        </div>
        
        </div>
        <Search class="search" :search__action="search_products" :searchOnEmpty="true" placeholder="Zoeken op EAN ..." />
       
      </div>
      
      
    </div>
    </div>
    <section v-if="$route.path.includes('add') ||
      $route.path.includes('inventory_ticket') ||
      $route.path.includes('checkin_inventory')
      " class="sub backdrop light big">
      <router-view></router-view>
    </section>

    <div v-if="searched_products.length !== 0" class="products">
      <ProductItem v-for="(product, i) in searched_products.filter((x) => {
          return x.parent == null;
      })" v-bind:key="product._id" :product="product" :i="i" :user="user" />
    </div>
    <div v-if="products !== (null || undefined) && searched_products.length === 0" class="products">
      <ProductItem v-for="(product, i) in products.filter((x) => {
        return x.parent == null;
      })" v-bind:key="product._id" :product="product" :i="i" :user="user" />
    </div>

    <div class="flex pagination" v-if="total_prod_count > limit && searched_products.length === 0">
      <button class="flex-10 flex-20-md" v-on:click="previousPage()">ᐸ</button>

      <div class="flex-80 flex-60-md">
        <button :class="page == Math.round(skip / limit)
          ? 'active show page_button'
          : page - 4 < Math.round(skip / limit) &&
            page + 4 > Math.round(skip / limit)
            ? 'show page_button'
            : 'page_button'
          " v-for="page in Array.from(
    { length: total_prod_count / limit + 3 },
    (x, i) => i
  )" v-bind:key="page" v-on:click="to_page(page)">
          {{ page }}
        </button>
      </div>
      <button class="flex-10 flex-20-md" v-on:click="nextPage()">ᐳ</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import infoBox from "@/components/Layout/InfoBox";
import ProductItem from "./product_item.vue";
import trx from "@/translations";
import Search from "@/components/Search";
import authCheck from "@/lib/authCheck";

/* eslint-disable no-debugger */

export default {
  name: "Products",
  computed: {
    ...mapGetters([
      "user",
      "products",
      "current_org",
      "shipment_boxes_list",
      "total_prod_count",
      "searched_products",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchUser",
      "getProducts",
      "search_product_ean",
      "setEmail",
      "getProductLocations",
      "setShareTT",
      "syncProductsWoo",
      "syncProductsShopify",
      "syncProductsLightspeed",
      "syncProductsShopware",
      "sync_products_mirakl",
      "setShippingMethod",
      "setProductLocation",
      "setNote",
      "setReturnInstruction",
      "update_min_stock",
      "syncProducts",
      "toggleFulfilment",
      "setShipBox",
      "linkProductLocation",
      "fetch_shipment_boxes_list",
      "fetch_surcharges_list",
      "fetch_shipment_methods_list",
      "fetch_total_products",
      "search_products",
      "weekEmail"
    ]),
    authCheck,
    nextPage() {
      this.skip += this.limit; // For the next page you just increment 'skip' for the page size 'limit'
      this.getProducts({ limit: this.limit, skip: this.skip });
    },
    previousPage() {
      if (this.skip > 0) {
        this.skip -= this.limit; // For the previous page, you just increment 'skip' for the page size 'limit'
        this.getProducts({ limit: this.limit, skip: this.skip });
      }
    },
    to_page(page) {
      this.skip = page * this.limit; // For the next page you just increment 'skip' for the page size 'limit'
      this.getProducts({ limit: this.limit, skip: this.skip });
    },
  },
  components: {
    infoBox,
    ProductItem,
    Search,
  },
  created() {
    // const self = this;
    this.fetchUser()
    debugger;
    this.getProducts({ limit: this.limit, skip: this.skip });
    this.fetch_shipment_boxes_list();
    this.fetch_surcharges_list();
    this.fetch_shipment_methods_list();
    this.fetch_total_products();
  },
  mounted() {
    
   },
  data() {
    return {
      trx,
      test1: function (x) {
        alert(x);
      },
      drp_open: false,
      limit: 50,
      skip: 0,
      allowDrop: function (ev) {
        ev.preventDefault();
      },
      drag: function (ev) {
        ev.dataTransfer.setData("text", ev.target.id);
      },
      drop: function (ev) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild(document.getElementById(data));
      },
    };
  },
  watch: {
    searched_products: function () {
      
    },
  },
};
</script>

<style lang="scss">
.products-wrp {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  .action-bar {
    margin-bottom: 5rem;
  }
}

.prd_fixed {
  position: fixed;
  width: 85vw;
  padding-top: 5rem;
  top: 0;
  background: var(--color-white) !important;
  z-index: 99;
}

h3 {
  margin: 0rem 0rem;
}


.btn-nav {
  >div {
    >.sync_buttons, a {
      @media screen and (max-width: $bp-medium) {
        display: block;
        min-width: 90%;
        width: 90% !important;
        text-align: center;
        >button {
          width: 100%;
        }
      }
     
    }
  }
}

.sync_buttons {
  position: relative;
  display: inline-block;
}

.search {
  input {
      width: 100% !important;
      border: 2px solid var(--color-primary);
      height: 4rem !important;
      font-size: 1.7rem;
      margin-bottom: 0.6rem;
      padding: 1rem 1.9rem;
  }
}

.controle__btns {
  @include box-bg-dash;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  top: 5rem;

  transform: translateY(-10px);
  transition: all 0.5s ease-out;

  &.is-open {
    opacity: 1;
    animation: slideDown 0.5s;
    transform: translateY(0);
  }

  .button {
    margin-top: 1rem !important;
  }
}

.product__location__link {
  max-width: 100px;
}

.product__location__minmax {
  position: relative;

  input {
    max-width: 80px;
    height: 50px;
    padding: 20px 10px;
    padding-top: 30px;
  }

  .min span {
    top: 8px;
    left: 10px;
    position: absolute;
  }

  .max span {
    top: 65px;
    left: 10px;
    position: absolute;
  }
}

.button {
  @include button;
  margin-right: 0.2rem;
}

.color_excel_import {
  text-align: center;
  background-color: var(--color-excel);
}

.color_add_product {
  background-color: var(--color-primary-mid);
}

.pagination {
  text-align: right;
  align-self: flex-end;
  @media screen and (max-width: $bp-medium) {
    width: 100%;

  }
}

.page_button {
  background-color: var(--color-grey-dark-2);
  visibility: hidden;
  display: none;

  &.active {
    background-color: var(--color-primary);
  }

  &.show {
    visibility: visible;
    display: inline-block;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
