import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')

// Define a global filter in Vue
Vue.filter('formatEuro', function(value) {
  if (!value) return '';
  // Create an instance of Intl.NumberFormat for Euro formatting
  const formatter = new Intl.NumberFormat('nl-NL', { // Using Ireland locale, you can choose others like 'de-DE' for Germany etc.
    style: 'currency',
    currency: 'EUR'
  });
  return formatter.format(value);
});
