<template>
<div>
	<h1>Order aanpassen</h1>
	<div v-if="order && order.shipmentDetails">
		<!-- Basic customer information -->

		<br />
		<h3>Klantgegevens</h3>
		<div class="flex">
			<div class="flex-50">
				<label for="firstname">Voornaam</label>
				<input id="firstname" type="text" v-model="order.shipmentDetails.firstName" />
			</div>
			<div class="flex-50">
				<label for="surname">Achternaam</label>
				<input id="surname" type="text" v-model="order.shipmentDetails.surname" />
			</div>
		</div>
		<div class="flex">
			<div class="flex-100">
				<label for="email">Email</label>
				<input id="email" type="email" v-model="order.shipmentDetails.email" />
			</div>
		</div>
		<div class="flex">
			<div class="flex-40">
				<label for="streetname">Straatnaam</label>
				<input id="streetname" type="text" v-model="order.shipmentDetails.streetName" />
			</div>
			<div class="flex-30">
				<label for="housenumber">Huisnummer</label>
				<input id="housenumber" type="number" v-model="order.shipmentDetails.houseNumber" />
			</div>
			<div class="flex-30">
				<label for="housenumber_addition">Toevoeging</label>
				<input id="housenumber_addition" type="text" v-model="order.shipmentDetails.houseNumberExtension" />
			</div>
		</div>
		<div class="flex">
			<div class="flex-40">
				<label for="zipcode">Postcode</label>
				<input id="zipcode" type="text" v-model="order.shipmentDetails.zipCode" />
			</div>
			<div class="flex-30">
				<label for="city">Stad</label>
				<input id="city" type="text" v-model="order.shipmentDetails.city" />
			</div>
			<div class="flex-30">
				<label for="country">Land</label>
				<select id="country" v-model="order.shipmentDetails.countryCode">
					<option v-for="value in countries" :key="value.value" :value="value.value">{{ value.name }}</option>
				</select>
			</div>
		</div>

		<!-- Product information -->
		<br />
		<h3>Producten</h3>
		<div v-if="order.products">
			<div class="product" v-for="product in order.products" :key="product.ean + product.quantity">
				<p>{{ product.title.substring(0, 50) }}</p>
				<p>{{ product.ean }}</p>
				<!-- <div>
					<label for="product_quantity">Hoeveelheid</label>
					<input min="1" id="product_quantity" class="product_quantity" type="number" v-model="product.quantity" />
				</div> -->
				<div class="flex">
					<p>Hoeveelheid: </p>
					<p>{{ product.quantity }}</p>
				</div>
				<button class="btn_danger" @click="deleteProduct(product)">Verwijderen</button>
			</div>
			<!-- <div>
				<button @click="() => this.isOpen = true">Product toevoegen</button>
			</div> -->
		</div>
		
		<br />
		<button @click="saveChanges()">Opslaan</button>
	</div>
	
	<!-- <Popup :closed="!isOpen" :title="'Product toevoegen'" @handleClose="handleClose">
		<input type="text" v-model="search" />
		<div v-for="product in this.product_list" :key="product._id">
			<div>
				<p>{{ product.name }}</p>
			</div>
			<div class="flex">
				<p>{{ product.ean }}</p>
				<label for="product_quantity">Hoeveelheid</label>
				<input min="1" id="product_quantity" class="product_quantity" type="number" v-model="product.quantity" />
				<button @click="addProduct(product)">Product toevoegen</button>
			</div>
		</div>
	</Popup> -->
</div>
</template>
<script>
import api from '@/api';

import { mapGetters, mapActions } from 'vuex';

// import Popup from '@/components/Layout/popup.vue';

export default {
	computed: {
		...mapGetters(['order', 'products', 'searched_products', 'countries'])
	},
	methods: {
		...mapActions(['get_order', 'getProducts', 'search_products', 'fetch_countries']),

		handleClose() {
			this.isOpen = false;
		},

		addProduct(product) {
			// Check if product already exists in order
			const productIndex = this.order.products.findIndex((p) => p.ean === product.ean);
			if (productIndex !== -1) {
				this.order.products[productIndex].quantity += product.quantity ? Number(product.quantity) : 1;
				return;
			}

			this.order.products.push({
				ean: product.ean,
				quantity: product.quantity ? Number(product.quantity) : 1,
				title: product.name,
				product_id: product._id,
				cancellationRequest: false,
			})
		},

		deleteProduct(product) {
			const productIndex = this.order.products.findIndex((p) => p.ean === product.ean);
			if (productIndex !== -1) {
				this.order.products.splice(productIndex, 1);
			}
		},

		async retrieveProducts() {
			await this.getProducts({ limit: 10 });
			this.product_list = this.products;
		},
		
		async saveChanges() {
			let new_order = JSON.parse(JSON.stringify(this.order));
			console.log(new_order)
			// new_order.products.forEach((product) => {
			// 	product.product_id = product.product_id._id;
			// })
			await api.put('/order/edit/' + this.order._id, new_order);
			this.$router.push('/shipments');
		},

		async getOrder() {
			await this.get_order(this.$router.currentRoute.params.id);
			if (this.order.shipmentDetails.houseNumberExtension === null) {
				this.order.shipmentDetails.houseNumberExtension = "";
			}
			if (this.order.shipmentDetails.countryCode === null) {
				this.order.shipmentDetails.countryCode = "";
			}
		}
	},
	created() {
		this.getOrder();
		this.retrieveProducts();
		if (this.countries.length === 0) {
			this.fetch_countries();
		}
	},
	components: {
		// Popup
	},
	data() {
		return {
			isOpen: false,
			search: "",
			product_list: [],
			debounceTimer: null
		}
	},
	watch: {
		search: {
			handler: function (val) {
				if (val.length === 0) {
					this.product_list = this.products;
				}

				if (val.length < 3) {
					return;
				}

				clearTimeout(this.debounceTimer);

				this.debounceTimer = setTimeout(() => {
					this.search_products({ query: val, limit: 10 });
				}, 500);
			},
			deep: true
		},
		searched_products: {
			handler: function (val) {
				this.product_list = val;
			},
			deep: true
		}
	}
}
</script>
<style lang="scss" scoped>
.product {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-grey-light-3);
	border-radius: var(--border-radius);
	padding: 0.5rem 1rem;

	&_quantity {
		width: fit-content;
	}
}
</style>