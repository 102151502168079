<template>
  <div class="newOrder-section">
    <h1 class="flex-100">Check-in (beta)</h1>
    <br>

    <div class="">

    <v-select ref="product_ean" class="flex-80" 
    v-if="shop_products"
    @search="(search, loading) => { 
        search_product_ean({search: search, loading: loading})
    }"
    open="true"
    v-model="selected_product"
    @input="add_temp_checkin_record({ean: selected_product })"

      :reduce="product => product.ean" :options="shop_products" label="ean" > 
        
        <template slot="option" slot-scope="option" >
            
            <span v-if="option.locations.length > 0">{{ option.locations[0].location_id.parent.name }} - {{option.locations[0].location_id.name }} - {{ option.name }}</span> <br>
            <small class="text-muted">
                {{ option.ean }}
            </small>
        </template>
    </v-select >

    <!-- <button class="flex-20" v-on:click="scan_product_ean = !scan_product_ean">📷</button> -->
    </div>

    <div class="chinrws">
      <div class="chinrws__row flex" v-for="(x,i) in temp_checkin_rows" v-bind:key="x.ean">

        <div class="ean flex-20" >
          <p>{{x.ean}}/{{x.product_item.api_name}}</p>
          <h6>{{x.product_item.name}}</h6>
        </div>

        <div class="check_in_amount flex-10">
          <input type="number" v-model="x.amount_checkin"  />
        </div>

        <div class="flex-25">
          <SelectShipMethod :product="x.product_item" />
          <br>
          <SelectShipBox :product="x.product_item" />               
        </div>

        <div class="flex-35">
          <ProductLocation :node="x.product_item" :i="i"  />     
        </div> 
      </div>
    </div>

    <InventoryForm 
      :model="{
        contact_person: this.current_org.contact_person,
        shipping_address: this.current_org.address.line1, 
        contact_person_email: this.current_org.contact_person_email,
        tack_trace: '',
        sended: moment(new Date()).toISOString(),
        expected_delivery: moment(new Date()).toISOString(),
        phone: this.current_org.phone,
        products: this.temp_checkin_rows
      }"
      :submit="direct_check_in"
      :submitNmae="trx.create"    
      :AddProducts="temp_checkin_rows" 
    />

  </div>

</template>
 

<script>

/* eslint-disable no-debugger */

import Vue from 'vue';
import vSelect from 'vue-select';
import trx from '@/translations';
import InventoryForm from '../Products/form';
import ProductLocation from '../Products/product_location.vue';
import SelectShipBox from '../Products/SelectShipBox.vue'
import SelectShipMethod from '../Products/SelectShipMethod.vue';
import moment from 'moment';
// import { StreamBarcodeReader } from "vue-barcode-reader";

import { mapGetters, mapActions } from 'vuex';
import 'vue-select/dist/vue-select.css';


// import ean_added_audio from '@/assets/audio/ean_checked.mp3'

Vue.component('v-select', vSelect)

export default {
  name: 'Checkin',
  components: {
      // StreamBarcodeReader,
      // VGrid,
      ProductLocation,
      SelectShipBox,
      SelectShipMethod,
      InventoryForm,
  },
  methods: {
    ...mapActions(['fetchOrgs', 'fetch_curr_org_details', 'getProducts', 'get_shop_products', 'direct_check_in', 'fetch_shipment_methods_list', 'search_product_ean', 'add_temp_checkin_record' ]),
    listen_to_ean_input() {
        let code = "";

        window.addEventListener('keypress', e => {
          
          if (e.keyCode === 13) {
                  if(code.length > 10) {
                    this.add_temp_checkin_record({ean:code});
                    
                    /// code ready to use                
                    code = "";
                }
            } else {
                code += e.key; //while this is not an 'enter' it stores the every key            
            }
        });
    }
  },
  created() {
      this.getProducts();
      this.fetchOrgs();
      this.listen_to_ean_input(this);
      if (this.current_org === null) {
        this.fetch_curr_org_details()
      }
  },
  computed: {
      ...mapGetters(['products', 'current_org', 'orgs', 'shipment_details', 'shop_products','temp_checkin_rows' ]),
  },
  data () {
    return {
      trx,
      moment,
      selected_product: null,
    }
  }
}
</script>

<style lang="scss" scoped>

  .chinrws {
    &__row {
      justify-content: space-between;
      @include box-bg-dash;
      padding: 2rem;
    }
  }
</style>
