<template>
  <div class="orgs-form">
        <vue-form-generator :schema="schema" :model="FormModel" :options="formOptions"></vue-form-generator>
  </div>
</template>

<script>

import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import trx from '@/translations';

Vue.use(VueFormGenerator)

export default {
  name: 'organization-form',
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  props: {
    submit: Function,
    submitNmae: String,
    model: {}
  },
  data () {
    return {
      trx,
      FormModel: this.model,
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Organization name'] + "*",
            model: 'name',
            placeholder: 'Apple incl.',
            required: true,
            validator: VueFormGenerator.validators.string
          },
          {
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Organization address']+ "*",
            placeholder: 'Databankweg 18, 3821AL, Amersfoort',
            model: 'address.line1',
            required: true,
            validator: VueFormGenerator.validators.string,
            hint: trx.users.address_hint
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Contact Person']+ "*",
            placeholder: 'John Doe',
            model: 'contact_person',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'email',
            label: trx.organizations['Contact Person Email']+ "*",
            placeholder: 'mail@exaple.com',
            model: 'contact_person_email',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Phone']+ "*",
            placeholder: '+31 (0) 85 0609 250',
            model: 'phone',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations.coc_number + "*",
            placeholder: '82327025',
            model: 'coc_number',
            min: 8,
            max: 8,
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations.vat_number + "*",
            placeholder: '',
            model: 'vat_id',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'Submit',
            buttonText: this.submitNmae,
            onSubmit: this.submit,
            validateAfterChanged: true,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        // validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
   
</style>
