<template>
  <div>
    <div v-if="selectedProduct === null">
      <ProductList
        :products="all_products"
        :updateData="updateData"
        :updateSelectedProduct="updateSelectedProduct"
      />
    </div>
    <div v-else>
      <div class="back_button" @click="back">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 12H20"
            stroke="#33363F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.28481 11.7966L10.735 7.90356C11.2645 7.52535 12 7.90385 12 8.55455V15.4454C12 16.0961 11.2645 16.4746 10.735 16.0964L5.28481 12.2034C5.14522 12.1037 5.14522 11.8963 5.28481 11.7966Z"
            fill="#33363F"
          />
        </svg>
        <h5><b>{{ this.trx.checkinv.checkin_product.back }}</b></h5>
      </div>
      <div class="products">
        <div class="flex input_container">
        <div class="amount_container">
          <div class="amount_separator flex">
            <div class="input_label flex-100-md">
              <label for="input_amount"><h6>{{ this.trx.checkinv.checkin_product.unit_amount }}</h6></label>
              <input
                v-model="amount"
                id="input_amount"
                type="number"
                :placeholder="this.trx.checkinv.checkin_product.amount"
              />
            </div>
            <div class="input_label flex-100-md">
              <label for="input_per"><h6>{{ this.trx.checkinv.checkin_product.box_amount }}</h6></label>
              <input
                v-model="per"
                id="input_per"
                type="number"
                placeholder="E.g. 20"
              />
            </div>
            <div class="input_label total flex-100-md">
              <label for="input_per"><h6>{{ this.trx.checkinv.checkin_product.total_amount }}</h6></label>
              <input
                id="input_per"
                type="number"
                :value="(amount * per)"
                readonly
                disabled
              />
            </div>
            <div class="input_label flex-100-md">
              <label for="input_expiration_date"><h6>{{ this.trx.checkinv.checkin_product.expiration_date }}</h6></label>
              <input
                v-model="expiration_date"
                id="input_expiration_date"
                type="date"
              />
            </div>
            <div class="input_label flex-100-md">
              <label for="input_batchnr"><h6>{{ this.trx.checkinv.checkin_product.batch_number }}</h6></label>
              <input
                v-model="batchnr"
                id="input_batchnr"
                :placeholder="this.trx.checkinv.checkin_product.batch_number"
                type="text"
              />
            </div>
            <div class="flex-100-md">
              <h6> .</h6>
              <button class="full_width" @click="addProduct">{{ this.trx.checkinv.checkin_product.add }}</button>
            </div>
          </div>
          
        </div>

        {{ this.trx.checkinv.checkin_product.divergent }}
      
      <br />
      <h4>{{ this.trx.checkinv.checkin_product.check }}</h4>
       
      </div>
        <ProductItem
          :product="selectedProduct"
          :i="0"
          :key="selectedProduct._id"
          :disableLocations="true"
        />
      </div>

      
     
    </div>
    <SelectedProductList
      :updateStep="updateStep"
      :checkin_products="data"
      :type="type"
      :deleteData="deleteData"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ProductList from "./product_list.vue";
import SelectedProductList from "./selected_product_list.vue";
import ProductItem from "../product_item.vue";
import trx from "@/translations";

export default {
  name: "AddCheckinProducts",
  data() {
    return {
      trx,
      selectedProduct: null,
      amount: null,
      per: null,
      batchnr: null,
      expiration_date: null,
    };
  },
  props: {
    updateStep: Function,
    updateData: Function,
    deleteData: Function,
    data: Array,
    type: String,
  },
  methods: {
    ...mapActions(["getAllProducts"]),
    updateSelectedProduct(product) {
      this.selectedProduct = product;
    },
    addProduct() {
      if (this.amount === null || this.per === null) {
        return;
      }

      this.updateData({
        product: this.selectedProduct,
        units: this.amount,
        packed_per: this.per,
        batchnr: this.batchnr,
        expiration_date: this.expiration_date,
      });
      this.selectedProduct = null;
      this.amount = null;
      this.per = null;
      this.batchnr = null;
      this.expiration_date = null;
    },
    back() {
      this.selectedProduct = null;
    },
  },
  created() {
    // Check if products array is empty, if so, get products
    if (this.all_products.length === 0) {
      this.getAllProducts();
    }
  },
  computed: {
    ...mapGetters(["all_products"]),
  },
  components: { ProductList, SelectedProductList, ProductItem },
};
</script>
<style lang="scss" scoped>
  .input_container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .amount_separator {
    display: flex;
    gap: 1rem;
  }

  .amount_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .full_width {
    width: 100%;
  }

  .total {
    input {
      border: 2px solid var(--color-primary);
      font-weight:800;
      font-size: 2rem;
      line-height: 0;
      padding: calc(1.05rem - 2px);
    }
  }

  .back_button {
    display: flex;
    gap: 0.3rem;
    cursor: pointer;
    width: fit-content;
    padding: 0.5rem;
    border-radius: 1rem;
    box-shadow: var(--shadow-light);
    transition: all;
    transition-duration: 0.3s;
    margin-bottom: 0.25rem;
    align-items: center;
  }

  .back_button:hover {
    box-shadow: none;
    color: white;
  }
</style>
