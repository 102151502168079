<template>
  <div class="signupform-container">
        <h1>{{trx.users['Signup to get started']}}</h1>
        <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
        <router-link :to="'login'" class="form-link">{{trx.users['Already have an account, login']}} </router-link>
  </div>
</template>

<script>
import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';

/* eslint-disable no-debugger */

Vue.use(VueFormGenerator);

Object.keys(VueFormGenerator.validators.resources).forEach(x => {
  VueFormGenerator.validators.resources[x] = trx.validation_trx[x];
}); 


export default {
  name: 'AuthContainer',
  methods: {
    ...mapActions(['fetchUser', 'signUp'])
  },
  computed: mapGetters(['user']),
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  created() {
   
  },
  data () {
    return {
      trx,
      model: {
        first_name: '',
        family_name: '',
        password: '',
        email: '',
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: trx.users['First name'],
            model: 'first_name',
            featured: true,
            required: true
          },{
            type: 'input',
            inputType: 'text',
            label: trx.users['Last name'],
            model: 'family_name',
            featured: true,
            required: true
          },
          {
            type: 'input',
            inputType: 'email',
            label: trx.users['Email'],
            autocomplete:'off',
            model: 'email',
            required: true
          },
          {
            type: 'input',
            inputType: 'tel',
            label: trx.users['Phone'],
            autocomplete:'off',
            model: 'phone',
            required: true
          },
          {
            type: 'input',
            inputType: 'password',
            label: trx.users['Password'],
            autocomplete:'new-password',
            model: 'password',
            min: 9,
            required: true,
            hint: trx.admin.users.password_hint,
            validator: VueFormGenerator.validators.string
          },
          {
            type: 'Submit',
            buttonText: trx.users['Signup'],
            onSubmit: this.signUp,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        // validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss">

</style>
