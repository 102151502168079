<template>
  <div class="postnl_buttons">
    <button :class="'ship_button ship_button__' + order.shipmentDetails?.countryCode" v-on:click="shipPostNL({order, code, ship_mtd_code, iframe})">
        {{name}}
        <span :class="'ship_button--flag ship_button--flag__' + order.shipmentDetails?.countryCode">
          <span></span>
          <span></span>
          <span></span>
        </span>
        
    </button>
  </div>  
</template>
<script>
import {  mapActions } from 'vuex';

export default {
  name: 'ShipOption',
  methods: {
      ...mapActions(['shipPostNL']),
  },
  components: {
  },
  props: {
    order: Object,
    name: String,
    code: String,
    ship_mtd_code: String,
    iframe: HTMLIFrameElement,
  },
  data () {
      return {
          
      }
  },
  mounted() {
    
  }
}
</script>


<style lang="scss" scoped>

  .ship_button {
    position: relative;
    min-height: 65px;
    min-width: 160px;
    max-width: 160px;
    border:2px solid var(--color-primary);
    background: var(--color-grey-light-3);
    color: var(--color-black);
    &--flag {
      position: absolute;
      top:-2px; 
      right: -3px;
      z-index:5;
      
      span {
        position: absolute;
        width: 25px;
        height: 15px;
        
        &:nth-child(1) {
          border-top-right-radius: 5px;
          top:0px;
        }
        &:nth-child(2) {
          top:15px;
        }
        &:nth-child(3) {
          top:30px;
          border-bottom-right-radius: 5px;
        }

        
      }
      &__NL span {
          &:nth-child(1) {
            background-color: red;
          }
          &:nth-child(2) {
            background-color: white;
          }
          &:nth-child(3) {
            background-color: blue;
          }
        }
      &__DE span {
          &:nth-child(1) {
            background-color: black;
          }
          &:nth-child(2) {
            background-color: red;
          }
          &:nth-child(3) {
            background-color: yellow;
          }
        }
      &__BE span {
        height: 35px;
        width: 8px;
        &:nth-child(1) {
          top: 0px;
          left:0px;
          background-color: black;
         
          border-top-right-radius: 0px;
          
        }
        &:nth-child(2) {
          top: 0;
          left:8px;
          background-color: yellow;
        }
        &:nth-child(3) {
          top: 0;
          left:16px;
          
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: red;

        }
      }
      
      &__FR span {
        height: 45px;
        width: 8px;
        &:nth-child(1) {
          top: 0px;
          left:0px;
          background-color: blue;
         
          border-top-right-radius: 0px;
          
        }
        &:nth-child(2) {
          top: 0;
          left:8px;
          background-color: white;
        }
        &:nth-child(3) {
          top: 0;
          left:16px;
          
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: red;

        }
      }
     
      
    }
    
  }

</style>