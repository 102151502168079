<template>
	<div class="container">
		<h1>{{ this.trx.orders.send }}</h1>
	</div>
</template>
<script>
import trx from "@/translations";

export default {
	name: "OrderSuccess",
	data() {
		return {
			trx,
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
</style>