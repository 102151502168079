<template>
  <div class="container">
    <ProductItem
      v-for="product in this.products"
      :key="product._id"
      v-bind:item="product"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import ProductItem from "./product_item.vue";

export default {
  name: "ProductList",
  props: {
    items: Array,
  },
  components: {
    ProductItem,
  },
  methods: {
    ...mapActions(["getProducts", "search_product_ean"]),
  },
  computed: {
    ...mapGetters(["products"]),
  },
  created() {
    this.getProducts();
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 30rem;
}
</style>
