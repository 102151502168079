<template>
    <div class="product-image">
        <img v-if="order_image" class="image" :src="image ? 'data:image/' + image.img_ext + ';base64,' + image.img_data : ''"
            :alt="node.title + '-'+node.ean" :title="node.title + '-'+node.ean"
        />
        <span class="product-count">{{ node.quantity }}</span>
    </div>
  </template>
  
  <script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import trx from '@/translations';
/* eslint-disable no-debugger */

  export default {
    name: "OrderImage",
    computed: {
        ...mapGetters(['order_image']),
    },
    methods: {
        ...mapActions(['get_order_image']),
    },
    async created() {
        await this.get_order_image( typeof this.node.product_id === 'string' ? this.node.product_id  : this.node.product_id._id);
        this.image = this.order_image
    },
    props: {
        node: { 
            type: Object, 
        }
    },
    data() {
      return {
        moment,
        trx,
        image: {}
      }
    },
   
  }

  </script>
  
  <style lang="scss" scoped>
    
    .product-image {
        text-align: center;
        overflow: visible;
        max-width: 50px;
        position: relative;
        .image {
            width: 50px !important;
            max-height: 50px !important;
            object-fit: contain !important;
        }
    }

    .product-count {
        background-color: #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        font-size: 2rem;
        right: -10px;
        background: var(--color-white);
        padding: 0rem 1rem;
        border: 1px solid var(--color-primary);
        z-index: 999;
    }

  </style>