<template>
  <div id="app">
    <h2 class="flex-60">Sales Dashboard (Beta)</h2>
    <!-- <div class="flex">
      <div class="flex-20">
        <p>Aantal producten</p>
        <select v-model="filters.products">
          <option value="">All</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </select>
      </div>
      <div class="flex-20">
        <p>Delivery Time</p>
        <select v-model="filters.deliveryTime">
          <option value="">All</option>
          <option value="1">1 day</option>
          <option value="2">2 days</option>
        </select>
      </div>
      <div class="flex-20">
        <p>Updated</p>
        <select v-model="filters.updated">
          <option value="">All</option>
          <option value="today">Today</option>
          <option value="week">This Week</option>
        </select>
      </div>
      <div class="flex-20">
        <p>Categorie</p>
        <select v-model="filters.categorie">
          <option value="">All</option>
          <option value="category1">Category 1</option>
          <option value="category2">Category 2</option>
        </select>
      </div>
    </div> -->

    <textarea v-model="query" v-on:change="fetch_sales(query);" class="code-output" placeholder="Code output"></textarea>
    <div class="table__conn">
    <table ref="sales_table" v-if="sales">
      <tr>
        <th class="large" @click="sort_f('name')">Name</th>
        <th @click="sort_f('address')">Address</th>
        <th class="center medium" @click="sort_f('products')">Products</th>
        <th class="e_large" @click="sort_f('delivery_time')">Delivery Time</th>
        <th class="medium">Updated</th>
        <th class="medium center" @click="sort_f('contacted')">Contacted?</th>
        <th class="center">Actions</th>
      </tr>
      <tr v-for="x in paginatedSales" :key="x._id">
        <td>{{ x.name }}</td>
        <td>{{ x.address }}</td>
        <td class="center" >{{ x.products }}</td>
        <td>{{ x.delivery_time }}</td>
        <td>{{ moment(x.updated).format('DD-MM-YYYY') }}</td>
        <td>
          <input type="checkbox" v-model="x.contacted" :checked="x.contacted" @change="update_sale({query: query, updated: x })">
        </td>
        <td class="center">
          <a class="action" :href="'https://bol.com' + x.contact_link" target="_blank">📥</a>
          <a class="action" :href="'https://bol.com' + x.profile_link" target="_blank">🎛️</a>
        </td>
        
      </tr>
    </table>
    </div>
    
    <br/>
    <br/>

    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Prev</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import moment from 'moment'

export default {
  name: "Sales",
  computed: {
    ...mapGetters(['sales']),
    paginatedSales() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sales.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.sales.length / this.itemsPerPage);
    }
  },
  data() {
    return {
      trx,
      query: '[ { $match: { products: { $lte: 75 } , updated: { $gte: new Date("'+moment().subtract(1, 'week').format('YYYY-MM-DD')+'"), $lte: new Date("'+moment().add(1, 'week').format('YYYY-MM-DD')+'") } } }, { $sort: { products: -1, updated: -1  }  }]',
      filters: {
        products: '',
        deliveryTime: '',
        updated: '',
        categorie: ''
      },
      moment,
      sort: { column: '', order: '' },
      currentPage: 1,
      itemsPerPage: 50
    };
  },
  methods: {
    ...mapActions(['fetch_sales', 'update_sale']),
    sort_f(column) {
      if (this.sort.column === column) {
        // If the same column is clicked, reverse the sort order
        this.sort.order = this.sort.order === 'asc' ? 'desc' : 'asc';
      } else {
        // If a different column is clicked, set the sort column and order
        this.sort.column = column;
        this.sort.order = 'asc';
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    updateContacted(sale) {
      // Perform your update logic here
      this.update_sale(sale);
    },
    editSale() {
      // Perform your edit logic here
    }
  },
  mounted() {
   
  },
  created() {
    this.fetch_sales(this.query);
    const table = this.$refs.sales_table;
    let isResizing = false;
    let startX;
    let startWidth;

    const handleMouseDown = (e) => {
      isResizing = true;
      startX = e.pageX;
      startWidth = parseInt(window.getComputedStyle(e.target).width, 10);
    };

    const handleMouseMove = (e) => {
      if (!isResizing) return;
      const width = startWidth + (e.pageX - startX);
      e.target.style.width = `${width}px`;
    };

    const handleMouseUp = () => {
      isResizing = false;
    };

    table.querySelectorAll('th').forEach((th) => {
      th.addEventListener('mousedown', handleMouseDown);
    });

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }
};
</script>

<style lang="scss" scoped>

.code-output {
  font-family: 'Courier New', monospace;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  color: #333;
  border-radius: 4px;
  resize: vertical; /* Allows vertical resizing of the textarea */
}


#app {
  padding: 2rem;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flex-20 {
  width: 20%;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.table__conn {
  max-height: 80vh;
  height: 65vh;
  overflow: scroll;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  table-layout: fixed; /* Set table layout to fixed */

  th,
  td {
    padding: 12px;
    text-align: left;
    font-size: 1.2rem;
    border-bottom: 1px solid #ddd;
    display: table-cell; /* Set display to table-cell */

    &.center {
      text-align: center;
    }

    .action {
      margin: 0 1rem;
    }
  }

  th {
    background-color: var(--color-primary-dark);
    color: var(--color-white);
    cursor: pointer;
    position: sticky; /* Add position sticky */
    top: 0; /* Set top position to 0 */
    z-index: 1; /* Add z-index to ensure it stays on top */
    &.small {
      width: 50px;
    }
    &.medium {
      width: 100px;
    }
    &.large {
      width: 200px;
    }
    &.extra_large {
      width: 300px;
    }
  }

  tr {
    &:nth-child(even) {
      background-color:var(--color-grey-light-2);
      
    }
    &:hover {
      background-color: var(--color-primary-light);
      transition: all 0.2s;
      color: var(--color-white);
    }
    
  }
}



a {
  text-decoration: none;
}

input[type="checkbox"] {
  margin: 0;
}

.code-container {
  position: relative;
  display: flex;
}

.code-output {
  flex-grow: 1;
  padding: 10px;
  background-color: #f8f8f8;
  color: #333;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
}

.line-numbers {
  padding: 10px;
  background-color: #f8f8f8;
  color: var(--color-black);
  font-family: 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
}

.line-number {
  padding-right: 10px;
}

.code-input {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  color: #333;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.5;
}

.pagination {
  @media screen and (max-width: $bp-medium) {
    width: 100%;
  }
  button {
    margin: 0 1rem;
  }
}

</style>