<template>
    <div class="autm">
        <h6>Conditie</h6>
        <div class="autm__cond">
            <div class="flex">
                <div class="flex-25">
                    Productveld
                    <select v-model="node.affected_field">
                        <option value="quantity">Aantal</option>
                        <option value="ean">EAN</option>
                        <option value="shipmentDetails.countryCode">Land van bestemming</option>
                    </select>
                </div>
                <div class="flex-10">
                    Voorwaarde
                    <select v-model="node.logical_operator">
                        <option value="===">Gelijk aan</option>
                        <option value="!=">Niet gelijk aan</option>
                        <option value=">">Groter dan</option>
                        <option value="<">Kleiner dan</option>
                    </select>
                </div>
                <div class="flex-25" >
                    Waarde
                    <input v-model="node.value" type="text"/>
                </div>
                <div class="flex-25" >
                    Actie
                        <select v-model="node.type">
                        <option>-</option>
                        <option value="change">Verander verpakking/verzendwijze</option>
                        <option value="add">Voeg een product toe of verander aantal</option>
                    </select>
                </div>
            </div>
            <div class="autm_actions" v-if="node.type">
                <button v-on:click="add_autm_action()">Actie toevoegen</button><h6>Actie(s)</h6>
                <AutomationAction v-for="x in node.actions" v-bind:key="x._id" :node="x" :product="product" :type="node.type" />
            </div>

            <button v-on:click="edit_automation(node)">Wijzigingen opslaan</button>
        </div>
        
    </div>    
</template>
        
<script>

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import vSelect from 'vue-select';
import AutomationAction from './autm_action.vue';
import trx from '@/translations';
/* eslint-disable no-debugger */

import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

export default {
  
    computed: {
        ...mapGetters(['products', 'shipment_methods_list', 'shipment_boxes_list']),
    },
    methods: {
        ...mapActions([ 'edit_automation']),
        add_autm_action: function() {
            this.node.actions.push({
                field: null, 
                value: null,
                qty: null
            });
        }
    },
    components: {
        AutomationAction
    },
    mounted() {

    },
    props: {
        product: Object,
        i: Number,
        link_expanded: Boolean,
        aut_expanded: Boolean,
        component_name: String,
        node: Object

    },
    data() {
        return {
            trx,
            autm_type: null,
        }
    },
    name: "autm_rule",
}
</script>

<style lang="scss" scoped>
   .product__aut {
       @include box-bg;
       padding: 2rem;

       .autm {
        margin-top: 2rem;
        padding: 2rem;
        @include box-bg-white;
         
        &__actions {
            @include box-bg-dash;
            padding: 2rem;
            margin-left: 5rem;
            max-width: 50rem;
        }
        [class^='flex-'] {
            margin-right: 1rem;
        }

       }
   }
</style>
