<template>
<div>
    <div class="flex" >
        <button v-if="!scan_location" v-on:click="addToLocation(product)">+</button>
        <input class="locationInput"     
          type="input"
          :style="{ display: scan_location ? 'inline' : 'none' }"
          ref="addToLocation"
          placeholder="Please scan barcode"
          v-model="inputValue"
        />
        <span class="closeInputField" v-if="scan_location" @click="closeInput">x</span>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import ean_checked from '@/assets/audio/ean_checked.mp3'

/* eslint-disable no-debugger */

export default {
    name: "AddLocationToInbItem",
    props: {
        product: Object,
        pieces: Number,
        locationId: String,
        inboundItemId: String,
        transferLocation: Boolean,
    },
    data() {
        return {
            inputValue: '',
            scan_location: false,
        }
    },
    methods: {
        ...mapActions(['link_barcode', 'deleteReservedLocation']),
        addToLocation (elm) {
            this.scan_location = true
            const self = this;
            var audio = new Audio(ean_checked)
            audio.play();
            const setLocation = (e) => {

                if (this.inputValue == this.locationId)  {
                    if(e.keyCode === 13) {
                        this.link_barcode({product_id: elm._id, location_id: e.currentTarget.value, amount: this.pieces, search: self.product.ean, type: "inbound", inbound_item_id: this.inboundItemId  })
                        this.deleteReservedLocation({inbItemId: this.inboundItemId, locationId: this.locationId})
                        this.$refs.addToLocation.style.display = 'none'
                        this.$refs.addToLocation.removeEventListener("keydown", setLocation, true );
                        this.$refs.addToLocation.value = null;
                        
                    } else if (e.key === "Escape") {
                        this.$refs.addToLocation.style.display = 'none'
                    }
                } else {
                    console.log("id is not same as location id")
                }
            }
            debugger
            this.$refs.addToLocation.addEventListener("keydown", setLocation, true);
            debugger
            this.$refs.addToLocation.style.display = "inline";
            this.$nextTick(() => {
                this.$refs.addToLocation.focus();
            });
        },
        closeInput() {
            this.scan_location = !this.scan_location
            this.$refs.addToLocation.style.display = 'none' 
        }
    },
}
</script>

<style lang="scss" scoped>
    .closeInputField {
        font-size: 2rem;
            &:hover {
                cursor: pointer;
            }
    }

    .locationInput {
        width: 15rem;
    }
</style>