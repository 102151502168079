<template>
  <div class="login">
    <img class="signupbg" src="@/assets/login.png" />
    <AuthContainer type="login"/>
  </div>
</template>

<script>
// @ is an alias to /src
import AuthContainer from '@/components/Layout/AuthContainer.vue';

export default {
  name: 'Login',
  components: {
    AuthContainer
  }
}
</script>

<style lang="scss" scoped>
   
</style>