<template>
  <div>
    <div>
      <h1>Zoeken naar order id</h1>
    </div>

    <div class="search">
      <Search
        class="flex-20"
        s_model="orderId"
        :search__action="search__orderid"
        placeholder="Order ID"
      />
    </div>

    <div class="container" v-if="admin_orders.org_name">
      <div class="order">
        <h3>Ordergegevens</h3>
        <p><strong>Status order: </strong> {{ admin_orders.active ? "Actief" : "Niet actief" }}</p>
        <p><strong>Platform:</strong> {{ admin_orders.platform }}</p>
        <div v-if="admin_orders.notes_notify || admin_orders.notes_color == 'red' || (admin_orders.notes_text !== null || undefined)">
          <p><strong>Notitie: </strong>{{ admin_orders.notes_text ? "'" + admin_orders.notes_text + "'" : ""}} {{ admin_orders.notes_color ? "Kleur: rood" : ""}}</p>
        </div>
        <p>
          <strong>Geplaatst op:</strong>
          {{ admin_orders.orderPlacedDateTime ? moment(admin_orders.orderPlacedDateTime).format("DD-MM-YYYY") : "" }}
        </p>
        <p v-if="admin_orders.track_trace !== (null || undefined)">
          <strong>track & trace: </strong>
          <a target="blank" :href="'https://jouw.postnl.nl/track-and-trace/'+admin_orders.track_trace+'-'+admin_orders.shipmentDetails.countryCode+'-'+ (admin_orders.shipmentDetails.zipCode)">{{admin_orders.track_trace}}</a>
        </p>
        <p>
          <strong>Klant:</strong> {{ admin_orders.shipmentDetails.firstName }}
          {{ admin_orders.shipmentDetails.surname }}
        </p>
        <p>
          <strong>Email klant:</strong> <a :href="`mailto:` + admin_orders.shipmentDetails.email">{{ admin_orders.shipmentDetails.email }}</a>
        </p>
        <p>
          <strong>Adres:</strong> {{ admin_orders.shipmentDetails.streetName }}
          {{ admin_orders.shipmentDetails.houseNumber }}, {{ admin_orders.shipmentDetails.city }}
        </p>
        <p><strong>Postcode:</strong> {{ admin_orders.shipmentDetails.zipCode }}</p>
        <p><strong>Land:</strong> {{ admin_orders.shipmentDetails.countryCode }}</p>
        <p v-if="admin_ordersMetadataExists"> <strong>Verwachte levering:</strong>
          {{ admin_orders.meta_data.orderItems[0].fulfilment.latestDeliveryDate }}
        </p>
      </div>

      <div v-if="admin_orders.products.length == 1" class="product">
        <h3>Productgegevens</h3>
        <p><strong>Productnaam:</strong> {{ admin_orders.products[0].title }}</p>
        <p><strong>Aantal:</strong> {{ admin_orders.products[0].quantity }}</p>
        <p><strong>Ean:</strong> {{ admin_orders.products[0].ean }}</p>
        <p><strong>Organisatie:</strong> {{ admin_orders.org_name }}</p>

        <div v-if="admin_orders.products[0].product_id.locations.length > 0"> 
          <div v-for="(location, index) in admin_orders.products[0].product_id.locations" v-bind:key="index">
              <p v-if="location.location_id.parent.name "> <strong>Locatie: </strong>
                {{ location.location_id.parent.name }}-{{location.location_id.name}} </p>
            </div>
        </div>
        <div v-else>
          <p><strong>Locatie: </strong>Geen locatie </p>
        </div>

      </div>

      <div v-else class="product">
        <h3>Productgegevens (multi-order)</h3>
        <p><strong>Organisatie:</strong> {{ admin_orders.org_name }}</p>
        <div
          v-for="(products, index) in admin_orders.products"
          v-bind:key="products._id"
          class="multi" >
          <p>
            <strong>Product {{ index + 1 }}</strong>
          </p>
          <p><strong>Productnaam:</strong> {{ products.title }}</p>
          <p><strong>Aantal:</strong> {{ products.quantity }}</p>
          <p><strong>Ean:</strong> {{ products.ean }}</p>

          <div v-if="products.product_id.locations.length > 0">
            <div v-for="(location, index) in products.product_id.locations" v-bind:key="index">
              <p v-if="location.location_id.parent.name "> <strong>Locatie: </strong>
                {{ location.location_id.parent.name }}-{{location.location_id.name}} </p>
            </div>
          </div>
          <div v-else>
            <p><strong>Locatie: </strong>Geen locatie</p>
          </div>
        
        </div>
      </div>

      <div class="delete">
      <button @click="showPopup" class="deleteButton">Verwijder deze order</button>

      <div v-if="isPopupVisible" class="popup">
        <p>let op: je staat op het punt een order uit de database te verwijderen. Dit kan niet teruggezet worden. 
          Weet je zeker dat je deze order wilt verwijderen? 
        </p>
        <button @click="closePopup">nee, breng mij terug</button>
        <button @click="deleteOrder({ orderId: admin_orders.orderId })">ja, verwijder deze order</button>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Search from "@/components/Search";
import moment from "moment";


export default {
  name: "searchOrder",
  data() {
    return {
      search: null,
      isPopupVisible: false,
      moment,
    };
  },
  computed: {
    ...mapGetters(["admin_orders"]),
    admin_ordersMetadataExists() {
      return ( 
        this.admin_orders.meta_data &&
        this.admin_orders.meta_data.orderItems &&
        this.admin_orders.meta_data.orderItems[0] &&
        this.admin_orders.meta_data.orderItems[0].fulfilment &&
        'latestDeliveryDate' in this.admin_orders.meta_data.orderItems[0].fulfilment
      )
    },
  },
  methods: {
    ...mapActions(["search__orderid", "deleteOrder"]),
    showPopup() {
      this.isPopupVisible = true;
    },
   
    closePopup() {
      this.isPopupVisible = false;
    }
  },
  components: {
    Search,
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 6rem;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0.5rem;
  }
}

.multi {
    margin-bottom: 2rem;
      p {
        margin: 0;
      }
}

.popup {

  position: fixed;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;


  button {
    margin: 1rem;
  }

  button:nth-child(3) {
    background-color: white;
    color: red;
    border: solid 1px red;
  }
}

.deleteButton {
  background-color: red;
    &:hover {
      background-color: red;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

</style>