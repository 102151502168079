<template>
    <div class="users-wrp">

        <h3>{{ trx.returns.name }}</h3>

        <div class="totals">
            <infoBox :name="trx.shipments['Open']" :big_number="returns.length" />
        </div>

        <div v-if="returns !== (null || undefined) || returns.length < 0" class="returns">
            {{ trx.returns.return_number_or_barcode }}
            <input type="text" v-model="search_return_id" v-on:keyup.enter="get_returns(search_return_id)"
                :placeholder="trx.returns.scan_help_text">
            <div v-for="l in returns" v-bind:key="l._id" :class="'returns__return ' +  (l.closed_datetime ? 'returns__return--closed' : '') ">

                <span class="returns__return__platform">{{ l.platform }}</span>

                <div class="returns__return__item flex" v-for="(ri, i) in l.returnItems" v-bind:key="ri._id">
                    <div class="flex-20">
                        <h4>{{ trx.returns.location }}</h4>
                        
                        <h1 v-if="ri.product_id && ri.product_id.locations[0]">
                            {{ ri.product_id.locations[0].location_id.parent.name }}-{{ri.product_id.locations[0].location_id.name }}
                        </h1>
                        <h1 v-else>
                            <div class="" v-if="l.Products__join[i].parent && l.Products__join[i].parent.locations[0]">
                                {{ l.Products__join[i].parent.locations[0].location_id.parent.name }}-{{
                                l.Products__join[i].parent.locations[0].location_id.name }}
                            </div>
                            
                        </h1>


                        <button v-on:click="cr_checkin_code(
                            {
                                ean: l.Products__parent__join[i] ? l.Products__parent__join[i].ean : ri.ean,
                                amount: ri.expectedQuantity, type: 'return',
                                location: ri.product_id && ri.product_id.locations[0] ? (ri.product_id.locations[0].location_id.parent.name + '-' + ri.product_id.locations[0].location_id.name) :
                                    (l.Products__join[i].parent.locations[0].location_id.parent.name + '-' + l.Products__join[i].parent.locations[0].location_id.name)

                            })">🖨️</button>
                        <h4>{{ trx.returns.product }}</h4>
                        {{ ri.title }}
                        <br>
                        <br>
                        <p>{{ l.Products__parent__join[i] ? l.Products__parent__join[i].ean : ri.ean }}</p>
                        {{ ri.trackAndTrace }}
                        {{ ri.transporterName }}

                    </div>
                    <div class="flex-30">
                        <h4>{{ trx.returns.return_instruction }}</h4>
                        <div class="background__red" v-if="ri.product_id">
                            <p>{{ ri.product_id.return_instruction }}</p>
                        </div>

                        <h4>{{ trx.returns.reason }}</h4>
                        <h5 v-if="ri.returnReason.mainReason">{{ ri.returnReason.mainReason }}</h5>
                        <p v-if="ri.returnReason.detailedReason">{{ ri.returnReason.detailedReason }}</p><br>
                        <p>{{ ri.returnReason.customerComments }}</p>
                    </div>
                    <div class="flex-20">
                        <h4>{{ trx.returns.product_state }}</h4>
                        <textarea v-on:change="update_return({ return_item: ri, return_id: l.returnId })"
                            v-model="ri.product_state"></textarea>
                        <button
                            v-on:click="(ri.product_state = trx.returns.product_excellent) && update_return({ return_item: ri, return_id: l.returnId })"
                            class="badge green">{{ trx.returns.product_excellent }}</button>
                        <button
                            v-on:click="(ri.product_state = trx.returns.product_good) && update_return({ return_item: ri, return_id: l.returnId })"
                            class="badge yellow">{{ trx.returns.product_good }}</button>
                        <button
                            v-on:click="(ri.product_state = trx.returns.product_bad_state) && update_return({ return_item: ri, return_id: l.returnId })"
                            class="badge red">{{ trx.returns.product_bad_state }}</button>
                    </div>
                    <div class="flex-40 returns__return__item__check ">
                        <h4>{{ trx.returns.damage }}</h4>
                        <div class="flex">
                            <input v-on:change="update_return({ return_item: ri, return_id: l.returnId })" class="flex-10"
                                type="checkbox" id="SMF-BRIEF-NL" v-model="ri.damaged_package" name="product_state"
                                value="damaged_package">
                            <label class="flex-90" for="SMF-BRIEF-NL"> {{ trx.returns.damaged_package }} </label>
                        </div>
                        <div class="flex">
                            <input v-on:change="update_return({ return_item: ri, return_id: l.returnId })" class="flex-10"
                                type="checkbox" id="damaged_product" v-model="ri.damaged_product" name="product_state"
                                value="damaged_product">
                            <label class="flex-90" for="damaged_product">{{ trx.returns.damaged_product }} </label>
                        </div>
                        <div class="flex">
                            <input v-on:change="update_return({ return_item: ri, return_id: l.returnId })" class="flex-10"
                                type="checkbox" id="damaged_product_package" v-model="ri.damaged_product_package"
                                name="product_state" value="damaged_product_package">
                            <label class="flex-90" for="damaged_product_package">{{ trx.returns.damaged_product_package
                            }}</label>
                        </div>
                        <br>
                        <span class="hidden">{{ ri.return_action == null ? ri.return_action = "RETURN_RECEIVED" : ""
                        }}</span>
                        <select v-model="ri.return_action"
                            v-on:change="update_return({ return_item: ri, return_id: l.returnId })">
                            <option selected="true" value="RETURN_RECEIVED">{{ trx.returns.RETURN_RECEIVED }}</option>
                            <option value="EXCHANGE_PRODUCT">{{ trx.returns.EXCHANGE_PRODUCT }}</option>
                            <option value="RETURN_DOES_NOT_MEET_CONDITIONS">{{ trx.returns.RETURN_DOES_NOT_MEET_CONDITIONS
                            }}
                            </option>
                            <option value="REPAIR_PRODUCT">{{ trx.returns.REPAIR_PRODUCT }}</option>
                            <option value="CUSTOMER_KEEPS_PRODUCT_PAID">{{ trx.returns.CUSTOMER_KEEPS_PRODUCT_PAID }}
                            </option>
                            <option value="STILL_APPROVED">{{ trx.returns.STILL_APPROVED }}</option>
                        </select>
                        <br>
                        <br>
                        <button v-on:click="fulfil_return(ri)">{{ trx.returns.handel_return_close }}</button>
                    </div>
                </div>
                <div class="flex returns__return__details">
                    <div class="flex-20">
                        <h4> {{ l.org_name }} </h4>
                    </div>
                    <div class="flex-20">
                        <h4>{{ trx.returns.return_id }}</h4>
                        <p class="returns__return__id">{{ l.returnId }}</p>
                    </div>
                    <div class="flex-20">
                        <h4>{{ trx.returns.registered }}</h4>
                        <p class="returns__return__datetime">{{ moment(l.registrationDateTime).locale("NL").fromNow() }}</p>
                    </div>
                    <div class="flex-20">

                    </div>
                    <div class="flex-20">

                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            {{ trx.returns.no_returns_to_show }}
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import infoBox from '@/components/Layout/InfoBox';
import duplicateWarning from '@/assets/duplicate_warning.svg';
import trx from '@/translations';
import moment from 'moment';
// import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


/* eslint-disable no-debugger */

export default {
    name: 'Returns',
    computed: {
        ...mapGetters(['returns'])
    },
    methods: {
        ...mapActions(['get_returns', 'update_return', 'cr_checkin_code', 'fulfil_return']),
        listen_to_ean_input() {
            let code = "";
            const self = this;

            window.addEventListener('keypress', e => {

                //usually scanners throw an 'Enter' key at the end of read
                if (e.keyCode === 13) {
                    if (code.length > 7) {
                        if (code.length > 0)
                            self.get_returns(code)
                        // else  
                        //     self.get_returns(self.search_return_id)   

                        /// code ready to use                
                        code = "";
                    }
                } else {
                    code += e.key; //while this is not an 'enter' it stores the every key            
                }
            });
        },
    },
    components: {
        infoBox,
        // DateRangePicker,
    },
    mounted: function () {
        this.get_returns(this.search_return_id);
    },
    created() {
        this.listen_to_ean_input()
    },
    data() {
        let startDate = new Date();
        let endDate = new Date();
        let p_size = 1;

        return {
            trx,
            moment: moment,
            duplicateWarning,
            search_return_id: null,
            dateRange: { startDate, endDate },
            p_size: p_size,
        }
    },
    filters: {
        date(val) {
            return val ? val.toLocaleString() : ''
        }
    }
}

</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 5rem;
}

.button {
    @include button;
    margin-right: 2rem;
}

.pakbon_button {
    @include button;
    height: 8rem;
    margin-right: 2rem;
}

.background__red {
    @include box-bg-white;
    background: var(--color-red);

    p {
        color: var(--color-white) !important;
    }
}

.returns {
    &__return {

        @include box-bg-white;
        margin: 1rem 0rem;
        align-content: flex-start;
        padding: 2rem;
        position: relative;
        &--closed {
            background: var(--color-grey-dark-1);
        }

        &__platform {
            right: 0;
            top: 0;
            background: var(--color-grey-dark-3);
            color: var(--color-grey-dark-1);
            padding: 0.5rem 2rem;
            border-bottom-left-radius: 2rem;
            width: 15rem;
            text-align: center;
            position: absolute;
        }

        &__details {
            h4 {
                margin-bottom: 2px;
            }

            padding: 2rem;
            justify-content: center;
        }

        &__company {
            margin-bottom: 1rem;
        }

        &__item {
            @include box-bg-white;
            align-content: flex-start;
            align-items: flex-start;

            input[type="checkbox"] {
                background: none;
                box-shadow: none;
            }

            &__check {
                max-width: 40rem;
            }

            >div {
                padding: 1rem 2rem;
            }
        }
    }
}
</style>
