import api from '@/api';

/* eslint-disable no-debugger */

const state = {
    returns: [],
    returns_date_range: null,
    search_return_id: null
};

const getters = {
    returns: state => state.returns,
    returns_date_range: state => state.returns_date_range,
    search_return_id: state => state.search_return_id
};

const actions = {
    async get_returns({commit}, model) {
        const response = await api.get("/returns/get/"+model);
        response !== undefined ? commit('set_returns', response.data) : '';
    },
    async update_return({dispatch}, model) {
        await api.post('/returns', model.return_item);
        await dispatch('get_returns', model.return_id);
    },
    async fulfil_return({dispatch}, model) {
        await api.post('/returns/fulfil', model);
        await dispatch('get_returns');
    }
};

const mutations = {
    set_returns: (state, returns) => (state.returns = returns),
};

export default {
  state,
  getters,
  actions,
  mutations
}
