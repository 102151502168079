var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newOrder-section"},[_c('h1',{staticClass:"flex-100"},[_vm._v("Check-in (beta)")]),_c('br'),_c('div',{},[(_vm.shop_products)?_c('v-select',{ref:"product_ean",staticClass:"flex-80",attrs:{"open":"true","reduce":product => product.ean,"options":_vm.shop_products,"label":"ean"},on:{"search":(search, loading) => { 
      _vm.search_product_ean({search: search, loading: loading})
  },"input":function($event){return _vm.add_temp_checkin_record({ean: _vm.selected_product })}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [(option.locations.length > 0)?_c('span',[_vm._v(_vm._s(option.locations[0].location_id.parent.name)+" - "+_vm._s(option.locations[0].location_id.name)+" - "+_vm._s(option.name))]):_vm._e(),_vm._v(" "),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(option.ean)+" ")])]}}],null,false,3915463879),model:{value:(_vm.selected_product),callback:function ($$v) {_vm.selected_product=$$v},expression:"selected_product"}}):_vm._e()],1),_c('div',{staticClass:"chinrws"},_vm._l((_vm.temp_checkin_rows),function(x,i){return _c('div',{key:x.ean,staticClass:"chinrws__row flex"},[_c('div',{staticClass:"ean flex-20"},[_c('p',[_vm._v(_vm._s(x.ean)+"/"+_vm._s(x.product_item.api_name))]),_c('h6',[_vm._v(_vm._s(x.product_item.name))])]),_c('div',{staticClass:"check_in_amount flex-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(x.amount_checkin),expression:"x.amount_checkin"}],attrs:{"type":"number"},domProps:{"value":(x.amount_checkin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(x, "amount_checkin", $event.target.value)}}})]),_c('div',{staticClass:"flex-25"},[_c('SelectShipMethod',{attrs:{"product":x.product_item}}),_c('br'),_c('SelectShipBox',{attrs:{"product":x.product_item}})],1),_c('div',{staticClass:"flex-35"},[_c('ProductLocation',{attrs:{"node":x.product_item,"i":i}})],1)])}),0),_c('InventoryForm',{attrs:{"model":{
      contact_person: this.current_org.contact_person,
      shipping_address: this.current_org.address.line1, 
      contact_person_email: this.current_org.contact_person_email,
      tack_trace: '',
      sended: _vm.moment(new Date()).toISOString(),
      expected_delivery: _vm.moment(new Date()).toISOString(),
      phone: this.current_org.phone,
      products: this.temp_checkin_rows
    },"submit":_vm.direct_check_in,"submitNmae":_vm.trx.create,"AddProducts":_vm.temp_checkin_rows}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }