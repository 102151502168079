import api from '@/api';
import router from '@/router';

/* eslint-disable no-debugger */

const state = {
    locations: null,
    product_locations: [],
    child_locations: null,
    location_groups: null,
    location_to_delete: [],
    most_sold_product: null,
};

const getters = {
    locations: state => state.locations,
    product_locations: state => state.product_locations,
    child_locations: state => state.child_locations,
    location_groups: state => state.location_groups,
    location_to_delete: state => state.location_to_delete,
    most_sold_product: state => state.most_sold_product,
};

const actions = {
    async fetchLocations({commit}) {
        const response = await api.get("/locations/null");
        response !== undefined ? commit('setLocations', response.data) : '';  
    },
    async fetch_child_Locations({commit}, model) {
        const response = await api.get("/locations/"+model);
        response !== undefined ? commit('set_child_locations', response.data) : '';  
    },
    async setProductLocation({dispatch, commit}, model ) {
        const res = await api.post('/product/assign_location', {
            product_id: model.product_id,
            rack_number: model.rack_number
        });

        if (res.status === 201)  {
            if (router.currentRoute.name.includes('InboundItem')) {
                await dispatch('fetchInboundItem', {id: router.currentRoute.params.id});
            } else {
                await dispatch('getProducts');
            }
        } else if (res.status === 405) {
            res !== undefined ?  commit('set_location_to_delete', res.data) : '';
        } else {
            console.log('Something went wrong')
        }
    },
    async link_barcode({dispatch, commit}, model ) {
        const res = await api.post('/locations/assign', {
            product_id: model.product_id,
            location_id: model.location_id,
            amount: model.amount,
            type: model.type,
            inbound_item_id: model.inbound_item_id,
        });

        if (res.status === 201)  {
            if (router.currentRoute.fullPath.includes('products'))
                await dispatch('getProducts');
        
            if (router.currentRoute.fullPath.includes('scanner'))
                await dispatch('search_product_ean', model.search);

            if (router.currentRoute.name.includes('InboundItem')) 
                await dispatch('fetchInboundItem', {id: router.currentRoute.params.id});

        } else if (res.status === 202) {
            res !== undefined ?  commit('set_location_to_delete', {
                prod_ids: res.data.product_ids,
                req_id: model.product_id
            }) : '';
        } else {
            console.log('Something went wrong')
        }

    },
    async update_location_stock({dispatch}, model) {
        
        await api.get(`/locations/stock/update/${model.product_id}/${model.location_product_id}/${model.amount}`);

        if (model.search)
            await dispatch('search_product_ean', model);
        
        if (router.currentRoute.fullPath.includes('scanner')) {
            await dispatch('search_product_ean', model.search);
        }    

        if (router.currentRoute.name.includes('InboundItem')) {
            await dispatch('fetchInboundItem', {id: router.currentRoute.params.id});
        }

    },
    async deassign_location({dispatch, commit}, model) {
        await api.delete(`/locations/clear/${model.location_product_id}/${model.product_id}/${model.location_id}`);
        
        commit('set_location_to_delete', {
            prod_ids: [],
            req_id: model.product_id
        }); 

        if (model.search)
            await dispatch('search_product_ean', model);

        if (router.currentRoute.fullPath.includes('products')) {
            await dispatch('getProducts', model.search )
        }

        if (router.currentRoute.fullPath.includes('admin/locations')) {
            await dispatch('fetch_child_Locations', model.parent_id);
        }

        if (router.currentRoute.fullPath.includes('scanner')) {
            await dispatch('search_product_ean', model.product_ean);
        }

        if (router.currentRoute.name.includes('InboundItem')) {
            await dispatch('fetchInboundItem', {id: router.currentRoute.params.id});
        }
        
    },
    async transfer_location({dispatch}, model) {
        await api.post(`/product/transfer/${model.loc_prod_id}`);

        if (router.currentRoute.name.includes('InboundItem')) {
            await dispatch('fetchInboundItem', {id: router.currentRoute.params.id});
        } else {
            await dispatch('getProducts');
        }
    },
    async add_location({dispatch}, model) {
        await api.get(`/locations/add/${model.name}/${model.id}`);
        await dispatch('getProducts');
        await dispatch('fetchLocations');
    },
    async getProductLocations({commit}, model) {
        const response = await api.get("/product/locations/"+model.product_id);
        response !== undefined ? commit('setProductLocations', response.data) : ''; 
    },
    async switch_loc_position({dispatch}, model) {
        await api.put('/product/switch_loc_position', model);
        await dispatch('getProducts');
    },
    async print_location_labels({dispatch}, model ) {
        try {

            const response = await api.post("/location/labels/"+model, model,  { responseType: 'blob'}).catch(err => {
                console.log(err);
            }); 
            
            const file = new Blob([response.data], {type: 'application/pdf'})
            const fileURL = window.URL.createObjectURL(file);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            
            const pdfWindowShip = window.open(fileURL);
            pdfWindowShip.print();

            window.open(fileURL);

            dispatch('dummyLog')
            
            // console.log(response, commit);
        } catch (err) {
            // console.log(err);
        }
    },
    async fetch_location_groups({commit}) {
        const response = await api.get("/location/groups");
        response !== undefined ? commit('set_location_groups', response.data) : '';  
    },
    async get_most_sold_product({commit}) {
        const res = await api.get('/most_sold_product');

        res ? commit('set_most_sold_product', res.data) : '';

    },
    reset_location_to_delete({commit}) {
        commit('set_location_to_delete', []);
    }
    // async clear_location({dispatch}, model) {
    //     // await api.put('/product/switch_loc_position', model);
    //     // await dispatch('getProducts');
    //}
};

const mutations = {
    setLocations: (state, locations) => (state.locations = locations),
    setProductLocations: (state, product_locations) => (state.product_locations = product_locations),
    set_child_locations: (state, child_locations) => (state.child_locations = child_locations),
    set_location_groups: (state, location_groups) => (state.location_groups = location_groups),
    set_location_to_delete: (state, location_to_delete) => (state.location_to_delete = location_to_delete),
    set_most_sold_product: (state, most_sold_product) => (state.most_sold_product = most_sold_product),
};

export default {
  state,
  getters,
  actions,
  mutations
}
