<template>
  <div>
    <div class="header">
      <h1>{{ this.trx.products.Products }}</h1>
    </div>
    <div class="container">
      <h2 class="subheader">{{ this.trx.checkinv.add_checkin.reason }}</h2>
      <br>
      <div class="buttons">
        <router-link to="/add_inventory" class="checkin_button">
          {{this.trx.checkinv.add_checkin.stock_checkin}}
          <p>{{ this.trx.checkinv.add_checkin.stock_checkin_info }}</p> 
        </router-link>
        <router-link to="/checkin_inventory" class="checkin_button">
          {{ this.trx.checkinv.add_checkin.change_checkin}}
          <p>{{ this.trx.checkinv.add_checkin.change_checkin_info }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import trx from "@/translations";

export default {
  name: "PreNotify",
  data() {
    return {
      trx,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-top: 1rem;
  margin-bottom: 3.5rem;
}

.container {
  margin-bottom: 3rem;
  margin: 0 auto;
}

.navbar {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 6rem;
  list-style: none;
}

.active-link {
  background-color: var(--color-primary) !important;
}
.link {
  background-color: var(--color-primary);
  border-radius: 100px;
  color: var(--color-white);
  padding: 1rem 2rem 1rem 2rem;
  font-size: 2rem;
}

.subheader {
  margin-bottom: 3rem;
}

.buttons {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.checkin_button {
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  border: 2px solid var(--color-primary);
  padding: 2rem;
  border-radius: 1rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--color-black);
  transition: all;
  transition-duration: 300ms;
}

.checkin_button:hover {
  background-color: var(--color-primary);
  color: white;
}
</style>
