<template>
    <div class="pick" ref="conatiner">
      
      <div class="mobile-menu-hamburger" @click="handleMobileMenu">
        <span class="mobile-menu-hamburger-line"></span>
        <span class="mobile-menu-hamburger-line"></span>
        <span class="mobile-menu-hamburger-line"></span>
      </div>

      <div :class="mobile_menu_expanded ? '' : 'mobile-menu-hidden'">
        <!-- Pick list header -->
        <div class="flex justify-between hidden-md pick__header">
          <div class="flex-20 flex-20-md">
            <h3>{{ trx.pick_list["name"] }}</h3>
          </div>
          <div class="flex-50 flex-50-md date_time">
            <h6>Datum & Tijd</h6>
            <date-range-picker
              ref="picker"
              class="datePicker"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :showWeekNumbers="true"
              :showDropdowns="true"
              :timePicker="true"
              :autoApply="true"
              opens="right"
              @select="getOrders"
              @update="getOrders"
              v-model="dateRange"
            >
              <template v-slot:input="picker">
                {{ moment(picker.startDate).format("DD-MM-YYYY") | date }} -
                {{ moment(picker.endDate).format("DD-MM-YYYY") | date }}
              </template>
            </date-range-picker>
          </div>
        </div>
        <!-- end header -->
  
        <!-- Pick list filters -->
        <div class="pick__filters">
            <div class="flex pick__filters--select">
              <div class="flex-50 flex-100-sm v_select">
              <h6>Locatie</h6>
              <v-select
                class="v_select"
                :close-on-select="false"
                v-if="locations"
                v-model="sl_locations"
                @input="ini_fetch_pick_list()"
                taggable
                multiple
                push-tags
                :options="
                  locations.map((x) => {
                    return { name: x.name };
                  })
                "
                :reduce="(location) => location.name"
                label="name"
              />
            </div>
              <div class="flex-50 flex-100-sm v_select">
              <h6>Locatie-Groep</h6>
              <v-select
                class="v_select"
                :close-on-select="false"
                v-if="location_groups"
                v-model="sl_group"
                @input="ini_fetch_pick_list()"
                taggable
                multiple
                push-tags
                :options="
                  location_groups.map((x) => {
                    return { name: x.name, locations: x.locations };
                  })
                "
                :reduce="(group) => group.locations"
                label="name"
              />
              </div>
              <div class="flex-50 flex-100-sm v_select">
              <h6>Cutoff-Groep</h6>
              <v-select
                class="v_select"
                :close-on-select="false"
                v-if="cutoff_groups"
                v-model="sm_group"
                @input="ini_fetch_pick_list()"
                taggable
                multiple
                push-tags
                :options="
                  cutoff_groups.map((x) => {
                    return { cut_off: x.cut_off, methods: x.methods };
                  })
                "
                :reduce="(group) => group.methods"
                label="cut_off"
              />
              </div>
              <div class="flex-50 flex-100-sm v_select">
              <h6>Verzendwijze</h6>
              <v-select
                v-if="shipment_methods_list"
                class="v_select"
              :close-on-select="false"
                v-model="sl_delivery"
                @input="ini_fetch_pick_list()"
                taggable
                multiple
                push-tags
                :options="
                  shipment_methods_list.map((x) => {
                    return { _id: x._id, name: x.name };
                  })
                "
                :reduce="(option) => option._id"
                label="name"
              />
              </div>
            </div>
            <div class="flex pick__filters--options">
              <div class="flex-100-sm order_switch">
                <SwitchInput
                  :change="change_sequence"
                  :value="-1"
                  :name="'Order sequence'"
                  :checked="sequence !== 1 ? true : false"
                />
                <p>Teruglopen</p>
              </div>
              <div class="flex-100-sm order_switch">
                <SwitchInput
                  :change="change_single_orders"
                  :value="single_orders ? false : true"
                  :name="'Multi orders'"
                  :checked="single_orders"
                />
                <p>Single-orders</p>
              </div>
              <div class="flex-100-sm order_switch">
                
                <SwitchInput
                  :change="change_multi_orders"
                  :value="multi_orders ? false : true"
                  :name="'Multi orders'"
                  :checked="multi_orders"
                />
                <p>Multi-orders</p>
              </div>
            </div>
        </div>
      </div>

      <!-- Control button for curr order -->
      <div class="pick__skip_buttons">
        <button class="pick__skip_buttons--back" v-on:click=" skip = skip - 1; ini_fetch_pick_list();"> Back </button>
        <button class="pick__skip_buttons--ref" v-on:click="skip = 0; ini_fetch_pick_list();">⟳</button>
        <button class="pick__skip_buttons--skip" v-on:click="skip = skip + 1; ini_fetch_pick_list();"> 
          Skip 
          <span class="" v-if="pick_list.length > 0 && this.skip > 0">{{ this.skip }}</span>
        </button>
      </div>
      
      <div v-if="( pick_list[0] && pick_list[0].needs_recount == true)">
          <p>Incorrect stock this product needs to be recounted</p>
          
          EAN/SKU:
          <h3> Tel alle locaties en vul het aantal in </h3>
          <h3>{{ pick_list[0].ean }} [{{ pick_list[0].product__stock }}x]</h3>
          
          <ProductLocation v-if="product && product.parent === null" :node="product" :i="0" />
          <ProductLocation v-else-if="product && product.parent" :node="product.parent" :i="0" />

          <b>Huidig aantal</b>
          <input class="flex-50" v-model="stock_curr_correct" type="number" />
          <b>Beschrijf waarom deze correctie is doorgevoerd:</b>
          <textarea v-model="stock_curr_correct_desc"></textarea>
          <button v-on:click="correct_stock({ org_id: product.org[0], ean: product.ean, desc: stock_curr_correct_desc,  curr_amount: stock_curr_correct  })" class="flex-50">Correctie doorvoeren</button>
          
      </div>

      <div v-else-if="((pick_list.length > 0) && (pick_list[0].product__stock <= 0 ) || (pick_list.length > 0)  && ( pick_list[0].product__stock - pick_list.qty <= 0 ))  ">
          <div class="" v-if="pick_list[0].needs_recount == false">
            {{ update_recount({recount: true, product__id: pick_list[0].final__product__id, needs_recount: pick_list[0].needs_recount  } ) }}
          </div>
        <!-- set recount to true  -->
      </div>

      <!-- Pick list order -->
      <div v-else-if="pick_list.length > 0" class="pick__order">
        <div :class="valid_location ? 'pick__order--conn flex flex-wrap green' : 'pick__order--conn flex-wrap flex'" >
          <!-- product note -->
          <div v-if="pick_list[0].product__note[0] && pick_list[0].product__note[0].length > 1" class="flex-100 flex-100-md product__note">
              <p>{{ pick_list[0].product__note.toString() }}</p>
          </div>
          <!-- end product note -->

          <div class="pick__order--location flex-30 flex-50-sm flex-30-md sub_menu__conn">
              <div class="flex">
                <h2>{{ pick_list[0].rack }}-{{ pick_list[0].loc_number }}</h2>
                <Dots :action="() => (expanded_sub_menu = !expanded_sub_menu)" />
              </div>
              <div class="sub_menu" v-if="expanded_sub_menu">
                <button class="close_button" @click="() => (expanded_sub_menu = !expanded_sub_menu)"> X </button>
                <popupPick />
                <br />
                <button v-on:click="
                    delete_pick_location({
                      location_product_id: pick_list[0].prod__loc_id,
                      product_id: pick_list[0].product__id,
                      location_id: pick_list[0].loc_id,
                    })
                  "
                  class="danger_button flex-30"
                >
                  Artikel niet aanwezig op locatie
                </button>
                <br />
                <br />
                <button class="warning_button flex-30">Lage voorraad</button>
              </div>
          </div>
            
          <div class="pick__order--qty flex-30 flex-30-md flex-50-sm">
            <p>{{ trx.pick_list.qty }}</p>
            <h1 :class="pick_list[0].qty > 1 ? 'red' : ''">  {{ pick_list[0].qty }} </h1>
          </div>
            
          <div ref="img" class="pick__order--image flex-30 flex-30-md flex-100-sm">
              <div v-if="pick_list[0].image[0]">
                <img
                  class="image"
                  :src="
                    `data:image/${pick_list[0].image[0].data.img_ext};base64,` +
                    pick_list[0].image[0].data.img_data
                  "
                />
              </div>
          </div>

          <div class="pick__order--ean flex-50 flex-50-md flex-100-sm">
              EAN/SKU:
              <h3>{{ pick_list[0].ean }} [{{ pick_list[0].product__stock }}x]</h3>
          </div>

          <div class="pick__order--title flex-50 flex-100-md">
              <p :title="pick_list[0].product__name"> {{ pick_list[0] && pick_list[0].product__name.length > 79 ? pick_list[0].product__name.substr(0, 80) + "..." : pick_list[0].product__name }} </p>
          </div>

        </div>
      </div>
      <!-- end pick_list order -->


      <div class="done" v-else-if="isLoading">
        <div class="done__conn">
          <h1 class="loading-indicator">Loading...</h1>
        </div>  
      </div>

      <div class="done" v-else>
        <!-- <button v-on:click="$refs.confetti.launchConfetti()">Celebrate Success</button> -->
        <div class="done__conn">
          <h2>Done! You are finished</h2>
        </div>
       
        <Confetti ref="confetti"></Confetti>

      </div>

      <Popup :title="'Hertellen'" :closed="!recount_popup" @handleClose="handleRecountClose">
        <ProductLocation v-if="product && product.parent === null" :node="product" :i="0" />

        <button class="save_button" @click="update_recount({recount: false, product__id: pick_list[0].product__id})">
          {{ trx.save }}
        </button>
      </Popup>

      <Popup :spaceBetween="true" :closed="!this.pick_note_popup" @handleClose="handlePickNoteClose" :title="trx.pick_note">
        <div class="note">
          <p>{{ this.pick_list[0] && this.pick_list[0].pick_note && this.pick_list[0].pick_note[0] }}</p>
        </div>
        <button @click="() => this.pick_note_popup = false" class="save_button">{{ trx.read }}</button>
      </Popup>

    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions, mapState } from "vuex";
  import Vue from "vue";
  import trx from "@/translations";
  import moment from "moment";
  import DateRangePicker from "vue2-daterange-picker";
  import vSelect from "vue-select";
  import popupPick from "./popupPick.vue";
  //   import { QrcodeStream } from 'vue-qrcode-reader';
  import location_correct_audio from "@/assets/audio/location_correct.mp3";
  import check_audio from "@/assets/audio/check.mp3";
  import wrong_audio from "@/assets/audio/wrong.mp3";
  
  import Dots from "@/components/Layout/dots.vue";
  import Popup from "@/components/Layout/popup.vue";
  import ProductLocation from "../Products/product_location.vue";
  
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import "vue-select/dist/vue-select.css";
  import SwitchInput from "../../components/Controls/Switch.vue";  

  import Confetti from '@/components/Confetti.vue';

  Vue.component("v-select", vSelect);
  
  /* eslint-disable no-debugger */
  export default {
    name: "Orders",
    computed: {
      ...mapState(['isLoading']),
      ...mapGetters([
        "pick_list",
        "p_size",
        "product",
        "order_report",
        "locations",
        "shipping_options",
        "shipment_methods_list",
        "shipment_boxes_list",
        "current_delivery",
        "location_groups",
        "cutoff_groups",
      ]),
    },
    methods: {
      ...mapActions([
        "fetch_shipment_boxes_list",
        "getOrders",
        "setNotes",
        "speak",
        "filter_locations",
        "fetchLocations",
        "fetch_location_groups",
        "fetch_cutoff_groups",
        "startTimer",
        "ship",
        "modify_limit",
        "filterDelivery",
        "shipPostNL",
        'setProduct',
        'update_recount',
        'correct_stock',
        "setNotes",
        "getOrderReport",
        "downloadOrderList",
        "toggleDoubleOrders",
        "fetch_shipment_methods_list",
        "fetch_pick_list",
        "delete_pick_location",
        "link_pick_location",
      ]),
     
      async ini_fetch_pick_list() {
        const self = this;
        this.noteClosed = false;

        if (this.skip < 0) {
          this.skip = 0;
          return;
        }
        await this.fetch_pick_list({
          //TODO: Add the group filter, if this exist only get the pick_list for this group
          locations: this.sl_group != "" ? this.sl_group : this.sl_locations,
          delivery: this.sm_group != "" ? this.sm_group : this.sl_delivery,
          date_range: this.dateRange,
          skip: this.skip,
          query_type: this.query_type,
          focus_element: self.$refs.img,
          sequence: this.sequence,
          single_orders: this.single_orders,
          multi_orders: this.multi_orders,
        });
  
        // Fix load on database, if the returned pick_list needs a recount, only then recount
        if (this.pick_list[0] && this.pick_list[0].needs_recount) {
          await this.setProduct(this.pick_list[0].product__id);
        }

        // this.speak(this.pick_list[0].rack+'  '+this.pick_list[0].loc_number + '  ' + this.pick_list[0].qty +' Keer' );

      },
      async updateNotes(model) {
        await this.setNotes(model);
        await this.ini_fetch_pick_list();
      },
      focus_change() {
        this.$refs.conatiner.focus();
      },
      onDecode(location_id) {
        const self = this;

        if (location_id === this.pick_list[0].loc_id) {
          this.valid_location = true;   
          var audio = new Audio(location_correct_audio);
          audio.play();
          setTimeout(() => {
              this.speak(this.pick_list[0].qty +' Keer')
          }, 1000);

        } else if (this.valid_location) {
          let order_ids = [];
  
          if (self.pick_list[0].orders) {
            order_ids = self.pick_list[0].orders.map((x) => {
              return x.order_id;
            });
          } else {
            order_ids.push(self.pick_list[0].order_id);
          }
  
          this.link_pick_location({
            link_location: location_id,
            order_ids: order_ids,
          });

          const check_audio_inst = new Audio(check_audio);
          check_audio_inst.play();

        } else {
          const wrong_audio_inst = new Audio(wrong_audio);
          wrong_audio_inst.play();
          setTimeout(() => {
              this.speak(this.pick_list[0].rack.split('').join(' ')+'  '+this.pick_list[0].loc_number + '  ' + this.pick_list[0].qty +' Keer')
          }, 1000);
        }
      },
      listen_to_ean_input() {
        let code = "";
        const self = this;

        window.addEventListener("keypress", (e) => {
          //usually scanners throw an 'Enter' key at the end of read
          if (e.keyCode === 13) {
            if (code.length > 23) {
              if (code === this.pick_list[0].loc_id) {
                
                this.valid_location = true;
                
                this.recount_popup = this.pick_list[0].needs_recount ? true : false;
                
                // this.pick_note_popup = this.pick_list[0].pick_note && this.pick_list[0].pick_note.length > 0 ? true : false;

                var audio = new Audio(location_correct_audio);
                
                audio.play();
                setTimeout(() => {
                  this.speak(this.pick_list[0].qty +' Keer')
              }, 1000);

                // this.scan_location = false;
              } else if (this.valid_location) {
                let order_ids = [];
  
                if (self.pick_list[0].orders) {
                  order_ids = self.pick_list[0].orders.map((x) => {
                    return x.order_id;
                  });
                } else {
                  order_ids.push(self.pick_list[0].order_id);
                }
  
                this.link_pick_location({
                  link_location: code,
                  order_ids: order_ids,
                  product_id: self.pick_list[0].product__id,
                });

                const check_audio_inst = new Audio(check_audio);
                check_audio_inst.play();

                this.valid_location = false;
                this.recount_popup = false;
                this.pick_note_popup = false;

              } else {

                const wrong_audio_inst = new Audio(wrong_audio);
                wrong_audio_inst.play();
                setTimeout(() => {
                  this.speak(this.pick_list[0].rack.split('').join(' ')+'  '+this.pick_list[0].loc_number + '  ' + this.pick_list[0].qty +' Keer')
                }, 1000);
                

              }
  
              /// code ready to use
              code = "";
            }
          } else {
            code += e.key; //while this is not an 'enter' it stores the every key
          }
        });
      },
      onInit(promise) {
        promise.then(console.log).catch(console.error);
      },
      scan_location_func: function () {
        this.scan_location = false;
      },
      
      change_sequence(value) {
        this.sequence = this.sequence * value;
        this.skip = 0;
        this.ini_fetch_pick_list();
      },
      change_single_orders(value) {
        this.single_orders = value;
        if (value === true) {
          this.multi_orders = false;
        }

        this.skip = 0;
        this.ini_fetch_pick_list();
      },
      change_multi_orders(value) {
        this.multi_orders = value;
        if (value === true) {
          this.single_orders = false;
        }

        this.skip = 0;
        this.ini_fetch_pick_list();
      },
      handleMobileMenu() {
        this.mobile_menu_expanded = !this.mobile_menu_expanded;
      },
      handleRecountClose() {
        this.recount_popup = false
      },
      handlePickNoteClose() {
        this.pick_note_popup = false
      }
    },
    components: {
      DateRangePicker,
      Dots,
      popupPick,
      SwitchInput,
      Popup,
      ProductLocation,
      Confetti
    },
    created() {
      this.fetchLocations();
      this.listen_to_ean_input();
      this.ini_fetch_pick_list();
      this.fetch_location_groups();
      this.fetch_cutoff_groups();
  
      if (!this.shipment_boxes_list) {
        this.fetch_shipment_boxes_list();
        this.fetch_shipment_methods_list();
      }
      if (this.pick_list[0] && this.pick_list[0].needs_recount) {
        this.setProduct(this.pick_list[0].product__id);
      }
      
    },
    data() {
      let startDate = new Date(moment().subtract(4, "days"));
      let endDate = new Date(moment().set({ hour: 22, minute: 0 }));
  
      return {
        trx,
        moment: moment,
        expanded_sub_menu: false,
        dateRange: { startDate, endDate },
        sl_locations: "",
        sl_delivery: "",
        sl_group: "",
        sm_group: "",
        query_type: "singel",
        scan_location: true,
        valid_location: false,
        wrong_location: false,
        skip: 0,
        sequence: 1,
        img: this.$refs.img,
        mobile_menu_expanded: false,
        noteClosed: false,
        recount_popup: false,
        pick_note_popup: false,
        single_orders: moment().hour() >= 20 ? false : true,
        multi_orders: false,
        stock_curr_correct: 0,
        stock_curr_correct_desc: "",
      };
    },
    watch: {

    },
    filters: {
      date(val) {
        return val ? val.toLocaleString() : "";
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  h1,
  h2 {
    margin: 0;
    font-size: 450%;
  }

  @mixin std-div {
    @include box-bg-white;
    margin-right: 1rem;
    display: flex;
    max-height: 15rem;
    min-height: 15rem;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap !important;
    margin-bottom: 1rem !important;
    margin-top: 0px;
    box-shadow: 0;
  }

  .pick {
    margin-top: 0rem;
    margin-bottom: 40rem;
    max-width: 720px;
    
    &__header {
      margin-bottom: 2rem;
      width: 100%;
      .date_time {
        display: flex;
        text-align: right;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        justify-self: flex-end;
      }
    }

    &__skip_buttons {
      display: flex;
      gap: 0.25rem;
      &--skip {
        position: relative;
       span {
        position: absolute;
        padding: 2px 7px;
        border-radius: 100%;
        top: -5px;
        right: -5px;
        background: var(--color-red);
       } 
      }
      &--ref {
        font-size: 3rem;
      }
        justify-content: space-between;
        flex-direction: row;
        gap: 1rem;
        button {
          width: 100%;
        }
    }

    &__filters {
      &--select {
        margin-bottom: 1rem;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        >div:not(:nth-child(even)) {
          padding-right: 2rem;
        }
        .v_select {
          font-size: 1.7rem;
          @media screen and (max-width: $bp-medium) {
            font-size: 3rem;
          }
        }
      }
      &--options {
        justify-content: flex-start;
        flex-direction: row;
        @media screen and (min-width: $bp-small) {
          gap: 18rem;
        }
        margin-bottom: 1rem;
        .order_switch {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 1rem 0;
          padding: 0;
          gap: 1rem;
          position: relative;
          label {
            z-index: 1;
          }
          p {
            position: absolute;
            border-radius: 20px;
            left: -2px;
            min-width: 180px;
            padding: 0px 2rem;
            @media screen and (min-width: $bp-small) {
              padding: 2px 2rem;
              padding-left: 8rem;
            }
            padding-left: 7rem;
            border: 1px solid var(--color-primary);
          }
        }
      }
    }

    &__order {
      @include box-bg-dash;
      padding: 1rem;
      margin-top:2rem;
      &--conn {
          >div:not(.product__note) {
          @include std-div;
          margin-top: 0px;
          padding: 0;
          >div:last-child {
            margin: 0;
          }
          .image {
            width: 100%;
            height: 12rem;
            object-fit: contain;
          }
          @media only screen and (max-width: $bp-medium) {
            h1 {
              font-size: 7rem;
            }
          }
        }
      }
      &--qty {
        @media all and (max-width: $bp-small) {
         p {margin-bottom: 2rem;}
        }
      }
      &--ean {
        h3 {
          text-align: center;
        }
      }
      &--location {
        h2 {font-size: 3.8rem !important}
        @media all and (max-width: $bp-small) {
         h2 {font-size: 3rem !important}
        }
      }
      &--title {
        padding: 1rem !important;
        text-align: center;

      }
    }
  }
  
  .sub_menu {
    position: absolute;
    bottom: -18rem;
    right: 2rem;
    background: var(--color-black);
    padding: 2rem;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
    &__conn {
      position: relative;
    }
    button {
      width: 100%;
    }
  }

  div.green {
    background: var(--color-green);
    color: var(--color-white);
  }

  .qrcode-scanner-loc {
    height: 15rem;
    width: 15rem;
  }

  .red {
    color: var(--color-red) !important;
  }
  
  .skip_amount {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .close_button {
    background-color: gray;
    margin-bottom: 2rem;
  }
  
  .v_select {
    margin: 0 0 1rem 0;
    padding: 0;
  }
  
  .save_button {
    width: 100%;
  }
  
  .mobile-menu-hidden {
    @media only screen and (max-width: $bp-small) {
      display: none;
    }
  }
  
  .mobile-menu-hamburger {
    position: fixed;
    top: 2rem;
    left: 10rem;
    color: white;
    z-index: 1000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  
    @media only screen and (min-width: $bp-small) {
      display: none;
    }
  }
  
  .mobile-menu-hamburger-line {
    height: 0.5rem;
    width: 5rem;
    background-color: white;
    margin-bottom: 1rem;
  }

  .done {
    &__conn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50vh;
      h2 {
        font-size: 5rem;
      }
    }
  }

  </style>
  