<template>
    <div class="infoBox">
        <h4 class="infoBox__name">{{name}}</h4>
        <div class="infoBox__numbers">
            <span class="infoBox__available">{{small_number}}</span> /
            <span class="infoBox__used">{{big_number}}</span>
        </div>    
    </div>
</template>

<script>
export default {
  name: 'infoBox',
  props: {
      name: String,
      big_number: Number,
      small_number: Number,
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .infoBox {
          @include box-bg-white;
          display: flex;
          flex-direction: column;
          padding: 1rem;
          flex: 0 0 20rem;
          justify-content: space-between;
          &__name {
              margin-bottom: 1rem;
              margin-top: 0rem;
          }
          &__used {
              font-size: 3rem;
              line-height: 3rem;
              margin-right: 0.5rem;
          }
          &__available {
              font-size: 1.7rem;
              margin-left: 0.5rem;
          }
          
          &__numbers {
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              margin-left: auto;
              margin-top: auto;
          }

          
      }
</style>
