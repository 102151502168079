<template>
  <div id="app">
    <h2 class="flex-60">Hi {{ user.first_name }}</h2>
    <br />
    <div class="flex">
      <div class="flex-30 margin-20 menu">
        <WideBox 
          v-if="authCheck('/products/ean/*', 'GET', user.curr_role)"
          class="menu__item"
          title="Inbound"
          link="inbound/item"
          description="Iets inboeken?"
        />
        <WideBox
          v-if="authCheck('/orders', 'GET', user.curr_role)"
          class="menu__item"
          title="Nieuwe zending aanmaken"
          link="new_order"
          description="Iets verzenden?"
        />
        <WideBox
          v-if="authCheck('/orders', 'GET', user.curr_role)"
          class="menu__item"
          title="Verzonden order zoeken"
          link="shipments"
          description="Vraag van een klant?"
        />
        <WideBox
          class="menu__item"
          title="Meld je producten aan"
          link="add_inventory"
          description="Nieuwe producten?"
        />
        <WideBox
          class="menu__item"
          title="Monitor de status van je goederen"
          link="checkin_inventory"
          description="Verwacht je een Inbound?"
        />
      </div>

      <div class="flex-70 margin-20" v-if="authCheck('/orders', 'GET', user.curr_role)">
        <div class="flex">
          <div class="flex-45 box numeric">
            <h3>Open orders vandaag</h3>
            <h1 v-if="order_report_open[0]">
              {{ order_report_open[0].value }}
            </h1>
          </div>
          <div class="flex-5"></div>
          <div class="flex-50 box numeric">
            <h3>Vandaag verwerkt</h3>
            <h1 v-if="order_report_fulfilled[0]">
              {{ order_report_fulfilled[0].value }}
            </h1>
          </div>
        </div>
        <br />
        <div class="flex">
          <div
            class="box flex-100 box_dash_red"
            v-if="order_report_notes.length > 0"
          >
            <h3>Orders die aandacht vragen</h3>
            <div v-for="on in order_report_notes" v-bind:key="on._id">
              <div :class="'box box_' + on.notes_color">
                <h4>{{ on.orderId }}</h4>
                <p>{{ on.notes_text }}</p>
                <div class="" v-for="onp in on.products" v-bind:key="onp._id">
                  <h4>{{ onp.ean }}</h4>
                  {{ onp.title }}
                  <br />
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <!-- <Orders30 class="box" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import trx from "@/translations";
import WideBox from "@/components/Boxes/wide";
import authCheck from "@/lib/authCheck";
// import Orders30 from './Report/orders.vue';
/* eslint-disable no-debugger */

export default {
  name: "App",
  components: {
    WideBox,
    // Orders30
  },
  computed: {
    ...mapGetters([
      "user",
      "order_report_notes",
      "order_report_open",
      "order_report_fulfilled",
    ]),
  },
  methods: {
    ...mapActions([
      "getOrderReport",
      "get_order_report_notes",
      "get_order_report_open",
      "get_order_report_fulfilled",
    ]),
    authCheck,
  },
  created: function () {
    if (!this.user) {
      this.fetchUser;
    }
    // this.get_order_report_notes();
    this.get_order_report_open();
    this.get_order_report_fulfilled();
  },
  data() {
    return {
      trx,
    };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 5px;
  font-size: 2rem;
}

.charts_conn {
  overflow: auto;
}

.box {
  @include box-bg-white;
}

.box_red {
  @include box-bg-red;
}

.box_dash_red {
  @include box-bg-red-dash;
}

.flex {
  align-items: flex-start !important;
}

.margin-20:not(:last-child) {
  margin-right: 2rem;
}

.menu {
  &__item {
    margin-bottom: 1rem;
  }
}

.numeric {
  h1 {
    font-size: 15rem;
    line-height: 16rem;
    text-align: center;
    font-weight: 400;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 26rem;
}

.charts {
  > div {
    @include box-bg-white;

    padding: 0rem;
    // margin: 0.5rem 1rem 1rem 0rem;
    width: 100%;
  }
  .gauge {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
