/**
 * Check if the user has the permission to access the route
 * 
 * @param {string} route - Route name
 * @param {string} method - HTTP method
 * @param {Object} curr_role - Current role of the user
 * @returns {boolean} - True if the user has the permission, false otherwise
 */
const authCheck = (route, method, curr_role) => {
	const routes = curr_role?.functions || null;
	if (routes === null) {
		return false;
	}
	if (!routes[route]) {
		return false;
	}
	method = method.toUpperCase();
	if (!routes[route].includes(method)) {
		return false;
	}
	return true;
}

export default authCheck;