<template>
    <div v-if="aut_expanded" class="product__aut">
        <div class="flex">
            <button class="add_new_button" v-on:click="add_automation({product_id: product._id})">+</button> 
            <h3> Automation(s) BETA</h3>
        </div>
        <div class="automations" v-if="automations">
            <AutomationRule v-for="x in automations" v-bind:key="x._id" :node="x" :product="product"  />
        </div>
    </div>
</template>
        
<script>

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import vSelect from 'vue-select';
import AutomationRule from '../Automations/autm_rule.vue';
import trx from '@/translations';
/* eslint-disable no-debugger */

import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

export default {
  
    computed: {
        ...mapGetters(['products', 'automations', 'shipment_methods_list', 'shipment_boxes_list']),
    },
    methods: {
        ...mapActions([ 'fetch_automations', 'edit_automation', 'add_automation']),
    },
    components: {
        AutomationRule
    },
    created() {
       
    },
    props: {
        product: Object,
        i: Number,
        link_expanded: Boolean,
        aut_expanded: Boolean,
        component_name: String,

    },
    data() {
        return {
            trx,
            
        }
    },
    name: "product_automation",
}
</script>

<style lang="scss" scoped>
   .product__aut {
       @include box-bg;
       padding: 2rem;

       .autm {
        margin-top: 2rem;
        padding: 2rem;
        @include box-bg-white;
         
        &__actions {
            @include box-bg-dash;
            padding: 2rem;
            margin-left: 5rem;
            max-width: 50rem;
        }
        [class^='flex-'] {
            margin-right: 1rem;
        }

       }
   }
</style>
