<template>
  <div class="adduser-container">
        <h4>{{trx.users["Create a new user"]}}</h4>
        <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
  </div>
</template>

<script>
import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';
// import store from '@/store';
/* eslint-disable no-debugger */

Vue.use(VueFormGenerator)

export default {
  name: 'AuthContainer',
  methods: {
    ...mapActions(['fetchUser', 'signUp', 'addUser', 'fetchPlans'])
  },
  computed: {
    ...mapGetters(['user', 'plans'])
  },
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  created() {
   this.fetchPlans();
  },
  data () {
    
    return {
    trx,
      model: {
        first_name: '',
        family_name: '',
        email: '',
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: trx.users["First name"],
            model: 'first_name',
            placeholder: 'Jhon',
            autocomplete: false,
            featured: true,
            required: true
          },{
            type: 'input',
            inputType: 'text',
            label: trx.users["Last name"],
            model: 'family_name',
            placeholder: 'Doe',
            autocomplete: false,
            featured: true,
            required: true
          },
          {
            type: 'input',
            inputType: 'email',
            label: trx.users["Email"],
            model: 'email',
            placeholder: 'john.doe@example.com'
          },
          {
            type: 'select',
            label: trx.users['Licence'],
            values: () => { 
                const values = this.plans.map((x) => {
                    return {
                        name: x.name,
                        id: x.id
                    }
                });
                return values
            },
            model: 'curr_sub',
            placeholder: trx.users["Select your licence"]
          },
          {
            type: 'Submit',
            buttonText: trx.add,
            onSubmit: this.addUser,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        // validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss">
</style>
