<template>
  <div class="product__location">
    <div class="flex" style="margin-bottom: 1rem">
      <div class="flex-80 flex-80-md">
        <h4>Locatie(s)</h4>
        <h4 class="product__totalstock">
          {{ trx.products.total_stock }}: {{ node.sf_stock }}
        </h4>
      </div>
      <div class="flex-20 flex-20-md text-right">
        <!-- v-on:click="getProductLocations({product_id:product._id })" -->
        <button class="loc_link_button" ref="loc_link_button" v-on:click="addToLocation(node)">
          +
        </button>
        <button class="hidden" v-on:click="scan_location = !scan_location">
          📷
        </button>
        <div class="qrcode-scanner hidden" v-if="scan_location">
          <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
        </div>
        <!-- <StreamBarcodeReader  @decode="scan_location_func" @loaded="onLoaded"></StreamBarcodeReader> -->
        <input
          type="input"
          style="display: none"
          class="barcode product__barcode-input"
          ref="addToLocation"
          v-on:input="alter"
          placeholder="Please scan barcode"
        />
      </div>
    </div>

    <AssignLocation
      v-for="pl in node.locations.filter((loc) => loc.active === true && loc.toTransfer !== true)"
      v-bind:key="pl._id"
      :node="pl"
      :ean="node.ean"
      :stock="pl.stock"
      :id="node._id"
      :location_number="pl.location_id.name"
      :location_rack="pl.location_id.parent.name"
      :i="i"
      :inboundItemId="inboundItemId"
    />

    <Popup v-if="location_to_delete.req_id == node._id" @handleClose="reset_location_to_delete" :closed="location_to_delete && location_to_delete.prod_ids.length > 0 ? false : true" closable="true"  title="Oeps! deze locatie is nog niet leeg">
      <div class="existing_location_container" v-for="(p, i) in location_to_delete.prod_ids" v-bind:key="p._id" >
        <h3>Controleer en verwijder de juiste locatie om verder te gaan</h3>
        <br>
        <b>Product</b>
        <h5>{{ p.name }}</h5>
        <b>EAN</b>
        <h5>{{ p.ean }}</h5>
        <ChildProductLocation v-if="(p.parent === null) && (p._id === location_to_delete.prod_ids[0]._id)" :node="p" :i="i"  />
      </div>
     
    </Popup>
   
    <!-- <div class="product__location__link">
            <div class="">{{trx.products["Link product to location"]}}</div>
            <input type="text" v-model="products.location_link" v-on:change="linkProductLocation(product)"  >
        </div> -->
  </div>
</template>

<script>
import trx from "@/translations";
import { mapGetters, mapActions } from "vuex";
import AssignLocation from "./assign_location.vue";
// import { StreamBarcodeReader } from "vue-barcode-reader";
import { QrcodeStream } from 'vue-qrcode-reader';
import Popup from "@/components/Layout/popup.vue";
import ean_checked from '@/assets/audio/ean_checked.mp3'

/* eslint-disable no-debugger */

export default {
  name: "ProductLocation",
  computed: {
    ...mapGetters(["products", 'location_to_delete', 'user']),
  },
  methods: {
    ...mapActions(["link_barcode", 'reset_location_to_delete']),
    alter(event) {
      // Handle the input event, here you can access event.key, event.target.value, etc.
      console.log('Key pressed:', event.key);  // Example: log the key pressed
    },
    addToLocation: function (elm) {
      const self = this;
      var audio = new Audio(ean_checked)
      audio.play();
      const setLocation = async (e) => {
              
            if(e.keyCode === 13) {
                this.link_barcode({product_id: elm._id, location_id: e.currentTarget.value, amount: 0, search: self.node.ean  })
                this.$refs.addToLocation.style.display = 'none'
                this.$refs.addToLocation.removeEventListener("keydown", setLocation, true );
                this.$refs.addToLocation.value = null;

            } else if (e.key === "Escape") {
                this.$refs.addToLocation.style.display = 'none'
            }
      }
      this.$refs.addToLocation.addEventListener("keydown", setLocation, true);
      this.$refs.addToLocation.style.display = "inline";
      this.$refs.addToLocation.focus();
    },
    scan_location_func: function () {
      this.scan_location = false;
    },
    onLoaded: function () {},
    onDecode(location_id) {
      this.link_barcode({
        product_id: this.node._id,
        location_id: location_id,
        amount: 0,
        search: this.node.ean,
      });
      this.scan_location = false;
    },
    onInit(promise) {
      promise.then(console.log).catch(console.error);
    },
  },
  components: {
    AssignLocation,
    // StreamBarcodeReader,
    QrcodeStream,
    Popup,
    ChildProductLocation: () => import('./product_location.vue')
  },
  props: {
    node: Object,
    i: Number,
    inboundItemId: String,
  },
  data() {
    return {
      trx,
      amount: 0,
      scan_location: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.product__location {
  position: relative;
  height: auto;
  overflow: auto;
  max-width: 330px;
  input {
    position: absolute;
  }
}

.loc_link_button {
  margin-right: 1rem;
}

.barcode {
  position: absolute;
  background: var(--color-white);
  left: 0px;
  top: 0;
  margin: 0;
  width: 80%;
  z-index: 99999;
}

.scanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100;
  // width: 95vw;
  // video {
  //   height: 100vh;

  // }
}

.existing_location_container {
  .popup_content {
    justify-content: center;
  }
}
</style>
