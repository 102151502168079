<template>
    <div :class="`control_buttons ${className}`">
        <router-link :to="{ path: '/pick' }" class="button">
            {{ trx.pick_list["name"] }}
        </router-link>
        <button :class="p_size === 1 ? '' : 'active'" v-on:click="toggleDoubleOrders">
            Multi orders
        </button>
        <router-link :to="{ path: '/returns' }" class="button">
            {{ trx.returns["name"] }}
        </router-link>
    </div>
</template>

<script>

import trx from "@/translations";
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ControlButtons",
    computed: {
        ...mapGetters([
            "p_size",
        ])
    },
    methods: {
        ...mapActions([
            "toggleDoubleOrders",
        ]),
    },
    data() {
        return {
            trx,
            menuOpen: false,
        }
    },
    props: {
        className: String,
    }
}
</script>

<style lang="scss">
.button {
  @include button;
  margin-right: 2rem;
}

.control_buttons {
  position: absolute;
  top: 0;
  right: 2rem;
  display: flex;
  width: auto;
  text-align: center;

  a,
  button {
    @include box-bg-solid;
    width: 100%;
    min-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-right: 1rem;

    &.active {
      color: var(--color-white);
      background: var(--color-primary);
    }
  }
  @media only screen and (max-width: $bp-small) {
    position: relative;
    display: flex;
    width: auto;
    text-align: center;
    flex-direction: column;
    right: 0;

    a, button {
      min-width: 0rem;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
    }
  }
}

.control_buttons_desktop {
  @media only screen and (max-width: $bp-small) {
    visibility: hidden;
    display: none;
  }
}
</style>