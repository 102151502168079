<template>
  <div class="adduser-container">
        <h4>{{trx.admin.ship_boxes.create}}</h4>
        <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
  </div>
</template>

<script>
import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';
// import store from '@/store';
/* eslint-disable no-debugger */

Vue.use(VueFormGenerator)

export default {
  name: 'AuthContainer',
  methods: {
    ...mapActions(['add_package'])
  },
  computed: {
    ...mapGetters(['user', 'plans'])
  },
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  created() {
   
  },
  data () {
    
    return {
    trx,
      model: {
        "name": '',
        "max_size": '',
        "package_cost": '',
        "purchase_cost":'',
        "package_code": '',
        "fulfillment_cost": ''
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: trx.admin.ship_boxes.package,
            model: 'name',
            autocomplete: false,
            featured: true,
            required: true
          },{
            type: 'input',
            inputType: 'text',
            label: trx.admin.ship_boxes.max_size,
            model: 'max_size',
            autocomplete: false,
            featured: true,
            required: true
          },
          {
            type: 'input',
            inputType: 'number',
            label: trx.admin.ship_boxes.package_cost,
            model: 'package_cost',
          },{
            type: 'input',
            inputType: 'number',
            label: trx.admin.ship_boxes.purchase_cost,
            model: 'purchase_cost',
          },
          {
            type: 'input',
            inputType: 'text',
            label: trx.admin.ship_boxes.package_code,
            model: 'package_code',
          },{
            type: 'input',
            inputType: 'number',
            label: trx.admin.ship_boxes.fulfillment_cost,
            model: 'fulfillment_cost',
            autocomplete: false,
            featured: true,
            required: true
          }, {
            type: 'Submit',
            buttonText: trx.add,
            onSubmit: this.add_package,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        // validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss">
</style>
