<template>
<div v-if="expanded">
	<h3>{{ trx.products.recount.settings }}</h3>
	<div class="label">
		<p>{{ trx.products.recount.recounting }}:</p>
		<SwitchInput :value="recount.toString()" :checked="recount" :change="handleRecount" :name="'Recount selecter'" :id="'recount-selecter'" />
	</div>
</div>
</template>
<script>
import trx from "@/translations"
import SwitchInput from "@/components/Controls/Switch.vue"
import api from "@/api"

export default {
	name: 'RecountSettings',
	props: {
		product: Object,
		expanded: Boolean,
		recounted: Boolean,
	},
	data() {
		return {
			trx,
			recount: false,
		}
	},
	created() {
		this.recounted = this.product.recounted ? this.product.recounted : false
		if (this.product.needs_recount) {
			this.recount = this.product.needs_recount
		}
	},
	methods: {
		handleRecount() {
			this.recount = !this.recount
			api.post("product/recount/" + this.product._id, {
				recount: this.recount,
			})
			this.product.needs_recount = this.recount
		},
	},
	components: {
		SwitchInput,
	},
}
</script>
<style lang="scss" scoped>
.label {
	display: flex;
	gap: 2rem;
}
</style>