import api from '@/api';
import router from '../../router';
/* eslint-disable no-debugger */


const state = {
    current_org: null,
    current_org_auth: null,
    orgs: [],
    deactiveOrgs: []
};

const getters = {
    current_org: state => state.current_org,
    current_org_auth: state => state.current_org_auth,
    orgs: state => state.orgs,
    deactiveOrgs: state => state.deactiveOrgs
};

const actions = {
    async fetchCurrOrg({commit} ) {
        const response = await api.get("/current_org_auth");
        response !== undefined ? commit('set_curr_org_auth', response.data) : '';  
    },
    async fetch_curr_org_details({commit}) {
        const response = await api.get("/current_org");
        response !== undefined ? commit('setCurrOrg', response.data) : '';  
    },
    async fetchOrgs({commit}) {
        const response = await api.get("/orgs");
        response !== undefined ? commit('setOrgs', response.data) : '';  
    },
    async fetchDeactiveOrgs({commit}) {
        const response = await api.get("/orgs/deactive");
        response !== undefined ? commit('setDeactiveOrgs', response.data) : '';  
    },
    async setCurrOrg({commit, dispatch}, id) {
        const response = await api.post('/orgs/set_curr', {customer_id: id});
        await dispatch('fetchCurrOrg');
        response !== undefined ? commit('setCurrOrg', response.data) : '';  
    },
    async createOrg({dispatch}, model) {
        const response = await api.post("/orgs", model);
        await dispatch('setCurrOrg', response.data._id);
        await dispatch('fetchCurrOrg');
        router.push({path: '/subscriptions/terms'});
    },
    async acceptTerms({commit}) {
        const response = await api.get("/accept_terms");
        response !== undefined ? commit('setCurrOrg', response.data) : ''; 
        router.push({path: '/settings/apis'}); 
    },
    async updateOrg({commit, dispatch}, model) {
        const response = await api.put("/orgs",  model );
        await dispatch('fetchCurrOrg', response.data._id);
        commit('setCurrOrg', response.data);
    },
    async deleteOrg({commit, dispatch}, model) {
        const response = await api.delete("/orgs/delete/"+model);
        commit('removeOrg', response.data);
        dispatch('fetchOrgs');
        dispatch('fetchDeactiveOrgs');
        router.push({path: '/orgs'}); 
    },
    async activateOrg({dispatch}, model) {
        await api.put("/orgs/activate/"+model);
        dispatch('fetchOrgs');
        dispatch('fetchDeactiveOrgs');
    },
    async editApis({dispatch}, model ) {
        await api.put("/orgs/editapis", model);
        await dispatch('fetchCurrOrg');
    }
};

const mutations = {
    setCurrOrg: (state, org) => (state.current_org = org),
    set_curr_org_auth: (state, org) => (state.current_org_auth = org),
    setOrgs: (state, orgs) => (state.orgs = orgs),
    setDeactiveOrgs: (state, deactiveOrgs) => (state.deactiveOrgs = deactiveOrgs),
    addorg: (state, org) => (state.orgs.push(org)),
    removeOrg: (state) => (state.current_org = null)
};

export default {
  state,
  getters,
  actions,
  mutations
}
