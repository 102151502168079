<template>
  <div>
    <h2>Picking</h2>
    <GChart v-if="ready" :type="'BarChart'" :data="picks" :options="chartOptions" />
		<button v-if="JSON.stringify(player_picks) === '{}'" @click="() => { player_picks = getUserSum(picks)}">See highscore</button>
    <div v-if="player_picks != {}">
			<table>
				<tr>
					<th><p>Player</p></th>
					<th><p>Picks</p></th>
				</tr>
				<tr v-for="(value, key) in player_picks" :key="value+key">
					<td><p>{{ key }}</p></td>
					<td><p>{{ value }}</p></td>
				</tr>
			</table>
		</div>
    <h2>Packing</h2>
    <GChart v-if="ready" :type="'BarChart'" :data="packs" :options="chartOptions" />
		<button v-if="JSON.stringify(player_packs) === '{}'" @click="() => { player_packs = getUserSum(packs)}">See highscore</button>
    <div v-if="player_packs != {}">
			<table>
				<tr>
					<th><p>Player</p></th>
					<th><p>Packs</p></th>
				</tr>
				<tr v-for="(value, key) in player_packs" :key="value+key">
					<td><p>{{ key }}</p></td>
					<td><p>{{ value }}</p></td>
				</tr>
			</table>
		</div>
  </div>
</template>
<script>
import api from "@/api";
import { GChart } from "vue-google-charts";

export default {
  name: "Scoreboard",
  data() {
    return {
      ready: false,
      picks: [],
      packs: [],
      player_picks: {},
      player_packs: {},
			chartOptions: {
				vAxis: {
					format: '0',
				}
			}
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fetchPicks();
    this.fetchPacks();
    this.ready = true;
  },
  methods: {
    async fetchPicks() {
      const res = await api.get("/report/pick");
      this.picks = res.data;
    },
    async fetchPacks() {
      const res = await api.get("/report/pack");
      this.packs = res.data;
    },
    getUserSum(list) {
			let player_index = [];
			let players = {};
			list.forEach((item, idx) => {
				if (idx === 0) {
					// We are in the header
					player_index.push(0) // Add the first column so that we don't have to think about it later
					item.forEach((player, idx) => {
						if (idx > 0) {
							player_index.push(player);
							players[player] = 0;
						}
					});
				} else {
					// We are in the data
					item.forEach((player, idx) => {
						if (idx > 0) {
							players[player_index[idx]] += player;
						}
					});
				}
			});

			// Sort the players
			players = Object.fromEntries(
				Object.entries(players).sort(([,a],[,b]) => b-a)
			);

			return players;
		},
  },
  components: {
    GChart,
  },
};
</script>
<style lang="scss" scoped></style>
