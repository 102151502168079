<template>
  <div class="organization">
        <h3>{{trx.organizations["Your organization details"]}}</h3>
        <OrgsForm v-if="current_org !== null" :model="current_org" :submit="updateOrg" />
        
        <div class="orgs__surcharges">
          <h3>{{trx.organizations.surcharges}}</h3>
           <v-select v-if="surcharges_list" v-model="current_org.surcharges" @input="select_surcharges({org_id: current_org._id, items: current_org.surcharges})" taggable multiple push-tags :options="surcharges_list.filter(x => {return x.type === 'store'})" label="name" >
              <template slot="option" slot-scope="option" >
                  <p>{{option.name}}</p>
                  <small class="text-muted">
                      € {{ option.price }}
                  </small>
              </template>    
          </v-select>   
        </div>

        <div class="orgs__delete">
          <h3>{{trx.organizations.delete_org_help}}</h3>
          <button v-on:click="deleteOrg(current_org._id)">{{trx.delete}}</button>
        </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrgsForm from '../Orgs/from';
import trx from '@/translations';

export default {
  name: 'organization',
  methods: {
    ...mapActions([
      'fetch_curr_org_details',
      'select_surcharges',
      'updateOrg',
      'deleteOrg', 
      'fetch_surcharges_list'
    ])
  },
  computed: mapGetters(['current_org', 'surcharges_list']),
  components: {
    OrgsForm
  },
  created() {
    this.fetch_curr_org_details();
    if (!this.surcharges_list) {
      this.fetch_surcharges_list()
    }
  },
  data () {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>
   h3 {
     margin-bottom: 2rem ;
   }
  
   .orgs {

     &__surcharges {
       margin-top: 5rem;
     }

     &__delete {
      border: 2px dashed var(--color-red);
      background: var(--color-white);
      border-radius: var(--border-radius-box);
      padding: 2rem;
      margin:5rem 0rem;
      box-shadow: var(--shadow-light);
    }

   }
</style>
