<template>
    <div class="foundLocations">
        <div>
            <td><span @click="deleteReservedLocation({ inbItemId: inbound_item._id, locationId: location.location_id._id })"
                    class="hoverButton">x</span></td>
        </div>
        <div>
            <input type="number" :max="maxUnits" :min=1 v-model="units" class="piecesInput" />
        </div>
        <div>
            <input type="number"  v-model="total_pieces" class="piecesInput" />
        </div>
        <div>
            <p class="locationName">{{ location.location_id.parent.name ? location.location_id.parent.name : '' }}-{{
                location.location_id.name ? location.location_id.name : '' }} </p>
        </div>
        <div>
            <AddLocationToInbItem :product="inbound_item.product_id" :pieces="Number(total_pieces)" 
            :locationId="location.location_id._id" :inboundItemId="inbound_item._id"/>
        </div>
    </div>
</template>

<script>
import AddLocationToInbItem from './addLocationToInbItem.vue'
import { mapActions } from 'vuex'

export default {
    name: 'locationFinderItem',
    components: {
        AddLocationToInbItem,
    },
    computed: {
        maxUnits() {
            return this.inbound_item.units;
        }
    },
    methods: {
        ...mapActions(['deleteReservedLocation']),
    },
    props: {
        location: Object,
        inbound_item: Object,
        total_locations: Number,
    },
    data() {
        return {
            units: 1,
            total_pieces: 0,
        }
    },
    created() {
        if (this.inbound_item.pieces_in_transfer > 0) {
            this.total_pieces = this.inbound_item.pieces_in_transfer / this.total_locations;
        } else {
            this.total_pieces = this.units * this.inbound_item.packed_per;
        }
    },
    watch: {
        units: function() {
            if (this.inbound_item.pieces_in_transfer > 0) {
                this.total_pieces = this.inbound_item.pieces_in_transfer / this.total_locations;
            } else {
                this.total_pieces = this.units * this.inbound_item.packed_per;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
 .foundLocations {
            display: flex;
            gap: 1rem;
            text-align: center;
            width: max;
            align-items: center;

            input {
                width: 6.5rem;
                margin-right: 0.5rem;
                padding: 1.5rem 0.5rem !important;
            }

            .locationName {
                font-weight: bold;
            }
           
        }
</style>