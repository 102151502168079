<template>
    <div class="product__box_size">
        <h4 class="product__box_size--title"><img src="../../assets/icons/delivery_icons/A01.svg" :title="trx.orders['Box size']"></h4>
        <!-- <p class="product__box_size--value" v-if="product.ship_box_id" > {{product.ship_box_id.name}}</p> -->
        <div class="product__box_size__select">
          <v-select v-if="shipment_boxes_list && shipment_boxes_list.length > 0" v-model="product.ship_box_id" @input="setShipBox({product_id: product._id, ship_box_id: product.ship_box_id})" :options="shipment_boxes_list" label="name">
            <template slot="selected-option" slot-scope="option" >
                <div style="display: flex; align-items: baseline">
                    <strong style="margin-right:2px">{{ option.name }} </strong>   € {{ option.package_cost }}
                </div>
            </template>
            <template slot="option" slot-scope="option" >
              <div class="flex"> 
                  <p class="flex-60">{{option.label}}</p>
                  <div class="flex-2"> </div>
                  <small v-if="option?.price > 0"  class="flex-20" style="justify-self: flex-end; text-align: right;">
                      € {{ option.price }}
                  </small>
              </div>
            </template>    
          </v-select>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-debugger */


import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SelectShipBox',
  computed: {
        ...mapGetters(['shipment_boxes_list' ])
  },
  methods: {
      ...mapActions(['setShipBox', 'fetch_shipment_boxes_list'])
  },
  created() {
    if (this.shipment_boxes_list == null ) {
      this.fetch_shipment_boxes_list()
    }
  },
  components: {

  },
  props: {
    product: Object,
  },
  data () {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>
   .product__box_size {
      
     position: relative;

     &--title {
       position: absolute;
       width: 5rem;
       z-index: auto;
       left: -3.8rem;
       display: flex;
       align-content: center;
       align-items: center;
       justify-content: center;
       height: 4rem;
       border-top-left-radius: var(--border-radius);
       border-bottom-left-radius: var(--border-radius);
       background-color: var(--color-primary-light);
       img {
        width: 3.4rem;
       }
     }

     .v-select.vs--single.vs--searchable {
      border-radius: 0px;
     }
     
     &--value {
          position: absolute;
          top: 3.7rem;
          left:2rem;
     }
   }
</style>


    