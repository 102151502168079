<template>
  <div class="inventory-form">
        <vue-form-generator :schema="schema" :model="FormModel" :options="formOptions"></vue-form-generator>
  </div>
</template>

<script>
import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import trx from '@/translations';

Vue.use(VueFormGenerator)

export default {
  name: 'inventory-form',
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  props: {
    submit: Function,
    submitNmae: String,
    model: {},
    AddProducts: Array
  },
  data () {
    return {
      trx,
      FormModel: this.model,
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Contactpersoon',
            placeholder: 'John Doe',
            model: 'contact_person',
            required: true,
            validateAfterChanged: true,
            validateBeforeSubmit: true,
            validator: VueFormGenerator.validators.string
          },
          {
            type: 'textArea',
            label: trx.shipments["Shipment Address"],
            model: 'shipping_address',
            rows: 4,
            placeholder: 'Shipment Address \nVoorbeeldadres 14 \n3829AX Amsterdam',
            required: true,
            validateAfterChanged: true,
            validateBeforeSubmit: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'text',
            label: 'Track & Trace (url)',
            placeholder: '',
            model: 'tack_trace',
          },{
            type: 'input',
            inputType: 'datetime',
            label: 'Verzenddatum',
            placeholder: '',
            model: 'sended',
          },{
            type: 'input',
            inputType: 'datetime',
            label: 'Verwachte leverdatum',
            placeholder: '',
            model: 'expected_delivery',
          },{
            type: 'input',
            inputType: 'email',
            label: 'Contact person email',
            placeholder: 'mail@exaple.com',
            model: 'contact_person_email',
            required: true,
            validateAfterChanged: true,
            validateBeforeSubmit: true,
            validator: VueFormGenerator.validators.email

          },{
            type: 'input',
            inputType: 'text',
            label: 'Phone',
            placeholder: '+31 (0) 85 0609 250',
            model: 'phone',
            
          },{
            type: 'Submit',
            buttonText: this.submitNmae,
            onSubmit: this.submit,
            validateAfterChanged: true,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
   .inventory-form {
     @include box-bg-dash;
     padding:2rem;
     margin-top: 2rem;
   }
</style>
