<template>
	<div class="selected-order-container">
		<img :src="order_product.img" :alt="order_product.title" />
		<div class="selected-order-quantity">
			<p>{{ order_product.quantity }}</p>
		</div>
	</div>
</template>
<script>

export default {
	name: "SelectedOrder",
	props: {
		order_product: Object,
	},
}
</script>
<style lang="scss" scoped>
.selected-order {

	&-container {
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: 12rem;
		min-width: 12rem;
		flex-basis: 20%;
		overflow: hidden;

		img {
			width: auto;
			height: 100px;
			object-fit: contain;
		}
	}

	&-quantity {
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem;
		background-color: white;
		border-radius: 100%;
		height: 3rem;
		width: 3rem;
		border: 1px solid var(--color-primary);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>