<template>
   <router-link class="sub-menu-item" :to="{ path:link }" >
        <!-- <img :src="require('@/assets/icons/' + icon + '.svg')" /> -->
        <p> {{name}} </p>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
   </router-link>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
      name: String,
      icon: String,
      link: String,
      state: String
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.sub-menu-item {
    padding: 2rem 2rem;
    display: flex;
    border-radius: var(--border-radius-box);
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    margin: 1rem 0rem;
    background: var(--color-primary-light);
    &.router-link-active {
        background: var(--color-primary-dark-o);
        border-left: 5px solid var(--color-primary-light);
        // margin-left: -2rem;
        // margin-right: -2rem;
    }
    p {
        color: var(--color-white);
        font-size: 1.7rem;
    }
    svg {
        width: 2rem;
        height: auto;
        color: var(--color-white);
    }
    &:hover {
        background: var(--color-primary);   
    }
}
</style>
