<template>
    <div>
        <span class="expand-icon" v-on:click="expanded = !expanded">ᐅ</span> {{ moment(node.month).format('MMM-YY')}}
        <div class="expanded" v-if="expanded">
            <div class="" v-for="i in node.detail_lines" v-bind:key="i.d_id">
                <table>
                    <tbody>
                        <tr>
                            <td v-if="i.d_id">{{ i.d_id }}</td>
                            <td v-if="i.d_count">{{ i.d_count }}</td>
                            <td v-if="i.expected">{{  moment(i.expected).format("DD-MM-YYYY") }}</td>
                            <td v-if="i.coli">{{ i.coli }}</td>
                            <td v-if="i.checked_in">{{ i.checked_in }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script>

  import trx from '@/translations';
  import moment from "moment";

  /* eslint-disable no-debugger */
  
  export default {
    name: 'ExpandItem',
    created() {
      
    },
    props: {
        node: Object
    },
    data () {
      return {
        trx,
        moment,
        expanded: false,
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  
  .expand-icon {
    cursor: pointer;
  }

</style>