<template>
    <div class="number-field">
      <button @click="decrement" aria-label="Decrease value">-</button>
      <!-- Handle null or undefined values with the || operator -->
      <input
        type="text"
        :value="valueToString"
        @focus.prevent="blurInput"
        readonly
      />
      <button @click="increment" aria-label="Increase value">+</button>
    </div>
  </template>
  
  <script>
  export default {
    props: ['value'], // Accepts value for v-model
    computed: {
      // Use a computed property to handle the value conversion
      valueToString() {
        // Check if value is null or undefined before calling toString()
        return this.value !== null && this.value !== undefined ? this.value.toString() : '';
      }
    },
    methods: {
      increment() {
        const newValue = this.value + 1;
        this.$emit('input', newValue);
      },
      decrement() {
        const newValue = Math.max(this.value - 1, 0); // Prevent negative values if needed
        this.$emit('input', newValue);
      },
      blurInput(event) {
        event.target.blur();
      },
    },
  };


  </script>

  <style scoped lang="scss">
  
  .number-field {
    display: flex;
    max-width: 320px;
    margin: 0 auto;
    button {
        flex: 0 0 25%;
        min-width: 3rem;
        font-size: 3rem;
        font-weight: 800;
        border-radius: 0px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        line-height: 1;
        &:first-child {
           border-top-left-radius: var(--border-radius);
           border-bottom-left-radius: var(--border-radius);
        } 
        &:last-child {
           border-top-right-radius: var(--border-radius);
           border-bottom-right-radius: var(--border-radius);
        }
    }
    input {
        flex: 1 0 50%;
        font-size: 3rem;
        font-weight: 800;
        justify-self: center;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        text-align: center;
        padding: 1rem;
        border: 1px solid var(--color-primary-dark);
        border-radius: 0px
    };
  }

  </style>
  