<template>
  <div class="stock-analysis">
   <h1>{{ trx.stock_analysis.analysis }}</h1>
      <div class="big-container flex">
         <div v-if="stock_analysis.i" class="small-container small-container-1 flex-25">
          <h3>{{ trx.stock_analysis.checked_in }}</h3>
  
          <table class="table-m">
            <thead>
              <tr>
                <th>{{ trx.stock_analysis.date }}</th>
                <th>{{ trx.stock_analysis.amount }}</th>
              </tr>
            </thead>
  
            <tbody>
              
              <tr v-for="im in stock_analysis.i.monthlyData" v-bind:key="'_im'+im.month">
                <td>
                  <ExpandItem v-bind:key="im.month+'_ex'" :node="im" />
                </td>
                <td>{{im.total_checked_in}}</td>
              </tr>
            </tbody>
          </table>
          <p class="total">{{stock_analysis.i.total}}</p>
        </div>
  
        <div v-if="stock_analysis.r" class="small-container small-container-2 flex-25">
          <h3>{{ trx.stock_analysis.retours }}</h3>
  
          <table class="table-m">
            <thead>
              <tr>
                <th>{{ trx.stock_analysis.period }}</th>
                <th>{{ trx.stock_analysis.amount }}</th>
              </tr>
            </thead>
  
            <tbody>
              <tr v-for="rm in stock_analysis.r.monthlyData" v-bind:key="'_rm'+rm.month">
                <td>
                  <ExpandItem v-bind:key="rm.month+'_ex'" :node="rm" />
                </td>
                <td>{{ rm.total }}</td>
              </tr>
            </tbody>
          </table>
          <p>{{stock_analysis.r.total}}</p>
        </div>
  
        <div v-if="stock_analysis.o" class="small-container small-container-3 flex-25">
          <h3>{{ trx.stock_analysis.orders }}</h3>
          <table class="table-m">
            <thead>
              <tr >
                <th>{{ trx.stock_analysis.period }}</th>
                <th>{{ trx.stock_analysis.amount }}</th>
              </tr>
            </thead>
  
            <tbody>
              <tr v-for="om in stock_analysis.o.monthlyData" v-bind:key="'_om'+om.month">
                <td>
                  <ExpandItem v-bind:key="om.month+'_om'" :node="om" />
                </td>
                <td>{{om.total}}</td>
              </tr>
            </tbody>
          </table>
          <p>{{stock_analysis.o.total}}</p>
        </div>
  
        <div class="logische-voorraad flex-20">
          <h3>{{ trx.stock_analysis.logical_stock }}</h3>
          <p>{{stock_analysis.total_stock}}</p>
        </div>
      </div>
  </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment'
  import ExpandItem from './Stock_analysis/item';
  import trx from "@/translations"
  /* eslint-disable no-debugger */

  export default {
      name: 'StockAnalysis',
      props: {
        ean: String,
        org_id: String,
      },
      computed: {
          ...mapGetters([ 'stock_analysis']),
          sort_date(data) {
            const sorted_data = data.sort((a,b) => new moment(a.month).format('YYYYMMDD') - new moment(b.month).format('YYYYMMDD'))
            return sorted_data;
          }
      },
      methods: {
          ...mapActions(['fetch_stock_analysis']),
          
      },
      components: {
        ExpandItem
      },
      created() { 
        this.fetch_stock_analysis({ ean: this.ean, org_id: this.org_id });
      },
      data() {
        return {
          moment,
          trx
        }  
      }
  
      
  }
  </script>
  
  <style lang="scss">
  
  .big-container {
    gap: 1.875rem;
    justify-content: flex-start;
    align-items: flex-start !important;
    align-content: stretch !important;
  }
  
  .small-container {
    flex-direction: column;
    border: 0.5px solid #124d09;
    border-radius: 1.875rem;
    margin: 0 !important;
    padding: 0 !important;
  
    h3 {
      text-align: center;
      font-size: 1.7rem;
      padding: 10px 0 20px 0;
    }
  
    p {
      text-align: center;
      border-radius: 0 0 15px 15px;
      background-color: #6b6b6b;
      color: #ffffff;
      padding: 0.5rem;
    }
  }
  
  .logische-voorraad {
    border: 0.5px solid #124d09;
    text-align: center;
    height: 100%;
    border-radius: 30px;
    border-radius: 1.875rem;
  }
  
  .logische-voorraad {
    h3 {
      background-color: var(--color-primary);
      font-size: 1.7rem;
      color: var(--color-white);
      padding: 1rem;
      border-radius: 1.875rem 1.875rem 0 0;
    }
    p {
      font-weight: bold;
      font-size: 3.5rem;
      padding: 0.5rem;
    }
  }
  
  </style>