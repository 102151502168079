<template>
  <div v-if="authCheck('/user/orders/fulfilled', 'GET', user.curr_role)" :class="'stp_wizard__conn ' + 'stp_wizard__conn__' + active">
    <div v-on:click="toggle_orders()" :class="'question_mark ' + active"> {{ active ? '►' : '📓' }}</div>
    <div :class="'stp_wizard ' + active">
      <h3>Notification center</h3>
      <p>Dit zijn de orders die je recent hebt verwerkt</p>
      <br>
      <br>
      <div class="info__conn">
        <OrderItem v-for="oi in fulfilled_orders" v-bind:key="oi._id" :node="oi" />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrderItem from '../../views/Shipments/order_item.vue';
import authCheck from '@/lib/authCheck';

export default {
  name: 'Notification_center',
  props: {
    msg: String,
    current: String,
  },
  computed: {
    ...mapGetters(['fulfilled_orders', 'user']),
  },
  methods: {
    ...mapActions(['get_fulfilled_orders']),
    authCheck,
    toggle_orders() {

      this.active = !this.active;
      if (this.active) {
        this.get_fulfilled_orders();
        window.document.querySelectorAll(".main")[0].style.minWidth = 'calc(93vw - 50rem)'
        window.document.querySelectorAll(".main")[0].style.maxWidth = 'calc(93vw - 50rem)'
        window.document.querySelectorAll(".main")[0].style.marginRight = '50rem'
      } else {
        window.document.querySelectorAll(".main")[0].style.minWidth = '93vw'
        window.document.querySelectorAll(".main")[0].style.marginRight = '0rem'
      }
    }
  },
  created() {

  },
  components: {
    OrderItem
  },
  data() {
    return {
      active: false
    }
  }
}
</script>

<style lang="scss" scoped>

.info {
  &__conn {
    height: 80vh;
    overflow: auto;
  }
}
.stp_wizard__conn {
  z-index: 999;
  position: relative;

}

.question_mark {
  transition: 0.1s linear;
  position: absolute;
  padding: 1rem 0.8rem;
  right: 0rem;
  font-size: 2rem;

  @media all and (max-width: $bp-medium) {
    display: none;
  }

  &.true {
    right: 50rem !important;
  }

  top:4rem;
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-white);
  border-radius: 10px 0px 0px 10px;
  background: var(--color-primary);
  cursor: pointer;
}

.stp_wizard {
  overflow: auto;
  transition: 0.1s linear;

  h3,
  h1,
  p {
    color: var(--color-white);
  }

  h3 {
    margin-bottom: 2rem;
  }

  height: 100vh;
  width: 0px;
  display: none;

  &.true {
    padding: 5rem;
    display: block;
    width: 50rem !important;
  }

  background: var(--color-primary-light);
  position: absolute;
  right: 0;
  top:0;

  border-radius: 50px 0px 0px 50px;
}
</style>