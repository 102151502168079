// shipments.js
import api from '@/api';
/* eslint-disable no-debugger */

const state = {
  s_reslt: [],
  perfm_d: [],
};

const getters = {
  s_reslt: state => state.s_reslt,
  perfm_d: state => state.perfm_d,
};

const actions = {
  
  async s_shipmt({ commit }, query) {
    try {
      const response = await api.get(`/orders/search/${query}`);
      debugger;
      commit('set_search_results', response.data);
    } catch (error) {
      console.error('Error searching shipments:', error);
    }
  },
  
  async f_shimpt_perf({ commit }, model) {
    try {
      const response = await api.get(`/orders/perform/${model.startDate}/${model.endDate}`);
      commit('set_performance_data', response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching shipment performance data:', error);
    }
  },
  
};

const mutations = {
  set_search_results: (state, results) => (state.s_reslt = results),
  set_performance_data: (state, data) => (state.perfm_d = data),
  // Define other mutations for managing shipments here
};

export default {
  state,
  getters,
  actions,
  mutations,
};