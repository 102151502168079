<template>
  <div class="users-wrp">
        <h3>{{trx.admin.ship_boxes.name}}</h3>
       
        <div class="table_controles">
            <div class="table_controles__btns">
                <router-link v-if="!$route.path.includes('add')" :to="{path:'/admin/shipment_box/add'}" class="button">
                        + {{trx["Add"]}}
                </router-link>
            </div>
            
            <div class="add_container" v-if="$route.path.includes('add') > 0" >
                <router-view ></router-view>
            </div>
            <div class="import_container" v-if="$route.path.includes('import') > 0" >
                <router-view></router-view>
            </div>
        </div> 

        <div v-if="shipment_boxes !== (null || undefined)" class="users table table__5">
             <div class="table__row header"> 
                <div class="user__name col-1">{{trx.common.name}}</div>
                <div class="user__name col-2">{{trx.admin.ship_boxes.max_size}}</div>
                <div class="user__email col-2">{{trx.admin.ship_boxes.package_cost}}</div>
                <div class="user__email col-2">{{trx.admin.ship_boxes.purchase_cost}}</div>
                <div class="user__licence col-3">{{trx.admin.ship_boxes.package_code}}</div>
                <div class="user__verified col-2">{{trx.admin.ship_boxes.fulfillment_cost}}</div>
                <div class="user__controls col-2"><img :src="require('@/assets/icons/settings.svg')"> </div>
            </div>
            

            <div v-for="x in shipment_boxes" v-bind:key="x._id" class="table__row user"> 
                <div class="user__name col-1" contenteditable>{{x.name}}</div>
                <div class="user__name col-2" contenteditable>{{x.max_size.L}} x {{x.max_size.W}} x {{x.max_size.H}}</div>
                <div class="user__email col-2" contenteditable>{{x.package_cost}}</div>
                <div class="user__email col-2">{{x.purchase_cost}}</div>
                <div class="user__email col-3" contenteditable>{{x.package_code}}</div>
                <div class="user__email col-2" contenteditable>{{x.fulfillment_cost}}</div>
                <div class="user__licence col-2 ">
                    
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
/* eslint-disable no-debugger */

export default {
  name: 'shipment_boxes',
  computed: {
      ...mapGetters(['shipment_boxes'])
    },
  methods: {
    ...mapActions(['fetchShipment_boxes', ])
  },
  components: {
     
  },
  created() {
      this.fetchShipment_boxes();
  },
  data() {
    return {
      trx,
      getSelectedLicence: (user, plan) => {
          const userLicence = user.licences.filter((x)=> {
              return x.organization_id === this.$store.getters.current_org_auth._id
          })[0];
          if (userLicence) {
            if (userLicence.licence_id === plan.id) {
                return true
            } else {
                return false
            }
          } else {
              return false
          }  
       }
    }
  }
}
</script>

<style lang="scss" scoped>
    h3 {
        margin: 5rem 0rem;
    }

    .totals {
      display: flex;
      justify-content: space-between;
    }

    .table_controles {
         margin: 5rem 0rem;

        .add_container {
            margin-bottom:2rem;
            @include box-bg-dash;
            padding: 2rem;
        }
        &__btns {
            margin:2rem 0rem;
        }
        .button {
           @include button;
            width: 10rem;
            text-align: center;
            font-weight: 600;
            font-size: 1.2rem;
            margin-right: 1rem;
            // color: var(--color-primary-dark);
        }
    }
    
    .users {
        .user__controls {
               img {
                   width: 1rem;
                   height: auto;
               }
        }
        .user {
            &__reset {
                cursor: pointer;
            }
        }
        
    }
</style>
