<template>
  <div class="organization">
        <h3>{{trx.shops['Create a new Shop']}}</h3>
        <OrgsForm :model="model" :submit="createOrg" :submitNmae="trx.create" />       
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrgsForm from '../Orgs/from';
import trx from '@/translations';

export default {
  name: 'Shops',
  methods: {
    ...mapActions(['fetch_curr_org_details', 'createOrg'])
  },
  computed: mapGetters(['current_org']),
  components: {
    OrgsForm
  },
  created() {
    this.fetch_curr_org_details();
  },
  data () {
    return {
      trx,
      model: {
        name: '',
        address: {
            line1: ""
        }, 
        contact_person: '',
        contact_person_email: '',
        phone: "",
        branche: "",
        about: "",
        coc_number: "",
        vat_id: "",
      },
    }
  }
}
</script>

<style lang="scss" scoped>
   h3 {
     margin-bottom: 5rem;
   }
   
</style>
