<template>
  <div class="wrapper">
    <h4 class="item-name">
      {{ item.name }}
    </h4>
    <div class="c">
      <div class="c-inner">
        <div class="c-image">
          <img
            class="image"
            v-if="this.item.img_url || this.item.images && this.item.images[0]"
            v-bind:src="
              this.item.img_url
                ? this.item.img_url
                : this.item.images[0].data
                ? `data:image/${this.item.images[0].data.img_ext};base64,${this.item.images[0].data.img_data}`
                : ``
            "
            alt="Product image"
          />
        </div>
        <div class="product">
          <p>
            {{ item.ean }}
          </p>
        </div>
        <div class="amount-selector">
        </div>
      </div>
      <div @click="updateSelectedProduct(item)" class="flex flex-column justify-center add-to-list-btn">
        <div class="add button button-right">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.5 27C34.5 28.125 34.185 29.19 33.63 30.09C33.315 30.63 32.91 31.11 32.445 31.5C31.395 32.445 30.015 33 28.5 33C26.67 33 25.035 32.175 23.955 30.885C23.925 30.84 23.88 30.81 23.85 30.765C23.67 30.555 23.505 30.33 23.37 30.09C22.815 29.19 22.5 28.125 22.5 27C22.5 25.11 23.37 23.415 24.75 22.32C25.785 21.495 27.09 21 28.5 21C30 21 31.35 21.54 32.4 22.455C32.58 22.59 32.745 22.755 32.895 22.92C33.885 24 34.5 25.425 34.5 27Z"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M30.735 26.97H26.265"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M28.5 24.78V29.265"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.75488 11.1599L17.9999 18.8249L31.1549 11.2049"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 32.4151V18.8101"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M32.415 13.755V22.2451C32.415 22.3201 32.415 22.38 32.4 22.455C31.35 21.54 30 21 28.5 21C27.09 21 25.785 21.495 24.75 22.32C23.37 23.415 22.5 25.11 22.5 27C22.5 28.125 22.815 29.19 23.37 30.09C23.505 30.33 23.67 30.555 23.85 30.765L21.105 32.2801C19.395 33.2401 16.605 33.2401 14.895 32.2801L6.88496 27.84C5.06996 26.835 3.58496 24.3151 3.58496 22.2451V13.755C3.58496 11.685 5.06996 9.16503 6.88496 8.16003L14.895 3.72C16.605 2.76 19.395 2.76 21.105 3.72L29.115 8.16003C30.93 9.16503 32.415 11.685 32.415 13.755Z"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductItem",
  data() {
    return {
      amount: null,
			per: null,
    };
  },
  props: {
    item: Object,
    updateData: Function,
    updateSelectedProduct: Function,
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  box-shadow: var(--shadow-light);
  border-radius: 0.77rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.item-name {
  background-color: var(--color-grey-light-3);
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.c {
  display: flex;
  gap: 2rem;

  &-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
  }

  &-image {
    min-height: 8rem;
    max-height: 8rem;
    min-width: 8rem;
    max-width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input {
  text-align: center;
  font-family: inherit;
  padding: 0;
  width: 10rem;
  padding: 0.5rem 0.25rem;
  border-radius: var(--border-radius-box);
  box-shadow: var(--shadow-light);
  margin: 0;
  margin-right: 0;
  font-size: 1.5rem;
  font-weight: 200;
  color: var(--color-primary-dark);
  border: 0px;
  transition: all 0.1s ease;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.product {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.amount-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.button {
  @media screen and (max-width: $bp-medium) {
    padding: 0.5rem 1rem;
  }
}

.image {
  height: auto;
  max-height: 75px;
  width: 50px;
  object-fit: contain;
}

.add {
  background-color: transparent;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  svg {
    stroke: var(--color-primary);
  }
}

.add-to-list-btn {
  padding: 1rem;
}

.add:hover {
  background-color: transparent;
}

.input_label {
	display: flex;
	flex-direction: column;
}
</style>
