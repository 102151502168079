<template>
    <div :class=" $route.path.includes(link) ? 'step active': 'step'">
      <div class="flex"> 
          <div class="arrow"></div>
          <div class="flex-15">
              <router-link :to="link">
                  <div class="step__circle">
                      <h1>{{step_number}}</h1>
                  </div>
              </router-link>
              
          </div>
          <div class="flex-85">
              <router-link :to="link">
                  <h4>{{title}}</h4>
              </router-link>
              <p v-if="$route.path.includes(link)">{{description}}</p>
              <br>
              <router-link v-if="$route.path === link && next_link" class="step__link btn" :to="next_link">
                  {{button_text}}
              </router-link>
              <a  v-if="link_button && $route.path === link && next_link" class="link_button" :href="link_button">{{ link_button_text }}</a>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Setup_wizzard',
    props: {
      title: String,
      step_number: String,
      description: String,
      link: String,
      next_link: String,
      button_text: String,
      link_button: String,
      link_button_text: String
    }
  }
  </script>
  
  <style lang="scss" scoped>

    .link_button {
        color: var(--color-primary);
        background: var(--color-white);
        padding: 1rem 2rem;
        font-size: 1.3rem;
        margin-left: 1rem;
        border-radius: var(--border-radius-box);
        position: relative;
    }
      .step {
          &.active {
              margin-bottom: 2rem;
              .arrow {
                  position: absolute;
                  width: 2px;
                  height: 100%;
                  top: 2rem;
                  left: 1.3rem;
                  border: 1px dashed var(--color-primary)
              }
          }
          &:not(.active) {
              height: 5rem;
              .arrow {
                  position: absolute;
                  width: 2px;
                  height: 100%;
                  top: 2rem;
                  left: 1.3rem;
                  border: 1px solid var(--color-white)
              }
          }
          position: relative;
          
          &__link {
              background: var(--color-primary);
              padding: 1rem 3rem;
              border-radius: var(--border-radius-box);
              font-size:12px;
              color: var(--color-white);
              &:hover {
                  background: var(--color-primary-mid);
              }
          }
          &__circle {
              h1 {
                  font-weight: 400;
                  font-size: 1.8rem;
              }
              position: absolute;
              top: 1px;
              width: 3rem;
              height: 3rem;
              display: flex;
              justify-content: center;
              align-content: center;
              align-items: center;
              background: var(--color-white);
              border-radius: 50%;
          }
          h4 {
              color: var(--color-white);
              font-weight: 400;
          }
          h3, p {
              color: var(--color-black);
          } 
      }
  </style>