<template>
  <div class="newProduct-section">
    <h1>{{ trx.products.create }}</h1>


    <br>
    <br>

    <div class="container--inputs">
      <label>{{ trx.products.ProductName }}</label>
      <input v-model="finalImportData[0].Product_name">

      <label>{{ trx.products.ean }}</label>
      <input v-model="finalImportData[0].barcode_sku_ean">

      <label>{{ trx.products.note }}</label>
      <input v-model="finalImportData[0].note" @keyup.enter="new_product(finalImportData)">

      <div class="customs" v-on:click="customs_expanded = !customs_expanded">
        <h3>{{ trx.products.customs.customs }}</h3>
        <p>{{ this.customs_expanded ? trx.condense : trx.expand }}</p>
      </div>
      <div v-if="customs_expanded">
        <label>{{ trx.products.customs.hs }}</label>
        <input v-model="finalImportData[0].hs">

        <label>{{ trx.products.customs.weight_kg }}</label>
        <input v-model="finalImportData[0].weight_kg">
        
        <label>{{ trx.products.customs.value }}</label>
        <input v-model="finalImportData[0].value" @keyup.enter="new_product(finalImportData)">
      </div>

    </div>

    <!-- <button v-on:click="new_product(finalImportData)" class="btn--product-import">
      Afronden en product toevoegen
    </button> -->
    <button v-on:click="validate_new_product({ finalImportData: finalImportData }, new_product)"
      class="btn--product-import">
      Afronden en product toevoegen
    </button>
  </div>
</template>

<script>
import trx from '@/translations';
import api from "@/api";
import error from '@/error'


export default {
  name: 'NewOrder',
  methods: {
    async new_product() {
      await api.post("/products/import", this.finalImportData)
      this.$router.go(-1)
    },

    validate_new_product: (model, action) => {

      if (!model.finalImportData[0].Product_name) {
        error(trx.validation_trx.add_product_name)
        return
      }

      if (!model.finalImportData[0].barcode_sku_ean) {
        error(trx.validation_trx.add_ean)
        return
      }

      if (model.finalImportData[0].barcode_sku_ean.length != 13) {
        error(trx.validation_trx.invalid_EAN_input)
        return
      }
      action(model)
    }
  },

  data() {
    return {
      trx,
      customs_expanded: false,
      Product_name: null,
      barcode_sku_ean: null,
      finalImportData: [{}],
    }
  }
}

</script>

<style lang="scss" scoped>
.newProduct-section {
  max-width: 55rem;
}

.btn--product-import {
  margin-top: 5rem;
}

.customs {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 3rem;
}
</style>
