<template>
	<div class="form-container">
		<form>
			<label for="company">{{ trx.orders.company }}</label>
			<input id="company" type="text" placeholder="Voorbeeldbedrijf BV" v-model="shipment_details.company" name="company" />
			<label for="fname">{{trx.orders.name}} *</label>
			<input id="fname" type="text" placeholder="Jan"  v-model="shipment_details.name" name="name" required />
			<label for="surename">{{ trx.orders.surname }} *</label>
			<input id="surename" type="text" placeholder="Jansen" v-model="shipment_details.surename" name="surname" required />
			<label for="country">{{ trx.orders.country }} *</label>
			<select id="country" v-model="shipment_details.countryCode" name="country" required>
				<option v-for="value in countries" :key="value.value" :value="value.value">{{ value.name }}</option>
			</select>
			<label for="postalcode">{{ trx.orders.postalcode }} *</label>
			<input id="postalcode" type="text" placeholder="1234 AB" v-model="shipment_details.postalcode" name="postalcode" required />
			<label for="place">{{ trx.orders.place }} *</label>
			<input id="place" type="text" placeholder="Amsterdam" v-model="shipment_details.city" name="city" required />
			<label for="street">{{ trx.orders.streetname }} *</label>
			<input id="street" type="text" placeholder="Hoofdstraat" v-model="shipment_details.streetname" name="street" required />
			<div class="input-seperator">
				<div>
					<label for="housenumber">{{ trx.orders.housenumber }} *</label>
					<input id="housenumber" type="number" placeholder="42" v-model="shipment_details.housenumber" name="housenumber" required />
				</div>
				<div>
					<label for="addition">{{ trx.orders.addition }}</label>
					<input id="addition" type="text" placeholder="A" v-model="shipment_details.houseNumberExtension" name="housenumberextension" />
				</div>
			</div>
			<label for="email">{{ trx.Email }} *</label>
			<input id="email" type="email" placeholder="voorbeeld@email.com" v-model="shipment_details.email" name="email" required />
			<label for="phone">{{ trx.orders.phone }}</label>
			<input id="phone" type="tel" placeholder="+31612345678" v-model="shipment_details.phone" name="phonenumber" required />
		</form>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import trx from "@/translations";

export default {
  name: "OrderForm",
	props: {
		updateStep: Function,
	},
	data() {
		return {
			trx,
		}
	},
	computed: {
		...mapGetters(['possible_addresses', 'shipment_methods_list', 'shipment_details', 'countries']),
	},
	methods: {
		...mapActions(['searchAddress', 'setAddress', 'fetch_shipment_methods_list', 'fetch_countries']),
	},
	created() {
		this.fetch_shipment_methods_list();
		this.shipment_details.countryCode = this.shipment_details.countryCode ? this.shipment_details.countryCode : 'NL';
		if (this.countries.length === 0) {
			this.fetch_countries();
		}
	},
};
</script>
<style lang="scss" scoped>
.form-container {
	padding-bottom: 25rem;
}

.input-seperator {
	display: flex;
	justify-content: space-between;
	div {
		width: 48%;
	}
}
</style>