<template>
<div>
    <div class="box">
        <p>Product</p>
        <p>{{ product.name !== (null || undefined) ? product.name : '' }}</p>
    </div>
    <div class="boxesNextToEachother">
        <div class="box" v-if="product.org[0].name">
            <p>Webwinkel/organisatie</p>
            <p>{{ product.org[0].name !== (null || undefined) ? product.org[0].name : ''}}</p>
        </div>
        <div class="box">
            
            <div class="product__settings flex">
                <div class="flex flex-30">
                    <p>EAN: {{ product.ean !== (null || undefined) ? product.ean : '' }} </p><br>
                </div>
                <div class="flex flex-30 hidden-md">
                    <input class="flex-10 hidden-md" type="checkbox" :id="'send_track_trace_email'+product._id" v-model="product.track_trace_email" v-on:change="setEmail({product_id: product._id, track_trace_email: product.track_trace_email })">
                    <label  class="flex-80 hidden-md" :for="'send_track_trace_email'+product._id"> Email track trace</label>
                </div>
               
            </div>
        </div>
    </div>

    <div class="boxesNextToEachother">
        <div class="box">
            <p>verzendwijze</p>
            <p>{{ product.def_shipping_id && product.def_shipping_id.name !== (null || undefined) ? product.def_shipping_id.name : '' }}</p>
        </div>
        <div class="box">
            <p>Verwerk in bol als (Beta)</p>
            <select v-model="product.bol_confirm" v-on:change="set_bol_confirm({product_id: product._id, bol_confirm: product.bol_confirm })">
                <option :selected="product.bol_confirm === 'TNT' ? true : false" value="TNT">TNT</option>
                <option :selected="product.bol_confirm === 'TNT-EXTRA' ? true : false" value="TNT-EXTRA">TNT-EXTRA</option>
                <option :selected="product.bol_confirm === 'TNT_BRIEF' ? true : false" value="TNT_BRIEF">TNT_BRIEF</option>
                <option :selected="product.bol_confirm === 'OTHER' ? true : false" value="OTHER">OTHER</option>
            </select>
        </div>
        <div class="box">
            <p>Verpakking</p>
            <p>{{ product.ship_box_id && product.ship_box_id.name !== (null || undefined) ? product.ship_box_id.name : ''}}</p>
        </div>
    </div>

    <div class="boxesNextToEachother">
        <div class="box">
            <p>Lead time (days)</p>
            <input type="number" v-model="product.lead_time_days" v-on:change="setLeadTime({product_id: product._id, data: product.lead_time_days})">
        </div>
        <div class="box">
            <p>Ship time (days)</p>
            <input type="number" v-model="product.ship_time_days" v-on:change="setShipTime({product_id: product._id, data: product.ship_time_days})">
        </div>
    </div>

    <div class="boxesNextToEachother">
        <div class="box">
            <p>Pick notitie</p>
            <textarea v-model="product.pick_note" v-on:change="setPickNote({product_id: product._id, data: product.pick_note})"></textarea>
        </div>
        <div class="box">
            <p>pack notities</p>
            <textarea v-model="product.pack_note" v-on:change="setPackNote({product_id: product._id, data: product.pack_note})"></textarea>
        </div>
    </div>

    <div class="boxesNextToEachother">
    <div class="box">
        <p>Notitie</p>
        <textarea v-model="product.note" v-on:change="setNote({product_id: product._id, data: product.note})"></textarea>
    </div>
    <div class="box">
        <p>Retour instructie</p>
        <textarea v-model="product.return_instruction" v-on:change="setReturnInstruction({product_id: product._id, data: product.return_instruction})"></textarea>
    </div>
    </div>

    <div class="boxesNextToEachother">
        <div class="box" v-if="product.surcharges.length > 0">
            <p>Toeslagen</p>
            <p v-for="surcharge in product.surcharges" :key="surcharge._id">{{ surcharge.name && surcharge.price !== (null || undefined) ? surcharge.name + ' ' + '(' + '€' + surcharge.price + ')' : ''}}</p>
        </div>
        <!-- TODO: Implement the following commented code -->
        <!-- <div class="box" v-if="classificatieGevaarlijkeStoffen !== (null || undefined)">
            <p>Classificatie van gevaarlijke stoffen</p>
            <p></p>
        </div> -->
    </div>
    <!-- <div class="boxesNextToEachother">
        <div class="box" v-if="gewicht !== (null || undefined)">
            <p>Gewicht (kg)</p>
            <p></p>
        </div>
        <div class="box" v-if="lengte !== (null || undefined)">
            <p>Lengte (cm)</p>
            <p></p>
        </div>
    </div>
    <div class="boxesNextToEachother">
        <div class="box" v-if="breedte !== (null || undefined)">
            <p>Breedte (cm)</p>
            <p></p>
        </div>
        <div class="box" v-if="hoogte !== (null || undefined)">
            <p>Hoogte (cm)</p>
            <p></p>
        </div>
    </div> -->
</div>
</template>

<script>
import { mapActions } from 'vuex';
 

export default {
    name: "ProductItemProductInformation",
    props: {
        product: Object,
    },
    methods: {
        ...mapActions(['setPickNote', 'setNote', 'setPackNote', 'setLeadTime', 'setShipTime', 'set_bol_confirm', 'setReturnInstruction'])
    }
}
</script>

<style lang="scss" scoped>
.box {
        @include box-bg-white;
        border-radius: 1rem;
    
            p:first-child {
                font-weight: bold;
            }      
    }  
    .boxesNextToEachother {
        display: flex;
        flex-direction: row;
        gap: 1rem;
            div {
                width: 100%;
            }
    }
  
</style>


