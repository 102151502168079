<template>
<div v-if="expanded">
	<h1>Open transfers</h1>
	<div>
		<div v-if="locations.length > 0">
			<div v-for="transfer in locations" :key="transfer._id" class="flex">
				<p>Location: {{ transfer.location_id.parent.name + '-' + transfer.location_id.name }}</p>
				<button @click="$router.push(`/inbound_details/${transfer.inbound_item_id[0]}`)">Go to inbound</button>
				<button @click="undoTransfer({ loc_prod_id: transfer._id })">Remove Transfer</button>
			</div>
		</div>
		<div v-else>
			<p>No open transfers</p>
		</div>
	</div>
</div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
	name: 'transfer_locations',
	props: {
		expanded: Boolean,
		locations: Array,
	},
	methods: {
		...mapActions(['undoTransfer']),
	},
}
</script>