<template>
  <div class="importuser-container">
        <h4>{{trx.users["Import users"]}}</h4>
        <div class="file-upload">
            <label for="file" class="file-custom">{{trx["Select a file to start import"]}}</label>
            <input type="file" id="file" v-on:change="previewFiles()" hidden aria-label="File browser example">
        </div>

        <div class="map-container" v-if="componentKey >= 1"> 
            <div v-for="x in internalFields" v-bind:key="x.name" class="fieldItem">
                <label :for="x.name">{{x.title}}</label> 
                <select class="selectpicker" :name="x.name" :id="x.name" >
                    <option>{{trx["select"]}}</option>
                    <option 
                        v-for="opt in Object.keys(tempData[0])" 
                        v-bind:key="opt[1]"
                        :selected="sim(x.default, opt) > 0.6 ? true : false"
                    > {{opt}} 
                    </option>
                </select>
            </div>

            <button v-on:click="createTable()" class="import-button">
                {{trx["Show preview"]}}
            </button> 
        </div>
        
        <div v-if="componentKey >= 2" :key="componentKey" class="import table data-container">
            <h4>{{trx['Data preview']}}</h4>
            <div class="table__row header"> 
                <div v-for="colum in internalFields" class="col-2" v-bind:key="colum.name">
                    {{colum.title}}
                </div>
            </div>
            <div class="table__rows" style="max-height:300px">
                <div v-for="rows in finalImportData" v-bind:key="rows[0]" class="table__row"> 
                    <div v-for="item in rows" v-bind:key="item[0]" class="col-2" >{{item}}</div>
                </div>
            </div>    
            <button class="import-button" v-if="componentKey >= 2" v-on:click="this.importUser">
                {{trx["Import"]}}
            </button>
        </div>        
  </div>
</template>

<script>

import trx from '@/translations';
import * as XLSX from 'xlsx';
import store from '@/store';
import sim from '../../../lib/similarity.js';
import api from '@/api';
import router from '@/router';

/* eslint-disable no-debugger */

export default {
  name: 'ImportUser',
  methods: {
    createTable() {
        let intDataObj = new Array;
        for (var x = 0; x < this.$store.state.tempData.data.length; x++) {
            let importModel = new Object;
            for (var i = 0; i < this.internalFields.length; i++) {
                const externalValue = document.querySelector(`[name="${this.internalFields[i].name}"]`).value
                let value = this.$store.state.tempData.data[x][externalValue];
                let RenderValue;
                value != undefined || value != null ? RenderValue = value : RenderValue = "";
                importModel[this.internalFields[i].name] = RenderValue;
            }
            intDataObj.push(importModel);
        }
        this.finalImportData = intDataObj;
        this.componentKey += 1;
    }, 
    initImport() {
      this.componentKey += 1;
    },
    async importUser()  {
        alert("start import")
        await api.post('/users', this.finalImportData);
        router.go(-1)
    },
    previewFiles( ) {
        var files = event.target.files, f = files[0];
        var reader = new FileReader();
        const self = this;
        reader.onload = (event) =>  {
            var data = new Uint8Array(event.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
            let sheetName = workbook.SheetNames[0]
            let worksheet = workbook.Sheets[sheetName];
            store.commit('SetTempTableData', XLSX.utils.sheet_to_json(worksheet));
            self.tempData = XLSX.utils.sheet_to_json(worksheet);
            self.initImport();
        };
        reader.readAsArrayBuffer(f);
    },
  },
  computed: {
    
  },
  components: {

  },
  created() {
   
  },
  data () {
    return {
        trx, 
        sim,
        componentKey: 0,
        tempData: [],
        finalImportData: [],
        selected: [],
        internalFields: [{
            title: trx.users["First name"],
            name: "first_name",
            type: "select",
            default: "firstname"    
        },{
            title: trx.users["Last name"],
            name: "family_name",
            type: "select",
            default: "lastname"    
        },{
            title: trx.users["Email"],
            name: "email",
            type: "select",
            default: "email"    
        },{
            title: trx.users["Stakeholder Group"],
            name: "stakeholders_group",
            type: "select",
            default: "Stakeholder Group"
        },{
            title: trx.users["Licence"],
            name: "licence",
            type: "select",
            default: "licence"    
        }]
    }
  }
}
</script>

<style lang="scss" scoped>
    .importuser-container {
        display: flex;
        flex-direction: column; 
        @include box-bg-dash;
        margin:2rem 0rem;
        padding: 2rem 2rem;
        .table {
            margin: 1rem 0rem
            &__row {
                max-height: 300px;
                overflow: auto;
            }
        }
        .map-container {
            @include box-bg-white;
            display: flex;
            flex-wrap: wrap;
            margin: 2rem 0rem;
            >* {
                flex: 0 0 calc(50% - 2rem);
                &:not(:last-child) {
                    margin-right: 2rem;
                }
            }
            .import-button {
                flex: 0 0 100%;
            }
            
        }
        .data-container {
            margin: 2rem 0rem;
            @include box-bg-white;
        }
        .import-button {
            margin:2rem 0rem;
            margin-left:auto;
        }
    }
</style>