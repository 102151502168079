import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import loadingPlugin from './loadingPlugin';


import orgs from './modules/orgs';
import suscr from './modules/subscr';
import orders from './modules/orders';
import shipments from './modules/shipments';
import pick_list from './modules/pick';
import inventory from './modules/inventory';
import inbounds from './modules/inbounds';
import products from './modules/products'; 
import returns from './modules/returns';
import admin from './modules/admin';
import locations from './modules/locations';
import automations from './modules/automations';
import sales from './modules/sales';
import apis from './modules/apis';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tempData: {},
    isLoading: false,
  },
  mutations: {
    SetTempTableData: (state, data) => { 
        state.tempData.data = data
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  plugins: [loadingPlugin],
  actions: {

  },
  modules: {
    auth,
    orgs,
    suscr,
    orders,
    shipments,
    pick_list,
    inventory,
    inbounds,
    products,
    returns,
    admin,
    locations,
    automations,
    sales,
    apis

  }
});
