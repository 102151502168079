<template>
  <div class="signupform-container">
        <h1>{{trx.reset_password}}</h1>
        <div v-if="!$route.query.token">
          Email
          <input v-model="email" :placeholder="trx.users['Please enter your email']">
          <button v-on:click="reset_password({email: email, isReset: !$route.query.token})">{{trx.users.reset}}</button>
        </div>
        <vue-form-generator v-if="$route.query.token" @validated="setValidState" :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
        <router-link :to="'login'" class="form-link">{{trx.users['Know your password?, Login']}} </router-link>
  </div>
</template>

<script>
import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import { mapActions } from 'vuex';
import trx from '@/translations';

Vue.use(VueFormGenerator)

export default {
  name: 'ResetForm',
  methods: {
    ...mapActions(['update_password', 'reset_password']),
  },
  computed: "",
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  
  created() {
   
  },
  data () {
    return {
      trx, 
      model: {
        password: '',
        token: this.$route.query.token,
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'password',
            label: 'Password',
            model: 'password',
            min: 6,
            required: true,
            hint: 'Minimum 6 characters',
            validator: VueFormGenerator.validators.string
          },
          
          {
            type: 'Submit',
            buttonText: trx.Reset,
            onSubmit: this.update_password,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss">

</style>
