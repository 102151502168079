import api from '@/api';
// import router from '@/router';

/* eslint-disable no-debugger */
const state = {
    automations: null,
};

const getters = {
    automations: state => state.automations,
}; 

const actions = {
    async fetch_automations({commit}, model) {
        const response = await api.get("/automations/"+model);
        response !== undefined ? commit('set_automations', response.data) : '';  
    },
    async add_automation({dispatch}, model ) {
        await api.post("/automation/", model);
        dispatch('fetch_automations', model.product_id)
    },
    async edit_automation({dispatch}, model ) {
        await api.put("/automation/", model);
        dispatch('fetch_automations', model.product_id)
    },
    async delete_automation({dispatch}, model) {
        await api.delete("/automations/"+model);
        dispatch('fetch_automations', model.product_id); 
    }
};

const mutations = {
    set_automations: (state, automations) => (state.automations = automations),
};

export default {
  state,
  getters,
  actions,
  mutations
}
