<template>
    <div>
        <span class="expand-icon" v-on:click="expanded = !expanded">ᐅ {{ title }}</span> 
        <div class="expanded" v-if="expanded">
            
            <div class="" >
                <table class="table-m">
                    <tbody>
                        <tr v-for="(value, key, i) in node" :key="i">
                          
                        <td>{{ key }}</td>
                        <td v-if="key === 'report'">
                           <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="td in value.map(d => {
                                return {
                                    // _id: d._id,
                                    product: d.product_id[0].ean,
                                    product_name: d.product_id[0].name,
                                    type: d.type,
                                    date: moment(d.located_datetime).format('DD-MM-YYYY'),
                                    location: d.location_id.parent.name + '-' + d.location_id.name ,
                                }
                            }).sort((a, b) => a.type.localeCompare(b.type))" v-bind:key="td">

                                <td v-for="(tdValue) in td" v-bind:key="tdValue">
                                   <span >
                                    
                                    {{ tdValue }}
                                   </span>
                                    
                                </td>
                           
                            </tr>
                            </tbody>
                           </table>
                        </td>
                        <td v-else-if=" (typeof value === 'object') && ( key !== 'report')">
                            <table>
                            <tbody>
                                <tr v-for="(innerValue, innerKey) in value" :key="innerKey">
                                    <td>{{ innerKey }}</td>
                                    <td>{{ innerValue }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </td>
                       
                        <td v-else>{{ value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script>

  import trx from '@/translations';
  import moment from "moment";
//   import MutationItem from './mutation_item.vue';

  /* eslint-disable no-debugger */
  
  export default {
    name: 'StorageItem',
    created() {
      
    },
    methods: {
        isArrayofObjects(item) {
        // Check if it's an array
            if (!Array.isArray(item)) {
                return false;
            }
            // Check every element to ensure each is an object
            return item.every(element => typeof element === 'object' && element !== null && !Array.isArray(element));
        },
        isObject (item) {
            return typeof item === 'object' && item !== null && !Array.isArray(item);
        },
        flattenData(data) {
            return data.map(item => ({
                    ...item,
                    ...item.data
            }));
        }
    },
    components: {
        // MutationItem
    },
    props: {
        node: {
            type: [Object, Array],
            required: true
        },
        title: String,
    },
    data () {
      return {
        trx,
        moment,
        expanded: false,
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  
  .expand-icon {
    cursor: pointer;
  }

  .date {
    min-width: 120px;
  }

</style>