var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-container"},[_c('div',{class:_vm.selected_products_open
        ? 'slider-open container'
        : 'slider-closed container'},[_c('div',{staticClass:"slider-container"},[_c('div',{staticClass:"slider-items"},[_c('h3',[_vm._v(" "+_vm._s(this.trx.orders.products_in_order)+": "+_vm._s(_vm.checkin_products.length)+" ")]),_c('div',{staticClass:"slider-button",on:{"click":function($event){_vm.selected_products_open = !_vm.selected_products_open}}},[_c('svg',{attrs:{"stroke":"green","width":"25","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.93129 14.43L12.0013 20.5L18.0713 14.43","stroke-width":"2","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12.0015 3.50008L12.0015 20.3301","stroke-width":"2","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})])])])]),(this.selected_products_open)?_c('div',{staticClass:"order-products"},_vm._l((_vm.sorted_checkin_products),function(item){return _c('div',{key:item.product._id},[_c('div',{staticClass:"product_item"},[_c('h6',[_vm._v(_vm._s(item.product.name))]),_c('br'),_c('div',{staticClass:"info_separator"},[_c('div',{staticClass:"global_product_info"},[_c('h5',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.totals))]),_c('br'),_c('div',{staticClass:"flex"},[_c('div',[_c('h6',[_vm._v("EAN")]),_c('input',{attrs:{"readonly":"","disabled":""},domProps:{"value":item.product.ean}})]),_c('div',[_c('h6',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.total_amount))]),_c('input',{attrs:{"readonly":"","disabled":""},domProps:{"value":item.total_amount}})]),_c('div',[_c('h6',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.boxes))]),_c('input',{attrs:{"readonly":"","disabled":""},domProps:{"value":item.total_boxes}})])]),_c('svg',{staticClass:"expand_item_button",style:(_vm.item_expanded === item.product._id
                    ? 'transform: rotate(180deg)'
                    : ''),attrs:{"width":"25","height":"25","viewBox":"0 0 25 25","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.expandItem(item.product._id)}}},[_c('path',{attrs:{"d":"M12.2952 17.4157L6.08945 8.55042C5.7183 8.0202 6.09762 7.29165 6.74484 7.29165H18.2551C18.9023 7.29165 19.2817 8.0202 18.9105 8.55042L12.7048 17.4157C12.6053 17.5579 12.3947 17.5579 12.2952 17.4157Z","fill":"#33363F"}})])]),_c('div',[(item.product.img_url || item.product.images && item.product.images[0])?_c('img',{staticClass:"image",attrs:{"src":item.product.img_url
                    ? item.product.img_url
                    : item.product.images[0].data
                    ? `data:image/${item.product.images[0].data.img_ext};base64,${item.product.images[0].data.img_data}`
                    : ``,"alt":"Product image"}}):_vm._e()])])]),(_vm.item_expanded === item.product._id)?_c('div',_vm._l((item.boxes),function(box){return _c('div',{key:item.product._id + box.units + box.packed_per,staticClass:"product_item_box"},[_c('div',{staticClass:"product_item_box_separator"},[_c('div',{staticClass:"flex"},[_c('div',[_c('h6',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.per_box))]),_c('input',{attrs:{"readonly":"","disabled":""},domProps:{"value":box.packed_per}})]),_c('div',{},[_c('h6',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.boxes))]),_c('input',{attrs:{"readonly":"","disabled":""},domProps:{"value":box.units}})]),_c('div',[_c('h6',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.total_amount))]),_c('input',{attrs:{"readonly":"","disabled":""},domProps:{"value":box.packed_per * box.units}})]),_c('div',[_c('h6',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.batch_number))]),_c('input',{attrs:{"readonly":"","disabled":""},domProps:{"value":box.batchnr ? box.batchnr : '-'}})]),_c('div',[_c('h6',[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.expiration_date))]),_c('input',{attrs:{"readonly":"","type":"date","disabled":""},domProps:{"value":box.expiration_date ? box.expiration_date : '-'}})])]),_c('div',[_c('div',{staticClass:"del_button_container",on:{"click":function($event){return _vm.deleteItem({
                      _id: item.product._id,
                      packed_per: box.packed_per,
                      units: box.units,
                      batchnr: box.batchnr,
                      expiration_date: box.expiration_date,
                    })}}},[_c('svg',{staticClass:"del_button",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L10.5858 12L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L12 10.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L13.4142 12L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071Z"}})])])])])])}),0):_vm._e()])}),0):_vm._e(),(this.selected_products_open && this.checkin_products.length > 0)?_c('div',{staticClass:"order-controls",on:{"click":_vm.toConfirm}},[_c('div',{staticClass:"order-controls-items"},[_c('h2',{staticClass:"send-order-text"},[_vm._v(_vm._s(_vm.trx.checkinv.checkin_product.submit_checkin))]),_c('div',{staticClass:"order-button"},[_c('svg',{attrs:{"stroke":"green","width":"24","height":"24","viewBox":"0 0 25 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.4316 5.92993L20.5016 11.9999L14.4316 18.0699","stroke-width":"2","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M3.50146 12H20.3315","stroke-width":"2","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})])])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }