<template>
  <div class="control-container">
    <div class="back" @click="updateStep(step-1)">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4316 5.92993L20.5016 11.9999L14.4316 18.0699"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.50146 12H20.3315"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
		<div class="next-container">
			<h3>
				{{ this.text }}
			</h3>
			<div class="next" @click="updateStep(step+1)">
				<svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
				>
        <path
				d="M14.4316 5.92993L20.5016 11.9999L14.4316 18.0699"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
        />
        <path
				d="M3.50146 12H20.3315"
				stroke-width="2"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
        />
      </svg>
    </div>
	</div>
  </div>
</template>
<script>
export default {
  name: "StepController",
  props: {
    text: String,
    step: Number,
    updateStep: Function,
  },
};
</script>
<style lang="scss" scoped>
.control-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
	margin-bottom: 8rem;
  width: 100vw;
  position: fixed;
}

.next-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 1rem;

	h3 {
		font-size: medium;
	}

  @media screen and (min-width: $bp-medium) {
    margin-right: 20rem;
  }
}

.back {
	margin-left: 2rem;
	padding: 1.5rem;
	border-radius: 100%;
	background-color: white;
	box-shadow: var(--shadow-dark);
  transition-duration: .3s;
  cursor: pointer;
	svg {
		rotate: 180deg;
		stroke: green;
	}

  @media screen and (min-width: $bp-medium) {
    margin-left: 20rem;
  }
}
.back:hover {
  box-shadow: var(--shadow-light);
}

.next {
	margin-left: 2rem;
	padding: 2rem;
	border-radius: 100%;
	background-color: green;
	box-shadow: var(--shadow-dark);
  transition-duration: .3s;
  cursor: pointer;
	svg {
		stroke: white;
	}
}

.next:hover {
  box-shadow: var(--shadow-light);
}
</style>
