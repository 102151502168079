import api from '@/api';
// import router from '@/router';

/* eslint-disable no-debugger */

const state = {
    shipment_boxes: null,
    shipment_boxes_list: null,
    shipment_methods: null,
    shipment_methods_list: null,
    surcharges: null,
    surcharges_list: null,
    labels: null,
    admin_orders: [],
    cutoff_groups: [],
    admin_transfers: [],
};

const getters = {
    shipment_boxes: state => state.shipment_boxes,
    shipment_boxes_list: state => state.shipment_boxes_list,
    shipment_methods: state => state.shipment_methods,
    shipment_methods_list: state => state.shipment_methods_list,
    surcharges: state => state.surcharges,
    surcharges_list: state => state.surcharges_list,
    labels: state => state.labels,
    admin_orders: state => state.admin_orders,
    cutoff_groups: state => state.cutoff_groups,
    admin_transfers: state => state.admin_transfers
}; 

const actions = {
    
    async fetchShipment_boxes({commit}) {
        const response = await api.get("/shipment_boxes");
        response !== undefined ? commit('setShipmentBoxes', response.data) : '';  
    },
    async fetch_shipment_boxes_list({commit}) {
        const response = await api.get("/shipment_boxes/list");
        response !== undefined ? commit('set_shipment_boxes_list', response.data) : '';  
    },
    async add_package({dispatch}, model ) {
        await api.post("/shipment_boxes/add", model);
        dispatch('fetchShipment_boxes')
    },
    async fetch_shipment_methods({commit}) {
        const response = await api.get("/shipment_methods");
        response !== undefined ? commit('set_shipment_methods', response.data) : '';  
    },
    async fetch_shipment_methods_list({commit}) {
        const response = await api.get("/shipment_methods/list");
        response !== undefined ? commit('set_shipment_methods_list', response.data) : '';  
    },
    async add_shipment_method({dispatch}, model ) {
        await api.post("/shipment_methods/add", model);
        dispatch('fetch_shipment_methods')
    },
    async fetch_surcharges({commit}) {
        const response = await api.get("/surcharges");
        response !== undefined ? commit('set_surcharges', response.data) : '';  
    },
    async fetch_surcharges_list({commit}) {
        const response = await api.get("/surcharges/list");
        response !== undefined ? commit('set_surcharges_list', response.data) : '';  
    },
    async add_surcharge({dispatch}, model ) {
        await api.post("/surcharge/add", model);
        dispatch('fetch_surcharges')
    },
    async select_surcharges({dispatch}, model) {
        await api.post("surcharges/select", model)
        await dispatch('getProducts');
    },
    async get_labels({commit}, model ) {
        const response = await api.post("/labels/", model);
        response !== undefined ? commit('set_labels', response.data) : ''; 
    },
    async search__orderid({ commit }, model) {
        const response = await api.get("/orders/searchOrderId/" + model.orderId );
        response !== undefined ? commit('set_admin_orders', response.data) : '';  
        console.log(response.data)
    },
    async deleteOrder({ commit }, model) {
        const response = await api.delete("/orders/searchOrderId/" + model.orderId);
        commit('set_admin_orders', [])
        if (response.ok) {
            this.$router.push("/admin/search_order")
        }
    },
    async fetch_cutoff_groups({commit}) {
        const response = await api.get("/shipment_methods/groups");
        response !== undefined ? commit('set_cutoff_groups', response.data) : '';  
    },
    async fetch_admin_transfers({commit}) {
        const response = await api.get("/transfers/open/all");
        response !== undefined ? commit('set_admin_transfers', response.data) : '';  
    },
};

const mutations = {
    setShipmentBoxes: (state, shipment_boxes) => (state.shipment_boxes = shipment_boxes),
    set_shipment_boxes_list: (state, shipment_boxes_list) => (state.shipment_boxes_list = shipment_boxes_list),
    set_shipment_methods: (state, shipment_methods) => (state.shipment_methods = shipment_methods),
    set_shipment_methods_list: (state, shipment_methods_list) => (state.shipment_methods_list = shipment_methods_list),
    set_surcharges: (state, surcharges) => (state.surcharges = surcharges),
    set_surcharges_list: (state, surcharges_list) => (state.surcharges_list = surcharges_list),
    set_labels: (state, labels) => (state.labels = labels),
    set_admin_orders: (state, admin_orders) => (state.admin_orders = admin_orders),
    set_cutoff_groups: (state, cutoff_groups) => (state.cutoff_groups = cutoff_groups),
    set_admin_transfers: (state, admin_transfers) => (state.admin_transfers = admin_transfers),
};

export default {
  state,
  getters,
  actions,
  mutations
}
