<template>
    <div v-if="link_expanded" class="product__link">

        <h4>Selecteer een product om te linken</h4>
        <!-- <v-select @search="handleSearchChange" @input="ini_link_product" :reduce="product => product._id"
            :options="searched_linking_products.filter(x => x._id !== product._id)" label="ean">
            <template slot="option" slot-scope="option">
                <div selectable="true"> {{ option.name }}</div> <br>
                <small class="text-muted">
                    {{ option.ean }} // {{ option.api_name }}
                </small>
            </template>
        </v-select> -->
        <input v-model="search_value" />
        <div v-if="searched_linking_products.length > 0">
            <div v-for="(x, i) in searched_linking_products.filter((slp) => slp._id !== product._id)" v-bind:key="x._id + '_' + i" class="flex">
                <div class="flex-20">
                    <h4>API: {{ x.api_name }} </h4>
                    <h4> Platform: {{ x.platform }} </h4>
                </div>
                <div class="flex-10" style="display:none">
                    InvSync {{ x.platform }}
                    <input type="checkbox" /> <br>
                </div>
                <div class="flex-20">
                    <h4>Name: </h4>
                    <p>{{ x.name }}</p>
                </div>
                <div class="flex-20">
                    <h4>EAN: </h4>
                    <p>{{ x.ean }}</p>
                </div>
                <div class="flex-20">
                    <button v-on:click="ini_link_product(x._id)">Link product</button>
                </div>
            </div>
        </div>

        <h3>Gelinkte producten</h3>
        
        <div class="container_3d">
            <div class="none">
                <div class="label"><h3>{{ product.ean }}</h3></div>
                <div class="branch">
                    <div v-for="(x, pi) in product.childeren" v-bind:key="x._id" class="node">
                    
                        <div class="label linked_products">
                            <div class="linked_products__conn">
                                <!-- <div class="flex-30">
                                    <h4>API: {{ x.api_name }} </h4>
                                    <h4> Platform: {{ x.platform }} </h4>
                                </div> -->
                                <!-- <div class="flex-20" style="display:none">
                                    InvSync {{ x.platform }}
                                    <input type="checkbox" /> <br>
                                </div> -->
                                
                                
                                <ChildProductItem class="linked_products__child_item"  :name="'product_item' + x._id" :product_id="x._id" :i="pi" />
                               
                                    <button class="linked_products__unlink" v-on:click="unlink_product({ parent_id: product._id, child_id: x._id })">❌</button>
                                
                                <div class="linked_products__platform_stock">
                                    Platform stock
                                    <h3>{{ x.own_stock }}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import vSelect from 'vue-select';

import trx from '@/translations';
/* eslint-disable no-debugger */

import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

export default {
    name: "ProductLink",
    computed: {
        ...mapGetters(['all_products', 'product_childeren', 'current_org', 'surcharges_list', 'searched_linking_products']),
    },
    methods: {
        ...mapActions([
            'getProducts',
            'getAllProducts',
            'setEmail',
            'unlink_product',
            'get_product_childeren',
            'delete_product',
            'getProductLocations',
            'setShareTT',
            'syncProductsWoo',
            'setShippingMethod',
            'setProductLocation',
            'setNote',
            'setReturnInstruction',
            'update_min_stock',
            'syncProducts',
            'toggleFulfilment',
            'setShipBox',
            'link_product',
            'fetch_surcharges_list',
            'select_surcharges',
            'search_products'
        ]),
        handleSearchChange(e) {
            this.search_products({ query: e, limit: 10, product_linking: true })
        },
        ini_link_product: function (product_2) {
            this.link_product({ product_1: this.product._id, product_2: product_2 })
        },
    },
    components: {
        ChildProductItem: () => import('./product_item.vue')
    },
    props: {
        product: Object,
        i: Number,
        link_expanded: Boolean,
        component_name: String
    },
    data() {
        return {
            trx,
            search_value: null,
        }
    },
    watch: {
        search_value: function (val) {
            this.search_products({ query: val, limit: 10, product_linking: true })
        }
    },
    
}
</script>

<style lang="scss" scoped>


.container_3d {
    overflow: auto
}

.product__link {
    @include box-bg;
    padding: 2rem !important;

    .linked_products {
        margin-top: 2rem;
        padding: 0rem;
        @include box-bg-dash;
        &__conn {
            position: relative;
            display: flex;
        }
        &__unlink {
            position: absolute;
            left: calc(50% - 1.2rem);
            top: -30px;
            border-radius: 100%;
            font-size: 1rem;
            margin:0;
            padding: 2px 5px;
            z-index: 999;
        }
        &__platform_stock {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            padding: 1rem;
            right: 0;
            top: 0;
        }
    }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: scroll;
}

.node {
  position: relative;
  margin-bottom: 20px;

  &::before {
    content: '';
    position: absolute;
    top: -5rem;
    left: 50%;
    width: 2px;
    height: 7rem;
    z-index: 2;
    background-image: linear-gradient(to bottom, transparent 50%, #555 50%);
    background-size: 100% 8px; /* Adjust based on your dashed size */
    background-repeat: repeat-y;
    transform: translateX(-50%);
    animation: dash 0.5s linear infinite;
  }

//   &:not(:last-child)::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 50%;
//     width: 2px;
//     height: 100%;
//     z-index: 5;
//     background-image: linear-gradient(to bottom, transparent 50%, #555 50%);
//     background-size: 100% 8px;
//     background-repeat: repeat-y;
//     transform: translateX(-50%);
//     animation: dash 2s linear infinite;
//   }
}

.label {
  padding: 5px 10px;
  background-color: var(--color-grey-light-2);
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
  z-index: 10;
}

.branch {
  margin-left: 0;
  margin-top: 5rem;
  position: relative;
//   background-image: linear-gradient(to bottom, transparent 50%, #555 50%);
  background-size: 100% 8px;
  background-repeat: repeat-y;
//   animation: dash 2s linear infinite;
display: flex;
gap: 1rem;
>div {
    flex: 0 0 calc(30% -1rem);
}
}

@keyframes dash {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 8px; /* This matches the background-size */
  }
}

</style>
