<template>
  <div class="list-container">
    <div
      v-bind:class="
        selected_products_open
          ? 'slider-open container'
          : 'slider-closed container'
      "
    >
      <div class="slider-container">
        <div class="slider-items">
          <h3>
            {{ this.trx.orders.products_in_order }}:
            {{ checkin_products.length }}
          </h3>
          <div
            class="slider-button"
            v-on:click="selected_products_open = !selected_products_open"
          >
            <svg
              stroke="green"
              width="25"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.93129 14.43L12.0013 20.5L18.0713 14.43"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.0015 3.50008L12.0015 20.3301"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="order-products" v-if="this.selected_products_open">
        <div v-for="item in sorted_checkin_products" :key="item.product._id">
          <div class="product_item">
            <h6>{{ item.product.name }}</h6>
            <br>
            <div class="info_separator">
              <div class="global_product_info">

                <h5>{{ trx.checkinv.checkin_product.totals }}</h5>
                <br>
                
                <div class="flex">
                  <div>
                    <h6>EAN</h6>
                     <input readonly disabled :value="item.product.ean">
                  </div>
                  <div>
                    <h6>{{ trx.checkinv.checkin_product.total_amount }}</h6>
                     <input readonly disabled :value="item.total_amount">
                  </div>
                  <div>
                    <h6>{{ trx.checkinv.checkin_product.boxes }}</h6>
                    <input readonly disabled :value="item.total_boxes">
                  </div>
                </div>
               
                <svg
                  class="expand_item_button"
                  @click="expandItem(item.product._id)"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  :style="
                    item_expanded === item.product._id
                      ? 'transform: rotate(180deg)'
                      : ''
                  "
                >
                  <path
                    d="M12.2952 17.4157L6.08945 8.55042C5.7183 8.0202 6.09762 7.29165 6.74484 7.29165H18.2551C18.9023 7.29165 19.2817 8.0202 18.9105 8.55042L12.7048 17.4157C12.6053 17.5579 12.3947 17.5579 12.2952 17.4157Z"
                    fill="#33363F"
                  />
                </svg>
              </div>
              <div>
                <img
                  class="image"
                  v-if="item.product.img_url || item.product.images && item.product.images[0]"
                  v-bind:src="
                    item.product.img_url
                      ? item.product.img_url
                      : item.product.images[0].data
                      ? `data:image/${item.product.images[0].data.img_ext};base64,${item.product.images[0].data.img_data}`
                      : ``
                  "
                  alt="Product image"
                />
              </div>
            </div>
          </div>
          <div v-if="item_expanded === item.product._id">
            <div
              v-for="box in item.boxes"
              class="product_item_box"
              :key="item.product._id + box.units + box.packed_per"
            >
              <div class="product_item_box_separator">
                <div class="flex">
                  <div>
                    <h6>{{ trx.checkinv.checkin_product.per_box }}</h6>
                    <input readonly disabled :value="box.packed_per">
                  </div>
                  <div class="">
                    <h6>{{ trx.checkinv.checkin_product.boxes }}</h6>
                    <input readonly disabled :value="box.units">
                  </div>
                  <div>
                    <h6>{{ trx.checkinv.checkin_product.total_amount }}</h6>
                    <input readonly disabled :value="box.packed_per * box.units">
                  </div>
                  <div>
                    <h6>{{ trx.checkinv.checkin_product.batch_number }}</h6>
                    <input readonly disabled :value="box.batchnr ? box.batchnr : '-' ">
                  </div>
                  <div>
                    <h6>{{ trx.checkinv.checkin_product.expiration_date }}</h6>
                    <input readonly type="date" disabled :value="box.expiration_date ? box.expiration_date : '-'  ">
                  </div>
                  
                </div>
                <div>
                 
                  <div
                    class="del_button_container"
                    @click="
                      deleteItem({
                        _id: item.product._id,
                        packed_per: box.packed_per,
                        units: box.units,
                        batchnr: box.batchnr,
                        expiration_date: box.expiration_date,
                      })
                    "
                  >
                    <svg
                      class="del_button"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L10.5858 12L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L12 10.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L13.4142 12L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="order-controls"
        v-if="this.selected_products_open && this.checkin_products.length > 0"
        @click="toConfirm"
      >
        <div class="order-controls-items">
          <h2 class="send-order-text">{{ trx.checkinv.checkin_product.submit_checkin }}</h2>
          <div class="order-button">
            <svg
              stroke="green"
              width="24"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4316 5.92993L20.5016 11.9999L14.4316 18.0699"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.50146 12H20.3315"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import trx from "@/translations";

export default {
  name: "SelectedProductList",
  data() {
    return {
      trx,
      selected_products_open: false,
      sorted_checkin_products: [],
      item_expanded: null,
    };
  },
  props: {
    updateStep: Function,
    deleteData: Function,
    checkin_products: Array,
    type: String,
  },
  methods: {
    toConfirm() {
      if (this.checkin_products.length === 0) {
        return;
      }
      this.updateStep(2);
    },
    setSortedCheckinProducts(val) {
      let new_sorted_checkin_products = [];

      function indexOf(value, array) {
        let index = 0;
        for (let item of array) {
          if (item.product._id === value.product._id) {
            return index;
          }
          index++;
        }
        return -1;
      }

      for (let item of val) {
        const idx = indexOf(item, new_sorted_checkin_products);
        if (idx !== -1) {
          new_sorted_checkin_products[idx] = {
            product: item.product,
            total_amount:
              Number(new_sorted_checkin_products[idx].total_amount) +
              Number(item.units * item.packed_per),
            total_boxes:
              Number(item.units),
            boxes: [
              ...new_sorted_checkin_products[idx].boxes,
              {
                units: item.units,
                packed_per: item.packed_per,
                batchnr: item.batchnr,
                expiration_date: item.expiration_date,
              },
            ],
          };
        } else {
          let new_item = {
            product: item.product,
            total_amount: item.units * item.packed_per,
            total_boxes: item.units,
            boxes: [
              {
                units: item.units,
                packed_per: item.packed_per,
                batchnr: item.batchnr,
                expiration_date: item.expiration_date,
              },
            ],
          };
          new_sorted_checkin_products.push(new_item);
        }
      }
      this.sorted_checkin_products = new_sorted_checkin_products;
    },
    expandItem(id) {
      if (this.item_expanded === id) {
        this.item_expanded = null;
      } else {
        this.item_expanded = id;
      }
    },
    deleteItem(item) {
      this.deleteData(item);
    },
  },
  watch: {
    checkin_products(val) {
      this.setSortedCheckinProducts(val);
    },
  },
  created() {
    this.setSortedCheckinProducts(this.checkin_products);
  },
};
</script>
<style lang="scss" scoped>
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.slider {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  background-color: var(--color-primary);
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  z-index: 10000;

  &-closed {
    @extend .slider;
    min-height: 14rem;
    padding: 2rem 2rem 8rem 2rem;

    .slider-button {
      transform: rotate(180deg);
    }
  }

  &-open {
    @extend .slider;
    height: 80vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  @media screen and (min-width: $bp-medium) {
    left: 53%;
  }

  @media screen and (max-width: $bp-largest) {
    max-width: 900px;
  }
}

.slider-icon {
  width: 30%;
  height: 0.77rem;
  border-radius: 2rem;
  background-color: var(--color-white);
}

.slider-items {
  justify-self: flex-start;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: white;
  }
}

.slider-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.send-order-text {
  @media screen and (min-width: $bp-medium) {
    font-size: medium;
  }
}

.order-products {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 1rem;
  padding-bottom: 50rem;
}

.order-controls {
  background-color: var(--color-primary);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem 2rem 8rem 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  color: white;

  h2 {
    color: white;
  }

  &-items {
    display: flex;
    gap: 2rem;
    align-items: stretch;
  }
}

.order-button {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 0.77rem;
  background-color: white;
  cursor: pointer;
}

.product_item {
  background-color: white;
  padding: 1rem;
  border-radius: 0.75rem;
}

.info_separator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.global_product_info {
  line-height: 1.5rem;
}

.image {
  height: auto;
  max-height: 10rem;
  width: 10rem;
  object-fit: contain;
}

.expand_item_button {
  cursor: pointer;
}

.product_item_box {
  background-color: white;
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 1rem 0 1rem 1rem;
}

.product_item_box_separator {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.del_button_container {
  display: flex;
  margin-top:30px;
  justify-content: flex-end;
}

.del_button {
  cursor: pointer;
  fill: var(--color-red)
}
</style>
