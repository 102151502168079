<template>
  <div>
    <button class="flex-30" v-on:click="() => isPopupOpen = !isPopupOpen">Niet voldoende aantal
      op locatie</button>
    <div v-if="isPopupOpen" class="popup_pick">
        <label>Hoeveel stuks zijn er op locatie?</label>
        <input type="number" v-model.number="inputNumber" required />
        <br>
        <button class="danger_button flex-30" v-on:click="changeQty"> Verander aantal </button>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      isPopupOpen: false,
      inputNumber: 0
    }
  },
  computed: {
    ...mapGetters(['pick_list'])
  },
  methods: {
    ...mapActions(['delete_pick_location', 'submit_popup_pick']),
    pop_up_pick() {
      this.isPopupOpen = true;
    },
    changeQty() {
      // Parse the input number
      let ava_amt = parseInt(this.inputNumber);
      let used_for_orders = 0;

      // Get the orders from picklist
      const ordersArray = this.pick_list[0].orders;
      const new_orders = [];

      // Iterate through orders to accumulate until available amount is reached
      for (let x of ordersArray) {
        if (used_for_orders + x.qty <= ava_amt) {
          new_orders.push(x);
          used_for_orders += x.qty;
        } else {
          // If partial fulfillment is possible
          if (ava_amt - used_for_orders > 0) {
            let partialOrder = { ...x };
            partialOrder.qty = ava_amt - used_for_orders;
            new_orders.push(partialOrder);
            used_for_orders += partialOrder.qty;
          }
          break; // Cannot fulfill more orders
        }
      }

      // Check if no orders can be fulfilled or if orders remain the same
      const isSameOrders = new_orders.length === ordersArray.length &&
        new_orders.every((order, index) => order.qty === ordersArray[index].qty);

      if (new_orders.length === 0 || isSameOrders) {
        console.log("Single order, can't pick it");
        window.alert("Deze order is te complex om in delen te sturen, raadpleeg manager");
        return;
      }

      // Update the pick_list with new orders and quantity
      this.pick_list[0].orders = new_orders;
      this.pick_list[0].qty = used_for_orders;
    }
  }
}
</script>

<style lang="scss" scopedlang="scss" scoped>
.popup_pick {
  position: absolute;
  // bottom: -11rem;
  // right: 2rem;
  color: var(--color-white);
  background: var(--color-black);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);

  &__conn {
    position: relative;
  }

  button {
    width: 100%;
  }
}
</style>