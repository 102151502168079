<template>
  <div class="logo">
        <img src="@/assets/logo.svg" class="login-container__1">
    </div>
</template>

<script>
export default {
  name: 'LoginContainer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .logo {
      width: 25rem;
      height: auto;
      margin-bottom: 5rem;
  }

</style>
