<template>
  <div>
    <div class="flex-100 order__details__products__product__note" v-if="item.product_id.note">
        <p>{{item.product_id.note}}</p>
    </div>
    <div class="order__details__products__product flex" :style="item.cancellationRequest === true ? 'opacity:0.5; pointer-events: none;' : ''" v-bind:class="{ canceled: item.cancellationRequest} "  >
        <div class="flex-15 expand__conn flex">
            <div v-if=" !item.product_id.parent ? item.product_id.locations[0] : item.product_id.parent.locations[0]"> 
                <LocationSlider  :locations=" !item.product_id.parent ? item.product_id.locations : item.product_id.parent.locations " />
            </div>
            <h3 v-else> - </h3>
            <!-- <button v-if="item.product_id.locations.length > 1" @click="expanded = !expanded" class="expand__button"> {{expanded ? "▲" : "▼" }}</button> -->
        </div>
        <div class="flex-10 flex">
            Aantal
            <h3>
                <span :class="item.quantity > 1 ? 'red' : '' ">{{item.quantity}}</span> 
            </h3>
        </div>

        <div class="flex-15 product_image_conn flex" >
            <object v-if="image" width="40" class="product_image" :data="'data:image/jpeg;base64,'+image.data.img_data" />
            <img  :src="item.product_id.img_url" v-else width="40"  class="product_image"  />
        </div>

        <div class="order__details__products__product__title flex-50" style="flex-grow: 1">
            <p :title="item.title">{{item.title.substr(0, 120) }} - <span v-if="item.product_id.meta_data"> {{item.product_id.meta_data.reference}} </span></p>
            <p> EAN: <a target="blank" :href="'https://www.google.com/search?q='+item.ean">{{!item.product_id.parent ? item.product_id.ean : item.product_id.parent.ean }}</a> // <span class="bsku" v-if="item.product_id && item.product_id.bsku">BSKU: <span>{{ item.product_id.bsku }}</span></span>  // stock: {{ item.product_id.parent && item.product_id.parent._id ? item.product_id.parent.sf_stock : item.product_id.sf_stock }}  </p>
        </div>
        <div class="flex-5 flex" >
          <Dots :action="expand_product" />
        </div>
    </div>

    <div class="products" v-if="product_expanded">
        <ProductItem v-bind:key="!item.product_id.parent ? item.product_id._id : item.product_id.parent._id" :product_id="!item.product_id.parent ? item.product_id?._id : item.product_id?.parent._id" :i="0" />
    </div>

    </div>
</template>



<script>
import { mapGetters, mapActions } from 'vuex';


import LocationSlider from '@/views/Orders/components/locationSlider.vue';
import ProductItem from '@/views/Products/product_item.vue';
import Dots from '../../../components/Layout/dots.vue';

// import api from "@/api";

/* eslint-disable no-debugger */

export default {
    name: 'WideBox',
    props: {
        item: Object,
        image: Object
    },
    computed: {
        ...mapGetters([]),
        // get_image : function(){
        //     return this.product_image;
        // }
    },
    methods: {
        ...mapActions(['get_product_image']),
        expand_product: function() {
            this.product_expanded = !this.product_expanded
        }
    },
    mounted() {
        // debugger;
        // api.get('/product/get_image/'+this.item.product_id._id).then(x => {
        //     debugger;
        //     if (x.data) {
        //         this.product_image = x.data
        //     } else {
        //         this.product_image = null
        //     }
            
        // });
    },
    components: {
        LocationSlider,
        ProductItem,
        Dots
    },
    data() {
        return {
            expanded: false,
            product_expanded: false,
            product_image: ''
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .order {
        &__details {
            &__products {
                &__product {
                    
                    @include box-bg-white;
                    flex-wrap: wrap; 
                    padding:0rem;

                    
                    
                    span {
                        color:var(--color-primary);
                        &.red {
                            color: var(--color-red)
                        }
                    }

                    @media only screen and (min-width: $bp-medium) {
                        height: 8rem;
                    }

                    >div {
                        
                        justify-content: center;
                        flex-direction: column;
                        min-height: 100%;
                        @media only screen and (max-width: $bp-medium) {
                            flex: 1 0 auto;
                            height: 8rem;
                            min-width: 10rem;
                        }
                        @media only screen and (min-width: $bp-medium) {
                            display: flex;
                        }
                        &:not(:last-child) {
                            align-items: center;
                            border-right: 2px solid var(--color-primary);
                            
                            @media only screen and (min-width: $bp-medium) {
                                    max-height: auto;
                            }
                        }
                        h3 {
                            margin-bottom: 0px;
                        }
                    }
                    
                    &__note {
                        padding: 0.5rem 2rem;
                        background: var(--color-purple);
                        border-top-right-radius: var(--border-radius);
                        border-top-left-radius: var(--border-radius);
                        p {
                            color: var(--color-white);
                            font-size: 1.7rem;
                            font-weight: 200;
                            background: var(--color-purple)
                        }
                    }

                    &__title {
                        
                        p {
                            font-size:1.4rem !important;
                            margin-bottom:0.5rem;
                        }
                        @media only screen and (max-width: $bp-medium) {
                            max-width: 50rem;
                        }
                        line-height: 14px;
                        font-weight: 600;
                        max-height: 20px;
                        text-align: left;
                        overflow: hidden;
                        padding: 2rem;
                    }
                }
            }
        }
    }

    .expand {
        &__conn {
            position: relative;
        }
        &__button {
            position: absolute;
                bottom: -2px;
                right: -2rem;
                width: 10rem;
                width: 5px;
                height: 8.3rem;
                text-align: center;
                padding: 1rem;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
        }
    }
    .product_image_conn {
        position: relative;
    }
    .product_image {
        width: 8rem;
        height: 8rem;
        object-fit: cover;
        &:hover {
            @include box-bg-dash;
            position: absolute;
            
            padding: 2rem;
            height: 200px;
            width: auto;
        }
    }
    .ProductLocation {
        margin-top: 4rem;
        padding: 2rem;
        @include box-bg-dash
    }

    .bsku {
        color: var(--color-black) !important;
        span {
            color: var(--color-blue) !important;
            font-size: 1.7rem;
        }
    }
</style>
