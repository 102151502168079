<template>
  <div class="settings">
    <h2>{{trx.settings['Settings']}}</h2>
    <div class="settings__nav"> 
      <SubNavItem link="/settings/org" :name="trx.settings['Your organization']" state=""/>
      <SubNavItem link="/settings/apis" :name="trx.settings.apis" state=""/>
      <SubNavItem link="/settings/subscription" :name="trx.subscriptions['Invoices']" state=""/>
      <SubNavItem link="/settings/users" :name="trx.settings['Users']" state=""/>
      <!-- <SubNavItem link="/settings/payments" :name="trx.settings['Payments']" state=""/>
      <SubNavItem link="/settings/org_settings" :name="trx.settings['Settings']" state=""/> -->
    </div>  
    <section class="sub light big">
          <router-view></router-view>
    </section>
  </div>
</template>

<script>
import SubNavItem from '@/components/Layout/SubNavItem.vue';
import trx from '@/translations'; 

export default {
  name: 'Settings',
  components: {
    SubNavItem
  },
  data() {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-top: 5rem;
  }
  max-width: 30rem;
  height: 100%;
}


</style>
