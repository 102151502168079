import api from '@/api';
// import router from '@/router';

/* eslint-disable no-debugger */

const state = {
    sales: null,
};

const getters = {
    sales: state => state.sales,
}; 

const actions = {
    
    async fetch_sales({commit}, model) {
        const response = await api.get("/sales?query=" +model );
        response !== undefined ? commit('set_sales', response.data) : '';  
    },
    async update_sale({dispatch}, model) {
        console.log(model.updated, model.query)
        await api.put("/sales", model );
        dispatch('fetch_sales', model.query )
    }
};

const mutations = {
    set_sales: (state, sales) => (state.sales = sales),
};

export default {
  state,
  getters,
  actions,
  mutations
}
