<template>
 <div>
    <h3>
      {{ trx.inbound_details.extraWork }}
      <span @click="expandedSurcharge = !expandedSurcharge" class="hoverButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 22 22"
        fill="none" stroke="currentColor" stroke-width="0" stroke-linecap="round"
        stroke-linejoin="round" class="svgIcon">
          <circle cx="12" cy="12" r="10" fill="white" />
          <line x1="12" y1="10" x2="12" y2="14" stroke-width="1.5" />
          <line x1="10" y1="12" x2="14" y2="12" stroke-width="1.5" />
        </svg>
      </span>
    </h3>
    <div v-if="expandedSurcharge" class="surcharge">
      <div>
        <input v-model="surcharge.description" placeholder="beschrijving" required/>
        <input v-model="surcharge.cost" placeholder="kosten" @input="validationInput"
        required/>
        <input v-model="surcharge.amount" placeholder="aantal" @input="validationInput"
        required/>
        <button
        @click="addSurcharge({ id: inbound_item._id, surcharge: surcharge })">
          Bevestig
        </button>
      </div>
    </div>
    <div v-if="inbound_item.surcharge">
      <div v-for="(surcharge, i) in inbound_item.surcharge" :key="i" class="showSurcharges">
        <p>
          <span @click="deleteSurcharge({ id: inbound_item._id, index: i })" class="hoverButton">
            x
          </span>
          toeslag {{ i + 1 }}: "{{ surcharge.description ? surcharge.description
          : '' }}", {{ surcharge.cost ? surcharge.cost : '' }} {{ surcharge.amount
          ? "* " + surcharge.amount : '' }} {{ surcharge.cost && surcharge.amount
          ? '= ' + '€' + surcharge.cost * surcharge.amount : '' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"


import trx from '@/translations'

export default{ 
    name: "InboundItemSurcharges",
    data() {
        return {
            trx,
            expandedSurcharge: false,
            surcharge: {
                description: '',
                cost: '',
                amount: ''
            },
        }
    },
    props: {
        inbound_item: Object,
    },
    methods: {
        ...mapActions([ 'addSurcharge', 'deleteSurcharge', ]),
        validationInput() {
            this.surcharge.amount = this.surcharge.amount.replace(/[^0-9,.]/g, '');
            this.surcharge.cost = this.surcharge.cost.replace(/[^0-9,.]/g, '');
        }
    }
}
</script>

<style lang="scss" scoped>

  .hoverButton:hover {
        cursor: pointer
    }

.surcharge {
        position: absolute;
        @include box-bg-white;
        z-index: 9999;
        transform: translate(110%, 0); 
        width: 35rem;
    }
 .svgIcon {
        vertical-align: middle;
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4));
    }
</style>