<template>
    <div class="selectable-list">
      <div
        class="selectable-list__item"
        v-for="option in options"
        :key="option.value"
        :class="{ selected: value === option.value }"
        @click="selectOption(option.value)"
      >
        <img v-if="option.svg" class="selectable-list__item--icon" :src="require('@/assets/' + option.svg + '.svg')">

        {{ option.text }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: [String, Number], // This is the current selected value, used with v-model
      options: {
        type: Array,
        required: true
      }
    },
    methods: {
      selectOption(selectedValue) {
        this.$emit('input', selectedValue);
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
  .selectable-list {
    border: 1px solid #ccc;
    border-radius: 4px;
    &__item {
        padding: 10px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        flex: 1 0 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 20px;
            height: auto;
        }
        &.selected {
            background-color: var(--color-primary);
            color: var(--color-white)
        }
        &:last-child {
            border-bottom: none;
        }
    }
  }

  .selectable-list {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.5rem;
  }

  </style>