import api from '@/api';
// import router from '@/router';


const state = {
    api: null,
    apis: null,
};

const getters = {
    apis: state => state.apis,
}; 

const actions = {
    
    async store_api({dispatch}, model) {
        await api.post("/store-api", model );
        dispatch('get_apis');
    },
    async get_apis({commit}) {
        const response = await api.get("/get-apis");
        response !== undefined ? commit('set_apis', response.data) : '';  
    },
    async get_api({commit}, model) {
        const response = await api.get("/get-api/" +model );
        response !== undefined ? commit('set_api', response.data) : '';  
    },
    async update_api({dispatch}, model) {
        await api.put("/update-api", model );
        dispatch('get_apis');
    }
};

const mutations = {
    set_api: (state, api) => (state.api = api),
    set_apis: (state, apis) => (state.apis = apis)
};

export default {
  state,
  getters,
  actions,
  mutations
}
