<template>
  <div class="wide_box">
       <router-link :to="{ path: link }" class="" > 
           <span>{{description}} </span>
           <h3>{{ title }}</h3>

            <div class="controls">
                <img class="image" src="@/assets/icons/right-icon.svg">
          </div>
      </router-link>
    
  </div>
</template>

<script>
export default {
  name: 'WideBox',
  props: {
    title: String,
    link: String,
    description: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .wide_box {
        h3 {
            font-size: 2rem
        }
        @include box-bg-white;
        position: relative;
        .controls {
            position: absolute;
            right: 2rem;
            bottom: 20px;
            .image {
                // max-width:1rem;
                height: 3rem;
                width: auto;
                
            }
        }
        &:hover {
            @include box-bg
        }
    }
</style>
