<template>
  <div class="slide-container" >
    <h1>{{slideIndex}}</h1>
    <h3 v-for="loc in items" ref="slides" v-bind:key="loc._id" class="mySlides slide"> 
        <embed :src="loc.pdf" width="566.92913386" ref="pdfviewer" height="450" type="application/pdf">
    </h3>
    <span class="dot" v-for="(loc,i) in items" ref="dots" v-bind:key="i+loc._id" v-on:click="currentSlide(i)"></span>
    <a class="prev" v-on:click="plusSlides(-1)">&#10094;</a>
    <a class="next" v-on:click="plusSlides(1)">&#10095;</a>
  </div>
</template>

<script>

/* eslint-disable no-debugger */

export default {
  name: 'LocationSlider',
  methods: {
      // Next/previous controls
        plusSlides: function(n) {
            this.showSlides(this.slideIndex += n);
        },
        // Thumbnail image controls
        currentSlide: function(n) {
            this.showSlides(this.slideIndex = n);
        },
        init_auto_cue: function() {
          if (this.$refs.pdfviewer) {
            const self = this;
            this.$refs.pdfviewer.forEach(x => {
              x.onload = function () {
                setTimeout( () => { self.plusSlides(+1) }, 2000)
              }
            })
          }
        },
        showSlides: function(n) {
            const self = this;
            let i;
            let slides = self.$refs.slides;
            let dots = self.$refs.dots;

            if (n > slides.length) {self.slideIndex = 1}

            if (n < 1) {self.slideIndex = slides.length}

            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.slideIndex-1].style.display = "block";
            dots[this.slideIndex-1].className += " active";
        }
  },
  props: {
    items: Array,
  },
  mounted() {
    if (this.$refs.slides) {
      this.showSlides(this.slideIndex);
    }
  
    window.addEventListener("keypress", e => {
      
      console.log(e.keyCode);
      if (e.keyCode === 122) {
        this.init_auto_cue();
      }
      if (e.keyCode === (13 || 32)  ) {
          this.plusSlides(+1)
      }
    });

  },
  data () {
      return {
        slideIndex: 1,
        
        
      }
  }
}
</script>

<style lang="scss" scoped>

    .slide-container {
        position: relative;
        .next, .prev {
            top: 20%;
            position: absolute;
        }
        .prev {
            left:-1.5rem;
        }
        .next {
            right: -1.5rem;
        }
    }

    .mySlides {
        display: none;
    }
    

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: var(--color-primary);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: var(--color-purple);
}

</style>