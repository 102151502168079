<template>
  <div class="left-nav">

    <img src="@/assets/logo_icon.svg" class="logo-icon">
    <div class="main-nav">
      <MenuItem 
        name="Home"
        icon="home"
        link="/home"
        :hasOrg="current_org_auth && current_org_auth.name ? true : false"
      />
      <MenuItem
        v-if="authCheck('/orders', 'GET', user.curr_role)"
        :name="trx.orders['Orders']" 
        icon="reports" 
        link="/orders"
        :hasOrg="current_org_auth && current_org_auth.name ? true : false" 
      />
      <MenuItem
        v-if="authCheck('/orders', 'GET', user.curr_role)"
        :name="trx.orders['Shipments']"
        icon="stakeholders"
        link="/shipments"
        :hasOrg="current_org_auth && current_org_auth.name ? true : false"
      />
      <MenuItem
        :name="trx.orders['Products']"
        icon="topics"
        link="/products"
        :hasOrg="current_org_auth && current_org_auth.name ? true : false"
      />
    </div>
    <div class="user-nav">
      <div class="user-nav__user" v-on:click="this.logout">
        <span v-if="user !== null" class="user-nav__user__name">{{ user.first_name.charAt(0) +
        user.family_name.charAt(0) }}</span>
      </div>
      <MenuItem :name="current_org_auth ? current_org_auth.name : ''" icon="orgs" link="/orgs" />
      <MenuItem
        v-if="authCheck('/orgs', 'PUT', user.curr_role)"
        :name="trx.settings['Settings']"
        icon="settings"
        link="/settings/org"
        :hasOrg="current_org_auth && current_org_auth.name ? true : false" />
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem';
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import authCheck from '@/lib/authCheck';

export default {
  name: 'Leftnav',
  methods: {
    ...mapActions(["logout", "fetchUser",]),
    authCheck,
  },
  computed: {
    ...mapGetters(['user', 'current_org_auth'])
  },
  components: {
    MenuItem
  },
  created() {
  },
  data() {
    return {
      trx,
    }
  }
}
</script>

<style lang="scss" scoped>
.left-nav {
  z-index: 99999999999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0px;

  box-shadow: var(--shadow-dark);

  @media all and (max-width: $bp-medium) {
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
  }

  @media all and (min-width: $bp-large) {
    max-width: calc(100vw - 93vw);
    min-width: calc(100vw - 93vw);
    padding: 5rem 1rem;
    text-align: center;
    height: 100vh;
    width: 120%;
    flex-direction: column;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }

  @media only screen and (max-width: $bp-large) {
    >div {
      display: flex;
    }

    .main-nav {
      width: 100vw;
      justify-content: space-around;

      .router-link-exact-active.router-link-active.menu-item.true {
        border-left: 0px;
        border-bottom: 5px solid var(--color-primary-light);
        ;
      }
    }

    .menu-item p {
      display: none;
    }
  }

  @include grdbackground;

}

.user-nav {
  @media only screen and (min-width: calc($bp-large + 10px)) {
    margin-top: 5rem;
    justify-self: flex-end;
  }

  @media only screen and (max-width: $bp-large) {
    position: fixed;
    top: 0rem;
    right: 1rem;
    display: flex;
    align-items: center;
    height: 7rem;
  }

  &__user {
    @media only screen and (min-width: $bp-large) {
      margin-bottom: 1rem;
      width: 40px;
      height: 40px;
    }

    order:1;
    width: 30px;
    height: 30px;
    background-color: var(--color-primary-light);
    color: var(--color-white);
    border-radius: 100%;

    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;

    &:hover {
      cursor: pointer;
      background: var(--color-primary-dark);
    }

    &__name {
      font-size: 1.5rem;
    }

  }

}

.logo-icon {
  @media only screen and (max-width: $bp-large) {
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 40px;
  }

  text-align: center;
  width: 60px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
}
</style>
