<template>
    <div v-on:click="ship( {
        orderItems: orderItems.map(i => {
            return {
                orderItemId: i.orderItemId 
            }
        }), 
        shippingLabelOfferId: o.shippingLabelOfferId,
        org_id: order.org,
        order_id: order._id,
        code: code ? code : o.transporterCode
        })" class="order__details__shipment__option flex" >
        <span class="flex-100">{{o.labelType}}</span>    
        <p class="flex-70">{{o.transporterCode}}</p>
        <p class="flex-30"> &euro; {{o.labelPrice.totalPrice}}</p>
        <div class="flex-100">
            {{o.packageRestrictions.maxWeight}} - 
            {{o.packageRestrictions.maxDimensions}}
            
        </div>  
        <br>
        <p>{{ o.handoverDetails !== undefined ? moment(o.handoverDetails.latestHandoverDateTime).format("DD-MM-YYYY") : ""  }}</p>
    </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'ShipOption',
  components: {},
  props: {
    o: Object,
    order: Object,
    orderItems: Array,
    ship: Function,
    code: String
  },
  data () {
      return {
          moment
      }
  }
}
</script>