<template>
  <div id="app">
    <MyMap :data="route_plan" />
  </div>
</template>

<script>
import MyMap from "@/components/Map";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "App",
  computed: {
    ...mapGetters(['route_plan'])
  },
  methods: {
    ...mapActions(['getRoutePlan']),
  },
  created()  {
        this.getRoutePlan();
  },
  components: {
    MyMap
  }
};
</script>

<style>
html, body {
  margin: 0;
  height: 100%;
}
