<template>
    <div class="">
        <div class="">
            <div class="map-container" ref="myMap"></div>
        </div>
        <div class="controles">
            <button v-on:click="getRoutes()"> Get planning</button>
            
            <br>
            <div class="agents">
                <div class="agent flex" v-for="agent in data.features" v-bind:key="agent.properties.agent_index">
                    <div class="flex-30">
                        <h5>{{agent.properties.agent_id}}</h5>
                    </div>
                    <div class="flex-20">
                        <h5>{{moment.utc(agent.properties.time*1000).format('HH:mm:ss')}}</h5>
                    </div>
                    <div class="flex-20">
                        <h5>{{agent.properties.distance / 1000 }} KM</h5>
                    </div>
                    <div class="flex-20">
                        <h5>{{agent.properties.waypoints.length - 1}}</h5>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  

</template>

<script>
import maplibre from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import moment from 'moment';
/* eslint-disable no-debugger */

export default {
    name: "Map",
    props: {
        data: []
    },
    methods: {
        getRoutes: function () {
        
            const self = this;

            const myAPIKey = '50092b8c9a73485c8228a50655fed1d7'; 
            const mapStyle = 'https://maps.geoapify.com/v1/styles/klokantech-basic/style.json';

            const initialState = {
                lng: 5.5453413749053375,
                lat: 52.03889165435838,
                zoom: 5
            };

            function generateRoute(points, mode) {
                var waypoints = points.map(function (position) { return position[1] + ',' + position[0]; }).join('|');
                var url = "https://api.geoapify.com/v1/routing?waypoints=" + waypoints + "&mode=" + mode + "&apiKey=" + myAPIKey;
                return fetch(url).then(function (resp) { return resp.json(); });
            }

            const map = new maplibre.Map({
                container: this.$refs.myMap,
                style: `${mapStyle}?apiKey=${myAPIKey}`,
                center: [initialState.lng, initialState.lat],
                zoom: initialState.zoom
            });

            const colors = ['#35AB11', '#F88F00', '#44D318', '#2C34FF', '#7C00BE']
            
            map.on('load', function() {

                self.data.features.forEach((agentPlan) => {
                    // const items = agentPlan.properties.waypoints.map((waypoint, index) => {
                    //     return  (waypoint.location, { index: index + 1 })
                    // });

                    const geojson = {
                        'type': 'FeatureCollection',
                        'features': agentPlan
                    };
                    
                    // create points source + layer
                    map.addSource(`waypoints-of-agent-${agentPlan.properties.agent_index}`, {
                        type: "geojson",
                        data: geojson,
                    });

                    // map.addLayer({
                    //     id: `waypoints-of-agent-${agentPlan.properties.agent_index}`,
                    //     type: "line",
                    //     source: `waypoints-of-agent-${agentPlan.properties.agent_index}`,
                    //     'layout': {
                    //         'line-join': 'round',
                    //         'line-cap': 'round'
                    //     },
                    //     'paint': {
                    //         'line-color': colors[agentPlan.properties.agent_index],
                    //         'line-width': 8
                    //     }
                    // });

                    
                
                    var waypoints = agentPlan.properties.waypoints.map(function (waypoint) { return waypoint.location; });
                    
                    generateRoute(waypoints, 'drive').then(function (routeData) {
                        var layerId = "agent-route-" + agentPlan.properties.agent_index;
                        agentPlan.routeLayer = layerId;
                        map.addSource(layerId, {
                            type: 'geojson',
                            data: routeData
                        });
                        map.addLayer({
                            'id': layerId,
                            'type': 'line',
                            'source': layerId,
                            'layout': {
                                'line-cap': "round",
                                'line-join': "round"
                            },
                            'paint': {
                                'line-color': colors[agentPlan.properties.agent_index],
                                'line-width': 3
                            }
                        });
                        return layerId;
                    });

                    map.addLayer({
                        id: `waypoints-text-of-agent-${agentPlan.properties.agent_index}`,
                        type: "circle",
                        source: `waypoints-of-agent-${agentPlan.properties.agent_index}`,
                        // layout: {
                        //     "text-allow-overlap": false,
                        //     "text-font": ["Roboto", "Helvetica Neue", "sans-serif"],
                        //     "text-size": 12,
                        // },
                        paint: {
                            "circle-radius": 4,
                            "circle-color": "#444444", // set any color here
                            "circle-stroke-width": 1,
                            "circle-stroke-color": "#000000",
                        },
                    });
                });

            });
        },
        
    },
    data() {
        return {
            moment
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~maplibre-gl/dist/maplibre-gl.css';

.controles {
    margin-left: 50vw;
}
.map-container {
  height: 100%;
  width: 50%;
  top:0;
  left:0;
  position: absolute;
  z-index: 999999999999;
}
</style>