<template>
<div>
    <input
        v-model="search_inbound_item"
        v-on:keyup.enter="pushIdToRout(search_inbound_item)"
        ref="SearchInboundItemRef"
        type="text"
        placeholder="scan QR code"
    />

        <section>
          <router-view></router-view>
        </section>
</div>
</template>

<script> 
export default {
    name: 'SearchInboundItem',
    data() {
        return {
            search_inbound_item: null,
        }
    }, 
    methods: {
        async pushIdToRout(inbItem) {
            if (this.$router.currentRoute.path === '/inbound/item/' + inbItem) {
                this.$refs.SearchInboundItemRef.value = ""
                return
            }
            await this.$router.push('/inbound/item/' + inbItem)
            this.$refs.SearchInboundItemRef.value = ""
        }
    },
    mounted() {
        this.$nextTick(() => this.$refs.SearchInboundItemRef.focus())
    }
}
</script>


<style lang="scss" scoped>
input {
    margin-bottom: 1rem;
}
</style>