<template>
  <div class="container">
    <div class="line">
      <div
        class="circle-container"
        v-for="(element, idx) in elements"
        :key="idx"
        @click="setStep(idx)"
      >
        <h3 v-bind:class="getStyle(idx)" class="circle">{{ idx + 1 }}</h3>
        <p class="circle-text">{{ element.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ProgressBar Component
 *
 * Displays a progress bar with interactive circles representing different steps in a process.
 *
 * @props {Array} elements - An array of objects representing the steps.
 * @example 
 *  [
 *    {name: "Product"},
 *    {name: "Verzendgegevens"},
 *    {name: "Bevestiging"},
 *  ]
 * @props {Number} currentStep - The current step of the progress.
 * @props {Function} updateStep - Callback function to update the current step.
 */
export default {
  name: "ProgressBar",
  props: {
    elements: Array,
    currentStep: Number,
    updateStep: Function,
  },
  methods: {
    /**
     * Determines the styling class for a circle based on its index.
     *
     * @param {Number} idx - The index of the circle.
     * @returns {String} - The class to be applied to the circle.
     */
    getStyle(idx) {
      return idx <= this.currentStep && "circle-done";
    },
    setStep(idx) {
      if (this.elements[idx].disabled === true) {
        return;
      }
      this.updateStep(idx)
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1rem 0 4rem 0;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  max-height: 1rem;
  border-radius: 0.25rem;
  background-color: var(--color-primary);
  margin: 2rem 0;
}

.circle {
  &-container {
    position: relative;
    text-align: center;
  }

  &-text {
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  border-radius: 100%;
  background-color: var(--color-primary-mid);
  color: white;
  cursor: pointer;

  &-done {
    background-color: var(--color-primary-dark);
  }
}
</style>
