<template>
<div>
    <div>
        <p>voeg locatie toe
                <span @click="expandedLocationTransfer = !expandedLocationTransfer" class="hoverButton">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 2 22 22"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="0"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="svgIcon"
                            >
                            <circle cx="12" cy="12" r="10" fill="white" />
                            <line x1="12" y1="10" x2="12" y2="14" stroke-width="1.5"/>
                            <line x1="10" y1="12" x2="14" y2="12" stroke-width="1.5"/>                                
                            </svg>
            </span>  </p>
        </div>
            
        <div v-if="expandedLocationTransfer" class="locationTransfer">
            <input type="text" placeholder="aantal" v-model="locationFinderQuantity" />
            <v-select v-model="selectedLocationType" :options="locationTypes" placeholder="soort"></v-select>
            <button @click="findLocation({ type: selectedLocationType, amount: locationFinderQuantity, inbItemId: inbound_item._id, product: inbound_item.product_id, transferLocation: true, departedLocationId: departedLocationId._id })" class=""> Vind locatie</button>
        </div> 
</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: "AddTransfer",
    data() {
        return {
            expandedLocationTransfer: false,
            selectedLocationType: null,
            locationTypes: ['warehousing', 'rack-box', '1/2pallet', 'half-box', 'pallet'],
            locationFinderQuantity: null,
        }
    },
    props: {
        inbound_item: Object,
        departedLocationId: Object,

    },
    methods: {
        ...mapActions(['findLocation'])
    }
}
</script>

<style lang="scss" scoped>
.locationTransfer {
        position: absolute;
        @include box-bg-white;
        z-index: 9999;
        transform: translate(110%, 0); 
        width: 35rem;
    }
    .hoverButton:hover {
        cursor: pointer
    }

    .svgIcon {
        vertical-align: middle;
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4));
    }
</style>