<template>
  <div class="main-wrapper">
    
    <div v-if="user" class="admin_menu">
      <a v-if="authCheck('/products/ean/*', 'GET', user.curr_role)" title="scanner" href="/scanner">📟</a>
      <a v-if="user.is_admin" title="Admin" href="/admin">⚙️</a>
      <a v-if="user.curr_org && user.licences[0] && user.licences[0].licence_id && licences.includes(user.licences[0].licence_id)"  @click="isOpen = !isOpen" >💯</a>
      <a v-if="authCheck('/products/ean/*', 'GET', user.curr_role)" title="scanner" href="/inbound/item">📟</a>
    </div>
    
    <LeftNav />
    
    <section class="main">
        <router-view></router-view>
    </section>
    
    <SetupWizard />

    <Notification />

    <Popup :closed="!isOpen" :title="'Scoreboard'" @handleClose="handleClose">
      <Scoreboard />
    </Popup>
  </div>
</template>

<script>
import LeftNav from '@/components/Layout/LeftNav';
import Scoreboard from '@/components/scoreboard.vue';
import SetupWizard from '@/components/SetupWizard/index.vue';
import Notification from '@/components/Notifications/index.vue';

import { mapGetters,mapActions  } from 'vuex';
import Popup from '../components/Layout/popup.vue';
import authCheck from '@/lib/authCheck';
/* eslint-disable no-debugger */

export default {
  name: 'main-wrapper',
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(["fetchUser"]),
    authCheck,
    handleClose() {
      this.isOpen = false;
    },
  },
  created() {
    this.fetchUser();
  },
  components: {
    LeftNav,
    SetupWizard,
    Scoreboard,
    Popup,
    Notification
},
  data() {
    return {
      isOpen: false,
      licences: ['7b318079-acc6-459f-8570-ebd019e2818b', 'a3106a1c-ec7f-457a-bb56-d6cc5eecc085', 'd38f1b39-25c9-4b57-92e8-4c148a8c3087']
    }
  }
}
</script>

<style lang="scss" scoped>
 .admin_menu {
        position: absolute;
        top:20rem;
        right: 0px;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        a {
        background: var(--color-grey-light-3);
        color: var(--color-white);
        padding: 1rem;
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
        }
    }
.main-wrapper {
  @media only screen and (max-width: $bp-medium) {
      background: #000000 !important;
      height: 100vh;
      
  }
  .main {
    @media only screen and (max-width: $bp-medium) {
        margin-top: 7rem;
        border-top-right-radius: var(--border-radius);
        min-height: 100%;
        padding: 1rem;
        padding-bottom:5rem ;
        margin-bottom:2rem;
        position: absolute;

    }
    @media only screen and (min-width:  $bp-large ) {
      padding: 5rem; 
      right: 0;
      min-width: 93vw;
      max-width: 93vw ;
      min-height: 100%;
      height: calc(100vh - 5rem );
      // border-bottom-left-radius: var(--border-radius);
    } 
    max-width: 100vw;
    min-width: 100vw;
    background-color: var(--color-white);
    position: absolute;
    overflow-y:scroll;
    overflow-x:hidden;
    padding: 3rem;
    // border-top-left-radius: var(--border-radius);
     
    @media only screen and (max-width: $bp-large) {
      margin-top: 7rem;
      // border-top-right-radius: var(--border-radius);
    }
  }
}
.logo-icon {
    z-index: 99999;
    position: fixed;
    top: 50px;
    left: 1.2rem;
    width: 60px;
    height: auto;
}


</style>
