<template>
  <div class="search">
    <input type="text" v-model="search" :placeholder="placeholder" @keyup.enter="search__action( s_model ? { [s_model]:search } : search )" />
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
    
export default {
  name: 'Search-box',
  props: {
    search__action: Function,
    s_model: String,
    placeholder: String,
    searchOnEmpty: Boolean,
  },
  // methods: {
  //     ...mapActions([this.props.search__action])
  // },
  // computed: {
  //     ...mapGetters(['user'])
  // },
  components: {
      
  },
  created() {
  },
  data() {
      return {
          trx,
          search: null
      }
  },
  watch: {
    async search(val) {
      if (val === "" && this.searchOnEmpty === true) {
        this.search__action( this.s_model ? { [this.s_model]:this.search } : this.search )
      }
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped >
  h3 {
    margin: 40px 0 0;
  }

</style>
