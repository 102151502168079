<template>
  <div class="dots_conn">
    <button class="dots" v-on:click="action">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>    
    </button>
  </div>
</template>

<script>
export default {
  name: 'Dots',
  props: {
    action: Function
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.dots {
    background: transparent;
    
    color: var(--color-grey-dark-3) !important;
    display: flex;
    flex-direction: column;
    &:hover {
      background: transparent;
      span {
        background: var(--color-primary);
      }
    }
    span {
      width: 5px;
      height: 5px;
      min-width: 5px;
      min-height: 5px;
      font-size: 5rem;
      background: var(--color-grey-dark-3);
      margin:2px;
      border-radius: 50%;
    }
}

</style>
