<template>
  <div>
    <div v-if="saved_information.inbound.contact_person">
      <h3>{{ trx.checkinv.checkin_done.confirmation_message }}</h3>
      <br />
      <p>
        {{ trx.checkinv.checkin_done.email_instructions }}
      </p>
      <br />
      <p><b>{{ trx.checkinv.checkin_done.shipping_instructions_title }}</b></p>
      <p>
        <br /><b>1.</b> <a href='https://m.smartfulfilment.nl/asset/21:werken-met-het-smart-fulfilment-portaal'> {{ trx.checkinv.checkin_done.shipping_instruction_1 }} </a>
        <br /><b>2.</b> {{ trx.checkinv.checkin_done.shipping_instruction_2 }}
        <br /><br />
        <b>{{ trx.checkinv.checkin_done.important }}</b> {{ trx.checkinv.checkin_done.important_note }}
      </p>
      <br />
      <p v-if="saved_information && saved_information.inbound._id"><button @click="$router.push(`/checkin_inventory/${saved_information.inbound._id}`)">{{ trx.checkinv.checkin_done.view_information_button }}</button></p>
      <br />
      <p><b>{{ trx.checkinv.checkin_done.documents_per_product }}</b></p>
      <div v-if="saved_information" class="pdf_container">
        <div
          v-for="(inbound_item, idx) in saved_information.inbound_items"
          :key="inbound_item._id"
        >
          <div class="pdf_item_container">
            <h3>{{ trx.checkinv.checkin_done.box_label }} {{ idx + 1 }}</h3>
            <div class="pdf_item">
              
              <div class="pdf_download" @click="download_qr(inbound_item._id)">
                <svg
                  width="42"
                  height="56"
                  viewBox="0 0 42 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.5694 27.5926C19.0315 25.8713 19.0423 22.547 19.3542 22.547C20.2579 22.547 20.1719 26.5168 19.5694 27.5926ZM19.3865 32.6706C18.5581 34.8437 17.5253 37.3289 16.3312 39.416C18.2999 38.663 20.5269 37.5656 23.0981 37.06C21.7318 36.0272 20.4193 34.5425 19.3865 32.6706ZM9.26293 46.097C9.26293 46.183 10.683 45.516 13.0176 41.7721C12.2968 42.4499 9.88691 44.4079 9.26293 46.097ZM26.6807 17.2539H41.312V52.5412C41.312 53.9721 40.1609 55.1232 38.73 55.1232H2.582C1.15114 55.1232 0 53.9721 0 52.5412V2.62253C0 1.19167 1.15114 0.0405273 2.582 0.0405273H24.0987V14.6719C24.0987 16.092 25.2606 17.2539 26.6807 17.2539ZM25.82 35.7367C23.6683 34.4242 22.2375 32.6168 21.2262 29.9487C21.7103 27.9584 22.4742 24.9353 21.8932 23.0418C21.3876 19.8789 17.3317 20.1909 16.7507 22.3103C16.2128 24.2791 16.7077 27.0547 17.6222 30.5942C16.3742 33.5635 14.5345 37.5441 13.2328 39.8249C13.222 39.8249 13.222 39.8356 13.2112 39.8356C10.2957 41.331 5.2931 44.6231 7.34794 47.1513C7.95041 47.8936 9.06928 48.2271 9.66099 48.2271C11.5867 48.2271 13.5017 46.2906 16.2343 41.5785C19.01 40.664 22.0546 39.5236 24.7334 39.0825C27.068 40.352 29.8006 41.1804 31.6188 41.1804C34.7602 41.1804 34.9754 37.7377 33.7381 36.5113C32.2427 35.0482 27.8964 35.4677 25.82 35.7367ZM40.5589 11.3368L30.0158 0.793611C29.5316 0.309486 28.8754 0.0405273 28.1868 0.0405273H27.5413V13.8112H41.312V13.1549C41.312 12.4772 41.0431 11.8209 40.5589 11.3368ZM32.587 38.8028C33.0281 38.5123 32.318 37.5226 27.9824 37.8346C31.9738 39.5344 32.587 38.8028 32.587 38.8028Z"
                    fill="black"
                  />
                </svg>
                <p>{{ trx.checkinv.checkin_done.qr_code_label }} {{ idx + 1 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="download_all_container">
        <div class="download_all_buttons">
          <button
            class="download_all_button"
            @click="download_all(saved_information.inbound_items)"
          >
          {{ trx.checkinv.checkin_done.download_all_documents_button }}
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>{{ trx.checkinv.checkin_done.processing_shipment }}</h3>
    </div>
  </div>
</template>
<script>
import trx from "@/translations";
import api from "@/api";

/* eslint-disable no-debugger */

export default {
  name: "AddCheckinConfirm",
  data() {
    return {
      trx,
      saved_information: {
        inbound: {},
        inbound_items: [],
      },
    };
  },
  props: {
    data: Array,
    information: Object,
    updateInformation: Function,
  },
  methods: {
    async createInbound(model) {

      model.items = model.items.map(x=> { 
          return {
            product: x.product._id,
            units: x.units,
            packed_per: x.packed_per,
            batchnr: x.batchnr,
            expiration_date: x.expiration_date,
          };
      })

      const { data } = await api.post("/inbound/", model);
      this.saved_information = data;
    },
    async submit() {
      await this.createInbound({
        items: this.data,
        information: this.information,
      });
    },
    async download_inbound_item_packing(id) {
      const response = await api.get(`/inbound_item/packing_slip/${id}`, {
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;

      const pdfWindowShip = window.open(fileURL);
      pdfWindowShip.print();
    },
    async download_qr(id) {
      const response = await api.get(`/inbounds/qr/${id}`, {
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;

      const pdfWindowShip = window.open(fileURL);
      pdfWindowShip.print();
    },
    async download_all(inbound_items) {
      inbound_items.forEach(async (inbound_item) => {
        await this.download_qr(inbound_item._id);
      });
    },
  },
  async created() {
    await this.createInbound({
      items: this.data,
      information: this.information,
    });
  },
};
</script>
<style lang="scss" scoped>
.pdf_container {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  gap: 2rem;
}

.pdf_item_container {
  box-shadow: var(--shadow-light);
  margin-bottom: 5rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.pdf_item {
  display: flex;
  gap: 1rem;
}

.pdf_download {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.download_all_container {
  width: 100%;
}

.download_all_buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
</style>
