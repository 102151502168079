<template>
  <div>
    <div>
			<label for="search_product_checkin"><h6>{{ this.trx.checkinv.checkin_products.choose_products_in_box }}</h6></label>
			<input id="search_product_checkin" v-model="searchQuery" :placeholder="this.trx.checkinv.checkin_products.search_products" />
		</div>
    <!-- Products -->
    <div v-if="products && products.length > 0" class="product_container">
      <ProductItem :updateData="updateData" v-for="item in productList" :key="item._id" :item="item" :updateSelectedProduct="updateSelectedProduct" />
    </div>
    <div v-else-if="productList && productList.length > 0" class="product_container">
      <ProductItem v-for="item in displayedProducts" :key="item._id" :item="item" :updateData="updateData" :updateSelectedProduct="updateSelectedProduct" />
    </div>
    <div v-else>
      <p>{{ this.trx.checkinv.checkin_products.no_products }}</p>
    </div>
  </div>
</template>

<script>
import trx from '@/translations';
import ProductItem from "./product_item.vue";

function debounce(func, wait, immediate) {
  let timeout;
  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export default {
  name: "ProductList",
  components: { ProductItem },
  props: {
    products: Array,
    updateData: Function,
    updateSelectedProduct: Function,
  },
  data() {
    return {
      trx,
      productList: [],
      searchQuery: "",
    };
  },
  computed: {
    displayedProducts() {
      return this.searchQuery ? this.productList : this.products.slice(0, 50);
    }
  },
  created() {
    // Define the debounced function in the created hook
    this.debouncedSearchChange = debounce(this.handleSearchChange, 300);
  },
  watch: {
    searchQuery() {
      this.debouncedSearchChange();
    },
    products: {
      deep: true,
      handler(newVal) {
        this.productList = newVal;
        this.debouncedSearchChange();
      },
    },
  },
  methods: {
    handleSearchChange() {
      if (!this.searchQuery) {
        this.productList = this.products.slice(0, 50);
      } else {
        const searchQueryLower = this.searchQuery.toLowerCase();
        this.productList = this.products.filter(product => {
          let nameLower = String(product.name).toLowerCase();
          let eanStr = String(product.ean);
          let bskuStr = String(product.bsku);
          return nameLower.includes(searchQueryLower) || eanStr.includes(this.searchQuery) || bskuStr.includes(this.searchQuery);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  #search_product_checkin {
    border: 2px solid var(--color-primary);
  }
  .product_container {
    display: flex;
    flex-direction: column;
    max-height: 50rem;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
