<template>
    <div>
         <p><strong>Transfers</strong></p>
        
        <div v-if="inbound_item && inbound_item.transfers && inbound_item.transfers.length > 0 && inbound_item.transfers.filter((x) => x.arrived_location_id === null)" class="locationTransfer">
            <div v-for="transfer in inbound_item.transfers" :key="transfer._id" >
                <p>departed location id: {{ transfer.departed_location_id.parent.name }}-{{ transfer.departed_location_id.name }}</p>
                <p>departed datum: {{ moment(transfer.departed_at).format('DD-MM-YYYY') }}</p>
                <p>aantal: {{ transfer.stock }}</p>
                <div class="foundLocations" v-if="transfer.reserved_location_id">
                <div v-for="rl in transfer.reserved_location_id" :key="rl._id">
                    <div>
                        <input class="piecesInput" v-model="rl.stock" />
                    </div> 
                    <div>
                        <p class="locationName">{{ rl.location_id.parent.name }}-{{ rl.location_id.name }} </p>
                    </div>
                    <div>
                        <AddLocationToInbItem :pieces="rl.stock" :product="inbound_item.product_id" :locationId="rl.location_id._id" 
                        :inboundItemId="inbound_item._id" :transferLocation="true" />
                    </div>
                </div>
                </div>
                <p v-if="transfer.arrived_location_id !== null">gearriveerde locatie: {{ transfer.arrived_location_id.parent.name }}-{{ transfer.arrived_location_id.name }}</p>
                <AddTransfer :inbound_item="inbound_item" :departedLocationId="transfer.departed_location_id"  v-if="transfer.arrived_location_id == null"/>
               
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import AddTransfer from './addTransferToInbItem.vue'
import AddLocationToInbItem from './addLocationToInbItem.vue'

export default {
    name: 'TransferLocation',
    data() {
        return {
            moment,
           
        }
    },
    props: {
        inbound_item: Object,
    },
    methods: {
        ...mapActions(['findLocation'])
    },
    components: {
    AddTransfer,
    AddLocationToInbItem
}

}
</script>

<style lang="scss" scoped>
.locationTransfer {
    overflow-y: auto;
    max-height: 15rem;
}

//// scroll bar
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
}
////

 .foundLocations {
    display: flex;
            gap: 1rem;
            text-align: center;
            width: max;
            align-items: center;

            input {
                width: 6.5rem;
                margin-right: 0.5rem;
                padding: 1.5rem 0.5rem !important;
            }

            .locationName {
                font-weight: bold;
            }
           
        }
</style>