<template>
  <div class="organization">
        <h3>{{trx.admin.locations.name}}</h3>
        <h2 v-if="child_locations" class="flex-100">{{locations.filter(pl => { return pl._id === child_locations[0].parent } )[0].name}}</h2>
        <div class="locations__location flex-100" v-if="child_locations">
            
            <div v-for="(child) in child_locations" :class="'grid-item item__' + child.name.split('.')[1]"  v-bind:key="child._id">
              <LocationNumber :node="child" :parent_type="locations.filter(pl => { return pl._id === child_locations[0].parent } )[0].type" :parent_id="locations.filter(pl => { return pl._id === child_locations[0].parent } )[0]._id" :rack="child.name" :maxDailyDemand="maxDailyDemand" />
            </div>
        </div>
        
       <br>
        <div class="flex flex-wrap locations" v-if="locations">  
          <div v-for="x in locations" v-bind:key="x._id" class="locations__conn flex-30 flex flex-wrap ">
            <div class="locations__location__info flex">
              <div class="locations__location__name">
                <h2 class="flex-10">{{ x.name }}</h2>
                <span title="Aantal locaties in deze locatie" class="locations__location__childlength">{{x.childeren && x.childeren.length ? x.childeren.length + 1 : 0}}</span>
                <span title="Type locaite" class="locations__location__labeltype">{{x.type}}</span>
              </div>
            </div>
            <div class="actions flex-100" >
              {{ x._id }}
              <h6>Actie(s)</h6>
              <div class="flex">
                <button class="flex-10" v-on:click="print_location_labels(x._id)" title="Print alle locaties">P</button>
                <button class="flex-10" v-on:click="fetch_child_Locations(x._id)" title="Toon locaties">T</button>
                <button class="flex-10 danger_button" v-on:click="delete_location({id: x._id})">D</button>
              </div>
            </div>
          </div>
        </div>      
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import LocationNumber from './LocationNumber.vue';
// import NestedTest from "./nested-test.vue";

export default {
  name: 'Locations',
  methods: {
    ...mapActions(['fetchLocations', 'fetch_child_Locations', 'add_location', 'print_location_labels'])
  },
  computed: {
    ...mapGetters(['locations', 'child_locations']),
    maxDailyDemand() {
      // Filter out invalid daily_demand values and find the maximum
      const validDemands = this.child_locations
        .map(p => {

          return p.products[0] ? p.products[0].product_id.total_sold_30d : p

        }).filter(d => d != null && d > 0); // Filter out null, undefined, and non-positive values
      return validDemands.length > 0 ? Math.max(...validDemands) : 1; // Default to 1 if no valid demands
    },
  },
  components: {
    LocationNumber,
    // NestedTest,
  },
  created() {
    if (!this.locations)
      this.fetchLocations();
  },
  data () {
    return {
        trx
    }
  }
}
</script>

<style lang="scss" scoped>
   h3 {
     margin-bottom: 5rem;
   }

   * {
    box-sizing: border-box;
}

   .locations {
       &__conn {
           @include box-bg-white;
           margin-bottom: 1rem;
           position: relative;
           margin-right: 1rem;
       }
       &__location {
            @include box-bg-white;
            
            height: 100%;
            display: grid;
            // grid-template-columns: repeat(10, );
            // grid-template-columns: repeat(auto-fit, minmax(min(100/3, max(64px, 100/5)), 1fr));
            grid-template-rows: minmax(50px, 50px);
            grid-gap: 0.3em;
            .item {
              &__0 {
                // grid-column: 1;
                grid-row: 9;
              }
              &__1 {
                // grid-column: 1/3;
                grid-row: 8;
              }
              &__2 {
                grid-row: 7;
              }
              &__3 {
                grid-row: 6;
              }
              &__4 {
                grid-row: 5;
              }
              &__5 {
                grid-row: 4;
              }
              &__6 {
                grid-row: 3;
              }
              &__7 {
                grid-row: 2;
              }
              &__8 {
                grid-row: 1;
              }
            }
            &__childlength {
              border-bottom-right-radius: var(--border-radius);
              background: var(--color-primary);
              color: var(--color-white);
              position: absolute;
              top: 0rem;
              left: 0rem;
              padding: 1rem;
              line-height: 5px;
            }
            &__labeltype {
              border-bottom-left-radius: var(--border-radius);
              background: var(--color-primary);
              color: var(--color-white);
              position: absolute;
              top: 0rem;
              right: 0rem;
              padding: 1rem;
              line-height: 5px;
            }
            &__number {
                @include box-bg-dash;
                
                position: relative;
                cursor: pointer;
                padding: 1rem;
                font-size: 1.7rem;
                min-width: 50px;
                min-height: 50px;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                
                .tools {
                    display: none;
                }

                &:hover {
                    .tools {
                        display: block;
                    }
                }
                &.free {
                    border-color: var(--color-primary);
                    background: var(--color-primary-o);
                    color: var(--color-primary-dark)
                }
                &.used {
                    border-color: var(--color-primary-o);
                    background: var(--color-purple);
                    color: var(--color-white);
                }
                &.error {
                    border-color: var(--color-red-o);
                    background: var(--color-red);
                    color: var(--color-white);
                  }
                &.empty {
                    border-color: var(--color-primary-o);
                    background: var(--color-white);
                    color: var(--color-primary-dark)
                }
            }
            overflow-x: scroll;
            overflow-y: visible !important;
       }

   }
   
</style>
