<template>
  <div class="add-subscription">
        <h1>{{trx.subscriptions["Pricing knowledge"]}}</h1>
        <p>Deze prijzen worden maandelijks in rekening gebracht als wij orders verwerken, verpakken of oplsaan. Een uitgebereid overizcht van de uitgaven kunt later terug vinden. Er wordt niks in rekening gebracht tot dat de prodcuten bij ons in het magazijn staan.<p>
        <div class="add-subscription__plans-container">
            <div class="add-subscription__plans-container__standard">
                <h3>{{trx.subscriptions['Fulfilment']}}</h3>
                <SubscrForm :plans="plans"></SubscrForm>
            </div>
            <!-- <div class="add-subscription__plans-container__entherprise disabled">
                <h3>{{trx.subscriptions['Enterprise']}}</h3>
                    <p>On-premise hosting</p>
                    <p>Custom standards</p>
                    <p>Premium standards</p>
                    <p>Premium support</p>
                    <p>Customization</p>
                    <button>Continue</button>
            </div>
            <div class="add-subscription__plans-container__free disabled">
                <div class="text">
                    <p>{{trx.subscriptions.free_plan_help}}</p>
                </div>  
                <div class="button">  
                    <button>{{trx.subscriptions['Start for free']}}</button>
                </div>    
            </div> -->
        </div> 
            
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import SubscrForm from './from';
import { mapGetters, mapActions } from 'vuex';


export default {
  name: 'add-subscription',
  components: {
      SubscrForm
  },
  methods: {
    ...mapActions(['fetchPlans', 'createSubscr', 'calcTotal', 'updateSubscr', 'fetchSubscriptions'])
  },
  computed: mapGetters(['current_subscr', 'plans', 'totals']),
  created() {
    this.fetchPlans();
  },
  data () {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>
    .add-subscription {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        p {
            text-align: center;
        }
        h1 {
            text-align: center;
            margin-right: auto;
            margin-left: auto;
        }
        @mixin def-padding {
            padding: 2rem;
            
        }
        &__plans-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 100rem;
            margin: 5rem 0rem;
            &__standard {
                flex: 1 0 calc(60% - 2rem);
                @include box-bg-solid;
                @include def-padding;
                margin:1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
            }
            &__entherprise {
                flex: 0 0 calc(40% - 2rem);
                @include box-bg-solid;
                @include def-padding;
                margin:1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                p {
                    margin-left: 2rem;
                    &::before {
                        content: '✔';
                        position: absolute;
                        margin-left: -2rem;
                    }
                }
                button {
                    margin-right: auto;
                }
            }
            &__free {
                flex: 0 0 calc(100% - 2rem);
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text {
                    flex: 0 0 80%;
                }
                .button {
                    flex: 0 0 15%;
                }
                @include box-bg-dash;
                @include def-padding;
                margin:1rem; 
            }
        }
    }
</style>
