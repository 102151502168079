<template>
	<div>
		<h2>Open transfers</h2>
		<div v-if="admin_transfers.length > 0">
			<div v-for="transfer in admin_transfers" :key="transfer._id">
				<p>{{ transfer.location_id }}</p>
			</div>
		</div>
		<div v-else>
			<div v-if="loading">
				Loading transfers...
			</div>
			<div v-else>
				There are no transfers open at the moment.
			</div>
		</div>
	</div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default {
	name: 'Transfers',
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		...mapGetters([
			'admin_transfers'
		])
	},
	methods: {
		...mapActions([
			'fetch_admin_transfers'
		]),
		async get_transfers() {
			this.loading = true
			await this.fetch_admin_transfers()
			this.loading = false
		}
	},
	created() {
		this.get_transfers()
	}
}
</script>